import React, { useState, useEffect } from 'react';
import ListCupons from './ListCupons';
import { NavLink } from 'react-router-dom';
import './cupones-styles.css';
import Swal from 'sweetalert2';

function AdminCuponesScreen() {

  const [rolVisto, CambioRoVisto] = useState(false);
  
  useEffect(() =>{

    if (rolVisto == false) {
      verRol();
    }
  });

  const verRol = async function(){
      let usuario = await localStorage.getItem('usuarioLegalizarlo');
      if (usuario != null) {
        let dataUser = JSON.parse(usuario);
        if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {

          if (dataUser.rol == 'subAdmin') { 

              dataUser.restricciones.forEach(element =>{ console.log(element);
                if (element == 'cupones') {
                  window.location.href = '/';
                }
              })
          }

          CambioRoVisto(true);
        }else{
          window.location.href = '/';
        }
      }else{
        window.location.href = '/';
      }
  }

  const saveData = async function(){
    
  }

  return (
    <div className="containerAdminDocs container pl-5">
        <section className="seccion1AdminDocs">
            <header>
              <h1>Aplicación de Administración de Libros</h1>
              <hr />
              <div className="links">
                <NavLink to="/admin/cupones/listado" className="link" activeClassName="active" exact>
                  Lista de Cupones
                </NavLink>
                <NavLink to="/admin/cupones/agregar" className="link" activeClassName="active">
                  Agregar Cupon
                </NavLink>
              </div>
            </header>
          <div className="container">
                <h1>Página de cupones</h1>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div><label>Código del cupón</label></div>
                    <div className="form-check-inline">
                      <input type="text" name="add_name" id="add_name" className="form-control w-100" required="" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <label>Estatus del cupón</label>
                    <div className="">
                      <label className="label-checked">
                         <input type="radio" id="add_active0" name="add_active" value="A" />  Activo
                      </label>
                      <label className="label-checked">
                         <input type="radio" id="add_active1" name="add_active" value="I" />    Inactivo</label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <label>Días, agrega (0) si es indefinido</label>
                    <div className="">
                        <input type="number" id="add_duracion" name="add_duracion" placeholder="Tiempo que dura el cupón" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <label>Ingresa el monto en porcentaje o en moneda </label>
                    <label className="label-checked">
                      <input type="radio" id="porcmon0" name="pormon" value="P" />  Porcentaje
                    </label>
                    <label className="label-checked">
                      <input type="radio" id="pormon1" name="pormon" value="M" />   Moneda</label>
                  </div>
                  <div className="col-12 col-md-4">
                    <div>
                      <label>Monto</label><br />
                      <input type="number" id="monto" name="monto" onchange="montominimo(this.id,'add')" />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div>
                      <label>Número de usos</label><br />
                      <input type="number" id="usos" name="usos" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button onClick={saveData} className="btn btn-primary">Guardar Cupon</button>
                  </div>
                </div>
          </div>
        </section>
        <section >
          <ListCupons/>

        </section>

    </div>
  );
}


export default AdminCuponesScreen;
