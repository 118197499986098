import React, { useState, useEffect } from 'react';
import './usuarios-styles.css';
import {losUsuarios} from '../../../backend/usuarios/usuarios';
import {validarSiHayPlanContratado} from '../../../backend/carrito/carrito';
import {InfoPlanUsuario} from '../../../components/usuarios/usuarios';

function AdminUsuariosScreen() {

  const [pantalla, CambioPantalla] = useState('');
  const [rol, CambioRol] = useState(false);
  const [busquedaUsuarios, CambioBusquedaUsuarios] = useState(false);
  const [usuarios, CambioUsuarios] = useState([]);
  const [usuariosFinales, CambioUsuariosFinales] = useState([]);
  const [limiteUsuarios, CambioLimiteUsuarios] = useState(20);
  const [elUsuario, CambioElUsuario] = useState(false);
  const [datosPlan, CambiosDatosPlan] = useState(false);

  useEffect(() => {

    if (rol == false) {
      verRol();
    }

    if (busquedaUsuarios == false) {

      losUsuarios('admin').then(response =>{

        let datos = [];
        let cero = 0;

        for (let respon of response) {
          
          if (cero <= limiteUsuarios) {

            datos.push(respon);
          }
        }

        CambioUsuarios(response);
        CambioUsuariosFinales(datos);
      });

      CambioBusquedaUsuarios(true);
    }
  });

  const verRol = async function(){
      let usuario = await localStorage.getItem('usuarioLegalizarlo');
      if (usuario != null) {

          let  dataUser = JSON.parse(usuario);
          if (dataUser.rol != 'admin' && dataUser.rol != 'subAdmin') {
            window.location.href = '/';
          }else{
            
            if (dataUser.rol == 'subAdmin') { 

                dataUser.restricciones.forEach(element =>{ console.log(element);
                  if (element == 'usuarios') {
                    window.location.href = '/';
                  }
                })
            }
          }

          CambioRol(true);
      }else{
        window.location.href = '/';
      }
  }

  const buscarUsuario = async function(){
      let query = document.getElementById('buscar').value;
      if (query === '') {

        let resultado = [];

        let cero = 0;

        for (let usuario of usuarios) {
          if (cero <= limiteUsuarios) {
            resultado.push(usuario);
          }
          cero++;
        }

        CambioUsuariosFinales(resultado);
      }else{
        
        let resultado = [];

        const datos = usuarios;

        const regex = new RegExp(`${query.trim()}`, 'i');

        const laBusqueda = datos.filter(datos => datos.data.email.search(regex) >= 0);

        let cero = 0;

        for (let buscado of laBusqueda) {
          if (cero <= limiteUsuarios) {
            resultado.push(buscado);
          }
            cero++;
        }

        CambioUsuariosFinales(resultado);
      }
  }

  const verMasUsuarios = async function(){

    let suma = limiteUsuarios + 20;
    CambioLimiteUsuarios(suma);

    buscarUsuario();
  }

  const verEsteUsuario = async function(item){

    CambioPantalla('usuario');
    CambioElUsuario(item);

    validarSiHayPlanContratado(item.id, 'planes').then(response =>{ console.log(response);

      CambiosDatosPlan(response);
    })
  }

  if (pantalla == 'usuario') {
    return(
      <section class="seccion1HomeAdmin">
        <div class="container">

          <div class="row">
            <div class="col-sm">
              <button onClick={() =>{
                CambioPantalla('');
                CambioElUsuario(false);
                CambiosDatosPlan(false);
              }} class="btn btn-primary botonLegalizarlo">Volver</button>
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
             <div class="formaDePago paddingTerminarPago">
                  <h5 class="tituloIndicacion">Información del Usuario</h5>
                  <h6 class="tituloIndicacion elCienPorCiento"></h6>
                  <div class="divPrecios elCienPorCiento">
                    <div>
                      <p class="infoPrecio bold">Nombre</p>
                      <p class="infoPrecio bold">Apellido</p>
                      <p class="infoPrecio bold">Email</p>
                      <p class="infoPrecio bold">Direcciòn</p>
                      <p class="infoPrecio bold">Pais</p>
                      <p class="infoPrecio bold">Codigo Postal</p>
                      <p class="infoPrecio bold">Telefono</p>
                      <p class="infoPrecio bold">Numero de identicicaciòn</p>
                    </div>
                    <div>
                      <p class="precios">{elUsuario.data.nombre}</p>
                      <p class="precios">{elUsuario.data.apellido}</p>
                      <p class="precios">{elUsuario.data.email}</p>
                      <p class="precios">{elUsuario.data.direccion}</p>
                      <p class="precios">{elUsuario.data.pais}</p>
                      <p class="precios">{elUsuario.data.codigoPostal}</p>
                      <p class="precios">{elUsuario.data.telefono}</p>
                      <p class="precios">{elUsuario.data.numeroIdentificacion}</p>
                    </div>
                  </div>
                  <hr/>
                  <h6 class="tituloIndicacion elCienPorCiento">Información del plan contratado</h6><br/>
                  <InfoPlanUsuario
                    datosPlan={datosPlan}
                  />
                  <hr/>
                </div>
            </div>
          </div>

        </div>
      </section>
    );
  }else{
    return(
      <section class="seccion1HomeAdmin">
        <div class="container">
          <div class="row">
            <div class="col-sm">
              <h4 class="tituloPaginaAdmin">Usuarios</h4>
              <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">Buscar por email</label>
                  <input type="text" class="form-control" id="buscar" onChange={buscarUsuario} />
              </div>
            </div>
          </div><br/>

          <div class="row">
            <div class="col-sm">
              <table class="table table-dark table-sm">
                <thead>
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Email</th>
                    <th scope="col">Accion</th>
                  </tr>
                </thead>
                <tbody class="table-group-divider">
                  {usuariosFinales.map((item) =>(
                    <tr>
                      <td>{item.data.nombre}</td>
                      <td>{item.data.email}</td>
                      <td>
                        <button onClick={() => verEsteUsuario(item)} class="btn btn-primary margen">Ver</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <button onClick={verMasUsuarios} class="btn btn-primary margen">Ver Mas</button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AdminUsuariosScreen;