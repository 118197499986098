import React, {useState, useEffect} from 'react';
import './documentos.css';
import {icoDocs, icoSearch, imgHombreEmpresario, flechaAbajo, u} from '../../assets/imgs.js';
import Footer from '../../components/footer/footer';
import ComoFunciona from '../../components/comoFunciona/comoFunciona';
import PreguntasYRespuestas from '../../components/preguntasYRespuestas/preguntasYRespuestas';
import {buscarDocumentos} from '../../backend/documentos/documentos.js';
import Swal from 'sweetalert2';

function DocumentosScreen(){

	const [pantalla, CambioPantalla] = useState('');
	const [documentos, CambioDocumentos] = useState([]);
	const [documentosFinales, CambioDocumentosFinales] = useState([]);
	const [idDoc, CambioIdDoc] = useState('');
	const [nombreDoc, CambioNombreDoc] = useState('');
	const [descripcionDoc, CambioDescripcionDoc] = useState('');
	const [precioDoc, CambioPrecioDoc] = useState('');
	const [tiempoEstimado, CambioTiempoEstimado] = useState('');
	const [paraQueSirve, CambioParaQueSirve] = useState('');
	const [documentosBuscados, CambioDocumentosBuscados] = useState(false);
	const [busquedaSolicitado, CambioBusquedaSolicitado] = useState(false);

	useEffect(() => {
		verRol();

		if (documentosBuscados == false) {
			buscarDocumentos('cliente').then(response =>{

				CambioDocumentos(response);
				CambioDocumentosFinales(response);
				validarSolicidado(response);
			});

			CambioDocumentosBuscados(true);
		}
	});

	const validarSolicidado = async function(losDocumentos){

		let idMasSolicitado = await localStorage.getItem('idMasSolicitado');

		if (idMasSolicitado != null) {

			Swal.fire({
		      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		      	showConfirmButton: false,
		      	allowOutsideClick: false,
		      	allowEnterKey: false,
		      	allowEscapeKey: false,
		    });

		    let elDocumento = false;

		    losDocumentos.forEach(element =>{

		    	if (element.id == idMasSolicitado) {

		    		elDocumento = element;
		    	}
		    })

		    if (elDocumento != false) {

		    	verDocumento(elDocumento);
		    }

		    await localStorage.removeItem('idMasSolicitado');
		}
	}

	const verRol = async function(){
	    let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
	       	let dataUser = JSON.parse(usuario);
	        if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {
	        	window.location.href = '/admin/home';
	        }
	    }
	}

	const verDocumento = async function(item){
		CambioPantalla('documento');
		CambioIdDoc(item.id);
		CambioNombreDoc(item.data.nombre);
		CambioDescripcionDoc(item.data.descripcion);
		CambioPrecioDoc(item.data.precio);
		CambioTiempoEstimado(item.data.tiempoEstimado);
		CambioParaQueSirve(item.data.paraQueSirve);
		window.setTimeout(verParaQueSirve, 2000, item.data.paraQueSirve);	
	}

	const verParaQueSirve = async function(t){
		console.log('ejecutando');
		console.log(t);
		document.getElementById('paraQueSirve').innerHTML = t;
		Swal.close();
	}

	const comprarDocumento = async function(){

		let usuario = await localStorage.getItem('usuarioLegalizarlo');

		if (usuario != null) {
			let dataUser = JSON.parse(usuario);

			if (dataUser.datosActualizados) {

				let data = {
					idProducto: idDoc,
					tipoDeCompra: 'documentos',
				};
				await localStorage.setItem('carrito', JSON.stringify(data));

				window.location.href = '/carrito';
			}else{

			    await localStorage.setItem('alertActualizar', 'alert');

			    window.location.href = '/cliente/home';
			}
		}else{
			let data = {
				idProducto: idDoc,
				tipoDeCompra: 'documentos',
			};
			await localStorage.setItem('carrito', JSON.stringify(data));

			window.location.href = '/carrito';
		}
	}

	const buscarProducto = async function(){

	    let query = document.getElementById('buscarDocs').value;

	    if (query === '') {

	    	CambioDocumentosFinales(documentos);
	    }else{
	        
	        let resultado = [];

	        const datos = documentos;

	        const regex = new RegExp(`${query.trim()}`, 'i');

	        const laBusqueda = datos.filter(datos => datos.data.nombre.search(regex) >= 0);

	        CambioDocumentosFinales(laBusqueda);
	    }
	}

	if (pantalla == 'documento') {
		return(
			<div class="containerDocs">
				<section class="seccion1ElDoc position-relative">
					<div class="transparenciaAzul position-relative py-5">
						<div class="container py-5">
							<div class="row">
								<div class="col-sm">
									<div class="containerDataDoc" style={{textAlign: 'left',}}>
										<span class="infoCategoria">Documentos legales > Sin categoría</span>
										<br />
										<h2 class="tituloSeccion1ElDoc" style={{margin: 0, textAlign: 'left',}}>{nombreDoc}</h2>
										<br />
										<p class="descripcionDoc" style={{margin: 0, textAlign: 'left',}}>{descripcionDoc}</p>
										<br />
										<br />
									</div>
								</div>
								<div class="col-sm">
									<div class="comprarDoc">
										<h4 class="comprarDocNombreDoc">{nombreDoc}</h4>
										<span class="precioDoc">${precioDoc} MXN</span>
										<br />
										<span class="ivaYTiempo">IVA Incluido<br />Tiempo estimado de edición: {tiempoEstimado} min</span>
										<hr />
										<buttom onClick={() => comprarDocumento()} class="btn btn-primary botonLegalizarlo" style={{width: 'fit-content',}}>Comprar documento</buttom>
									</div>
								</div>
							</div>
						</div>
						<div class="container-shape" data-negative="true">
							<svg xmlns="http://www.w3.org/2000/svg" class="shape" viewBox="0 0 1450 100" preserveAspectRatio="none"><path class="" d="M1450,60C76,66.3,0.8,9.8,00,0v100l1450,0V1C1450,5,1450,60,190,90z"></path></svg>
						</div>
					</div>
				</section>
				<section class="seccion2ElDoc">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<h3 class="elDocTituloSeccion2">¿Para qué sirve este documento?</h3>
							</div>
						</div>
						<div class="row">
							<div class="col-sm colorGris">
								<div class="paraQueSirve" id="paraQueSirve">
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class="seccion4Docs" id="seccionPreguntas">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<PreguntasYRespuestas
									idPagina={idDoc}
								/>
							</div>
						</div>
					</div>
				</section>
				<section class="seccion6">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<p class="preguntaSeccion6">¿Ya la utilizas?</p>
								<h2 class="tituloSeccion6">Firma Electrónica</h2>
								<p class="parrafoSeccion6">Firma documentos desde cualquier lugar y dispositivo, de manera inmediata y con mayor seguridad y certidumbre legal.</p>
								<a href="#" class="btn btn-primary botonLegalizarlo botonIzquierda">Conocer más</a>
							</div>
							<div class="col-sm position-relative">
								<img src={u} class="ue" />
								<img src={imgHombreEmpresario} class="hombreEmpresario" />
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		);
	}else{
		return(
			<div class="containerDocs">
				<section class="seccion1Docs position-relative">
					<div class='figure1doc'>

					</div>
					<div class='figure2doc'>

					</div>
					<div class="container-shape" data-negative="true">
						<svg xmlns="http://www.w3.org/2000/svg" class="shape" viewBox="0 0 1450 100" preserveAspectRatio="none"><path class="" d="M1450,60C76,66.3,0.8,9.8,00,0v100l1450,0V1C1450,5,1450,60,190,90z"></path></svg>		
					</div>
					<div class='figure3doc'>

					</div>
					<div class="container position-relative">
						<div class="row">
							<div class="col-sm d-flex align-items-center justify-content-center">
								<img class="icoDocs" src={icoDocs} />
								<h1 class="tituloSeccionDocs">Documentos</h1>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<p class="parrafoDocs">Elegimos me-ti-cu-lo-samente los documentos más usados en el ámbito empresarial, que necesitarás en tus operaciones diarias. Los separamos en categorías para que los encuentres de una manera muy, muy sencilla.</p>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<div class="container_search">
									<input type="search" onChange={buscarProducto} id="buscarDocs" class="inputBuscar" placeholder="Contrato de arrendamiento, Pagaré, etc..." />
									<img src={icoSearch} />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<h3 class="infoCantidadDocs">Encontramos <span class="cantidadDocs">{documentosFinales.length} Documentos</span> para tí</h3>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<img src={flechaAbajo} class="flechaAbajo" />
							</div>
						</div>
					</div>

				</section>
				<section class="seccion2Docs position-relative">
					<div class="backgroundGrayTop"><div class="imgbackgroundGray"></div></div>
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<div class="categorias">
									<span class="categoria activa">Todos</span>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm listaDocumentos">
								{documentosFinales.map((item) =>(
									<div class="documento">
										<div class="tituloDoc">
											<h3 class="tituloDocH4">{item.data.nombre}</h3>
										</div>
										<div class="infoDoc">
											<p class="descripcionCorta">{item.data.descripcionCortar}</p>
											<p class="precio">${item.data.precio} MXN <br/> <span style={{fontSize: 16, fontFamily: 'AvenirRegular',}}>Precio Incluye IVA 🙂</span></p>
											<buttom onClick={() => verDocumento(item)} class="btn btn-primary colorRosa">Ver Documento</buttom>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</section>
				<section class="seccion3Docs position-relative">
					<div class="backgroundGray"></div>
					<div class="container position-relative">
						<div class="row">
							<div class="col-sm">
								<ComoFunciona />
							</div>
						</div>
					</div>
				</section>
				<section class="seccion4Docs" id="seccionPreguntas">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<PreguntasYRespuestas
									idPagina={'documentos'}
								/>
							</div>
						</div>
					</div>
				</section>
				<section class="seccion6">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<p class="preguntaSeccion6">¿Ya la utilizas?</p>
								<h2 class="tituloSeccion6">Firma Electrónica</h2>
								<p class="parrafoSeccion6">Firma documentos desde cualquier lugar y dispositivo, de manera inmediata y con mayor seguridad y certidumbre legal.</p>
								<a href="#" class="btn btn-primary botonLegalizarlo botonIzquierda">Conocer más</a>
							</div>
							<div class="col-sm position-relative">
								<img src={u} class="ue" />
								<img src={imgHombreEmpresario} class="hombreEmpresario" />
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		);
	}
}

export default DocumentosScreen;
