import React, {useState, useEffect} from 'react';
import './nosotros-styles.css';
import {imgInmediates, imgClaridad, imgCostoBeneficio, imgSeguridadLegal, imgTodoIncluido, imgCompromiso} from '../../assets/imgs.js';
import Footer from '../../components/footer/footer';

function NosotrosScreen(){

	useEffect(() => {
		verRol();
	});

	const verRol = async function(){
	    let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
	       	let dataUser = JSON.parse(usuario);
	        if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {
	        	window.location.href = '/admin/home';
	        }
	    }
	}

	return(
		<div class="containerHome">
			<section class="seccion1Nosotros position-relative">
				<div class="container positionrelativetext">
					<div class="row">
						<div class="col-sm">
							<h1 class="tituloSeccion1Nosotros">Hace mucho tiempo en una galaxia muy, muy lejana...</h1>
							<p class="parrafoSeccion1Nosotros">...Miles de negocios dejaron de operar debido a la tramitología y a los viejos estándares legales.</p>
							<p class="parrafoSeccion1Nosotros">Entonces, nació Legalizarlo. Un grupo de abogados Jedi que decidió ser parte de la nueva era, poniendo al alcance de todos: documentos y servicios legales que todo joven padawan necesita para su crecimiento.</p>
							<p class="parrafoSeccion1Nosotros">Con el objetivo de crecer al planeta México y explorar la región desconocida, en 2020, decidieron reestructurar sus servicios y enfocarse en dar soluciones prácticas y seguras, a los problemas que se repetían una y otra vez. Ayudando a emprendedores y negocios a llegar del punto A al punto B, superando los tropiezos que implica ser una nueva empresa intergaláctica.</p>
							<br/><br/><br/>
						</div>
					</div>
				</div>
				<div class='figure1'></div>
				<div class='figure4'></div>
				<div class="container-shape" data-negative="true">
					<svg xmlns="http://www.w3.org/2000/svg" class="shape" viewBox="0 0 1450 100" preserveAspectRatio="none"><path class="" d="M1450,60C76,66.3,0.8,9.8,00,0v100l1450,0V1C1450,5,1450,60,190,90z"></path></svg>
				</div>
				<div class='figure3doc'></div>
			</section>
			<section class="seccion5Nosotros">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h1 class="tituloSeccion1Nosotros">¿Qué hacemos?</h1>
							<p class="parrafoSeccion1Nosotros">Conociendo el rollo que implica ser una empresa formal en México, analizamos y seleccionamos me-ti-cu-losamente los documentos que SÍ O SÍ, vas a utilizar en tu vida empresarial.</p>
							<p class="parrafoSeccion1Nosotros">En Legalizarlo encontrarás la solución a ese papeleo que necesitas, a un precio accesible y creado por abogados expertos en la materia. Olvídate de las “letras chiquitas”, empaquetamos los servicios todo en uno. Ya sea un documento o asesoría legal, el precio que ves, es el precio final. Fácil, rápido y sin engaños.</p>
							<p class="parrafoSeccion1Nosotros negrillas">Damos nuestro mayor esfuerzo para ser tu abogado de cabecera.</p>
							<br/><br/><br/>
						</div>
					</div>
				</div>
			</section>
			<section class="seccion4">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h2 class="preguntaSeccion4 centrar">¿Por qué Legalizarlo?</h2>
						</div>
					</div>
					<div class="row">
						<div class="col-sm centrar">
							<div class="porques">
								<img src={imgInmediates} class="imgPorque" />
								<h3 class="tituloPorque">Inmediatez</h3>
								<p class="parrafoPorque">Descarga y personaliza, al instante, los documentos legales más importantes que usarás en tu vida empresarial.</p>
							</div>
							<div class="porques">
								<img src={imgClaridad} class="imgPorque" />
								<h3 class="tituloPorque">Claridad</h3>
								<p class="parrafoPorque">Deja la “tramitología” en nuestras manos. Conocemos cada detalle legal que tu empresa necesita.</p>
							</div>
							<div class="porques">
								<img src={imgCostoBeneficio} class="imgPorque" />
								<h3 class="tituloPorque">Costo-Beneficio</h3>
								<p class="parrafoPorque">Nos interesa tu crecimiento… en serio. Y por ello te ofrecemos servicios de calidad, sin precios excesivos.</p>
							</div>
							<div class="porques">
								<img src={imgSeguridadLegal} class="imgPorque" />
								<h3 class="tituloPorque">Seguridad Legal</h3>
								<p class="parrafoPorque">Todos nuestros trámites y documentos, están realizados y evaluados por abogados expertos en la materia.</p>
							</div>
							<div class="porques">
								<img src={imgTodoIncluido} class="imgPorque" />
								<h3 class="tituloPorque">Todo incluido</h3>
								<p class="parrafoPorque">Te ofrecemos una selección de servicios personalizados que incluyen todo lo que necesitas. Sin gastos ocultos ni choro mareador.</p>
							</div>
							<div class="porques">
								<img src={imgCompromiso} class="imgPorque" />
								<h3 class="tituloPorque">Compromiso</h3>
								<p class="parrafoPorque">Te ofrecemos una selección de servicios personalizados que incluyen todo lo que necesitas. Sin gastos ocultos ni choro mareador.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}

export default NosotrosScreen;
