import React, {useState, useEffect} from 'react';
import './abogados-styles.css';
import {
	abogadosSeccion1,
	icoDocs, 
	icoSearch, 
	imgHombreEmpresario, 
	flechaAbajo, 
	u, logoabogadotop,
} from '../../assets/imgs.js';
import Footer from '../../components/footer/footer';
import ComoFunciona from '../../components/comoFunciona/comoFunciona';
import PreguntasYRespuestas from '../../components/preguntasYRespuestas/preguntasYRespuestas';
import {buscarServicios} from '../../backend/servicios/servicios';
import {consultarServicioPersonalizado} from '../../backend/serviciosPersonalizados/serviciosPersonalizados';
import Swal from 'sweetalert2';

function AbogadosScreen(){

	const [pantalla, CambioPantalla] = useState('');
	const [servicios, CambioServicios] = useState([]);
	const [rolVisto, CambioRolVisto] = useState(false);
	const [serviciosBuscados, CambioServiciosBuscados] = useState(false);
	const [nombreServicio, CambioNombreServicio] = useState('');
	const [descripcionServicio, CambioDescripcionServicio] = useState('');
	const [precioServicio, CambioPrecioServicio] = useState('');
	const [idServicio, CambioIdServicio] = useState('');
	const [paraQueSirve, CambioParaQueSirve] = useState('');
	const [verServicioPersonalizado, CambioVerServicioPersonalizado] = useState(false);
    const [comprobarServicioPersonalizado, CambioComprobarServicioPersonalizado] = useState(false);

	useEffect(() => {
		if (rolVisto == false) {
			verRol();
		}
		if (serviciosBuscados == false) {
			buscarServicios('cliente', '').then(response =>{

	        	CambioServicios(response);
	        	validarSolicidado(response);
	        });

	        CambioServiciosBuscados(true);
		}

		if (comprobarServicioPersonalizado == false) {

			validarServicioPersonaliza();

			CambioComprobarServicioPersonalizado(true);
		}
	});

	const validarServicioPersonaliza = async function(){

		let idServicioPersoalizado = await localStorage.getItem('verServicioPersonalizado');
		if (idServicioPersoalizado != null) {

			Swal.fire({
		      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		      	showConfirmButton: false,
		      	allowOutsideClick: false,
		      	allowEnterKey: false,
		      	allowEscapeKey: false,
		    });

		    CambioVerServicioPersonalizado(true);

		    let elServicioPersonalizado = await consultarServicioPersonalizado(idServicioPersoalizado);

		    if (elServicioPersonalizado != false) {

		    	verServicio(elServicioPersonalizado);
		    }else{

		    	Swal.close();
		    }

		    await localStorage.removeItem('verServicioPersonalizado');
		}
	}

	const validarSolicidado = async function(losServicios){

		let idMasSolicitado = await localStorage.getItem('idMasSolicitado');

		if (idMasSolicitado != null) {

			Swal.fire({
		      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		      	showConfirmButton: false,
		      	allowOutsideClick: false,
		      	allowEnterKey: false,
		      	allowEscapeKey: false,
		    });

		    let elServicio = false;

		    losServicios.forEach(element =>{

		    	if (element.id == idMasSolicitado) {

		    		elServicio = element;
		    	}
		    })

		    if (elServicio != false) {

		    	verServicio(elServicio);
		    }

		    await localStorage.removeItem('idMasSolicitado');
		}
	}

	const verRol = async function(){
	    let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
	       	let dataUser = JSON.parse(usuario);
	        if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {
	        	window.location.href = '/admin/home';
	        }else{
	        	CambioRolVisto(true);
	        }
	    }
	}

	const verServicio = async function(item){
		CambioPantalla('servicio');
		CambioIdServicio(item.id);
		CambioNombreServicio(item.data.nombre);
		CambioDescripcionServicio(item.data.descripcion);
		CambioPrecioServicio(item.data.precio);
		//CambioTiempoEstimado(item.data.tiempoEstimado);
		CambioParaQueSirve(item.data.paraQueSirve);
		window.setTimeout(verParaQueSirve, 2000, item.data.paraQueSirve);	
	}

	const verParaQueSirve = async function(t){
		console.log('ejecutando');
		console.log(t);
		document.getElementById('paraQueSirve').innerHTML = t;
		Swal.close();
	}

	const comprarServicio = async function(){

		let usuario = await localStorage.getItem('usuarioLegalizarlo');

		if (usuario != null) {
			let dataUser = JSON.parse(usuario);

			if (dataUser.datosActualizados) {

				let elTipoDeCompra = '';

				if (verServicioPersonalizado == true) {

					elTipoDeCompra = 'serviciosPersonalizados';
				}else{

					elTipoDeCompra = 'servicios';
				}

				let data = {
					idProducto: idServicio,
					tipoDeCompra: elTipoDeCompra,
				};
				await localStorage.setItem('carrito', JSON.stringify(data));

				window.location.href = '/carrito';
			}else{

			    await localStorage.setItem('alertActualizar', 'alert');

			    window.location.href = '/cliente/home';
			}
		}else{
			let data = {
				idProducto: idServicio,
				tipoDeCompra: 'servicios',
			};
			await localStorage.setItem('carrito', JSON.stringify(data));

			window.location.href = '/carrito';
		}
	} 

	if (pantalla == 'servicio') {
		return(
			<div class="containerDocs">
				<section class="seccion1ElServicio position-relative">
					<div class="transparenciaNada position-relative">
						<div class="container py-5">
							<div class="row">
								<div class="col-sm">
									<span class="infoCategoria">Contacta un Abogado > {nombreServicio}</span>
									<br />
									<h2 class="tituloSeccion1ElDoc">{nombreServicio}</h2>
									<br />
									<p class="descripcionDoc">{descripcionServicio}</p>
									<br />
									<br />
								</div>
								<div class="col-sm">
									<div class="comprarDoc">
										<h4 class="comprarDocNombreDoc">{nombreServicio}</h4>
										<span class="precioDoc">${precioServicio} MXN</span>
										<br />
										<span class="ivaYTiempo"><cite>IVA Incluido</cite></span>
										<hr />
										<buttom onClick={() => comprarServicio()} class="btn btn-primary colorPurpura">Comprar servicio</buttom>
									</div>
								</div>
							</div>
						</div>
						<div class="container-shape" data-negative="true">
					<svg xmlns="http://www.w3.org/2000/svg" class="shape" viewBox="0 0 1450 100" preserveAspectRatio="none"><path class="" d="M1450,60C76,66.3,0.8,9.8,00,0v100l1450,0V1C1450,5,1450,60,190,90z"></path></svg>		</div>
					</div>

				</section>
				<section class="seccion2ElDoc">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<h3 class="elDocTituloSeccion2">¿Qué incluye nuestro servicio?</h3>
							</div>
						</div>
						<div class="row">
							<div class="col-sm colorGris" id="paraQueSirve">
								
							</div>
						</div>
					</div>
				</section>
				<section class="seccion4Docs" id="seccionPreguntas">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<PreguntasYRespuestas
									idPagina={idServicio}
								/>
							</div>
						</div>
					</div>
				</section>

				<section class="seccion2ElServicio">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<div class="agerdarCita">
									<div class="elParrafoAgendar">
										<p class="parrafoAgendar">
											¿Tienes dudas? Agenda una<br/>
											llamada y conversamos para<br/>
											identificar si este servicio es lo<br/>
											que necesitas.
										</p>
										<div class="lineaAgendar"></div>
									</div>
									<buttom onClick={comprarServicio} class="btn btn-primary botonLegalizarlo">
										Agendar llamada
									</buttom>
								</div>
							</div>
						</div>
					</div>
				</section>
				
				<Footer />
			</div>
		);
	}else{
		return(
			<div class="containerAbogados">
				<section class="seccion1Abogados position-relative">
					<div class="container container pt mt">
						<div class="row ">
							<div class="col-sm mb-4 d-flex align-items-center justify-content-center flex-column">
								<h1 class="tituloSeccion1Abogados">¿Necesitas un abogado de cabecera?</h1>
								<p class="parrafoSeccion1Abogados">Ya sea una consulta rápida y puntual o todo el seguimiento a tus trámites legales, aquí encontrarás una selección de nuestros servicios todo-en-uno. Sin costos ocultos ni letras pequeñas, para que tengas claridad de cuánto cuesta y qué incluye.</p>
							</div>
							<div class="col-sm position-relative d-flex justify-content-center ">

								<img src={abogadosSeccion1} class="imgAbogadosSeccion1" />
							</div>
						</div>
					</div>
					<div class="container-shape" data-negative="true">
					<svg xmlns="http://www.w3.org/2000/svg" class="shape" viewBox="0 0 1450 100" preserveAspectRatio="none"><path class="" d="M1450,60C76,66.3,0.8,9.8,00,0v100l1450,0V1C1450,5,1450,60,190,90z"></path></svg>		</div>
				</section>

				<section class="seccion3Abogado">
					<div class="container">
						<div class="">
							<div class="col-sm">
								<div class="categorias">
									<span class="tituloSeccion2Abogados">Servicios Legales</span>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								{servicios.map((item) =>(
									<div class="documento">
										<img class="imgServicio" src={item.data.foto} />
										<div class="tituloServicio">
											<h3 class="tituloDocH4">{item.data.nombre}</h3>
										</div>
										<div class="infoDoc">
											<p class="descripcionCorta">{item.data.descripcionCortar}</p>
											<p class="precio">${item.data.precio} MXN <br/> <span style={{fontSize: 16, fontFamily: 'AvenirRegular',}}>Precio Incluye IVA 🙂</span></p>
											<buttom onClick={() => verServicio(item)} class="btn btn-primary colorRosa">Ver Servicio</buttom>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</section>

				<section class="seccion3Abogados position-relative">

					<div clas="container position-relative">
						<div class="">
							<div class="col-sm background-f5f5fc">
								<ComoFunciona />
							</div>
						</div>
					</div>
					<div class="backgroundGray bg-white"></div>
				</section>
				<section class="seccion4Abogados pb-5" id="seccionPreguntas">
					<div clas="container pb-5">
						<div class="">
							<div class="col-sm pb-5">
								<PreguntasYRespuestas 
									idPagina={'abogados'}
								/>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		);
	}
}

export default AbogadosScreen;
