import React, {useState, useEffect} from 'react';
import './footer.css';
import {logoTransparente, facebookLegalizarlo, instagramLegalizarlo, linkeningLegalizarlo} from '../../assets/imgs.js';
import {ModalAuth, ModalCambiarPassword} from '../modals/modals.js';
import {
	consultarAvisoPrivacidad,
	consultarTerminosCondiciones,
} from '../../backend/preguntasYRespuestas/preguntasYRespuestas.js';

function Footer(){

	const [busquedaAvisoPrivacidad, CambioBusquedaAvisoPrivacidad] = useState(false);
	const [avisoPrivacidad, CambioAvisoPrivacidad] = useState('');
	const [busquedaTerminosCondiciones, CambioBusquedaTerminosCondiciones] = useState(false);
	const [terminosCondiciones, CambioTerminosCondiciones] = useState('');
	const [mostrarAviso, CambioMostrarAviso] = useState(false);
	const [mostrarTerminos, CambioMostrarTerminos] = useState(false);

	/*useEffect(() => {

		if (busquedaAvisoPrivacidad == false) {

			consultarAvisoPrivacidad().then(response =>{

				if (response != false) {

					CambioAvisoPrivacidad(response.data.texto);
				}
			});

			CambioBusquedaAvisoPrivacidad(true);
		}

		if (busquedaTerminosCondiciones == false) {

			consultarTerminosCondiciones().then(response =>{

				if (response != false) {

					CambioTerminosCondiciones(response.data.texto);
				}
			});

			CambioBusquedaTerminosCondiciones(true);
		}
	});*/

	const verTexto = async function(elTexto){

		if (elTexto == 'aviso') {

			CambioMostrarAviso(true);
			setTimeout(poner, 500, avisoPrivacidad, 'aviso');
		}else if (elTexto == 'terminos') {

			CambioMostrarTerminos(true);
			setTimeout(poner, 500, terminosCondiciones, 'terminos');
		}
	}

	const poner = async function(losDatos, idElement){

		document.getElementById(idElement).innerHTML = losDatos;
	}

	return(
		<>
			<section class="footer">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<img src={logoTransparente} />
						</div>
						<div class="col-sm">
							<ul class="listaFooter">
								<li onClick={() => window.location.href = '/'}>Firma electrónica</li>
								<li onClick={() => window.location.href = '/planes'}>Nuestros planes</li>
								<li onClick={() => window.location.href = '/nosotros'}>Nosotros</li>
								<li onClick={() => window.location.href = '/aviso-de-privacidad'}>Aviso de privacidad</li>
								<li onClick={() => window.location.href = '/terminos-y-condiciones'}>Términos y Condiciones</li>
							</ul>
						</div>
						<div class="col-sm">
							<ul class="listaFooter">
								<li onClick={() => window.location.href = '/documentos'}>Documentos legales</li>
								<li onClick={() => window.location.href = '/abogados'}>Contactar abogado</li>
								<li onClick={() => window.location.href = '/'}>Blog</li>
								<li onClick={() => window.location.href = '#'}>Ingresar</li>
							</ul>
						</div>
						<div class="col-sm containerRedesSociales">
							<img onClick={() => window.open('https://www.facebook.com/legalizarlo', '_blank')} class="imgSocial" src={facebookLegalizarlo} />
							<img onClick={() => window.open('https://www.instagram.com/legalizarlo', '_blank')} class="imgSocial" src={instagramLegalizarlo} />
							<img onClick={() => window.open('https://www.linkedin.com/company/legalizarlo/', '_blank')} class="imgSocial" src={linkeningLegalizarlo} />
						</div>
					</div>
					<hr/>
					<div class="row">
						<div class="col-sm">
							<h6 class="h4Footer">® Legalizarlo Derechos Reservados | Diseño Web: diho.mx</h6>
						</div>
					</div>
				</div>
			</section>
			<ModalAuth mostrar={mostrarAviso}>
				<p style={{padding: 20, cursor: 'pointer', width: '100%', textAlign: 'right', color: 'red', fontWeight: 'bold',}} onClick={() => CambioMostrarAviso(false)}>X</p>
				<div id="aviso" style={{padding: 20,}}></div>
			</ModalAuth>
			<ModalAuth mostrar={mostrarTerminos}>
				<p style={{padding: 20, cursor: 'pointer', width: '100%', textAlign: 'right', color: 'red', fontWeight: 'bold',}} onClick={() => CambioMostrarTerminos(false)}>X</p>
				<div id="terminos" style={{padding: 20,}}></div>
			</ModalAuth>
		</>
	);
}

export default Footer;