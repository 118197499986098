import React, {useState, useEffect} from 'react';
import './administradores-styles.css';
import Swal from 'sweetalert2';
import {resgistrarUsuario} from '../../../backend/auth/auth';
import {terminarActualizacion} from '../../../backend/cliente/cliente';
import {buscarSubAdmins, editarEstadoSubAdmin, editarRestricciones} from '../../../backend/administradores/administradores';
import {LasRestricciones} from '../../../components/administradores/administradores';
import {BotonActivo} from '../../../components/documentos/documentos';
import {ModalAuth} from '../../../components/modals/modals.js';

function AdminAdministradoresScreen(){

	const [rol, CambioRol] = useState(false);
	const [pantalla, CambioPantalla] = useState('');
	const [email, CambioEmail] = useState('');
	const [subAdmins, CambioSubAdmins] = useState([]);
	const [tituloModal, CambioTituloModal] = useState('');
	const [mostrarEditarSubAdmin, CambioMostrarEditarSubAdmin] = useState(false);
	const [estadoSubAdmin, CambioEstadoSubAdmin] = useState(false);
	const [idSubAdmin, CambioIdSubAdmin] = useState(false);

	useEffect(() => {

		if (rol == false) {
			verRol();
		}
	});

	const verRol = async function(){
	    let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
	       	let dataUser = JSON.parse(usuario);
	        if (dataUser.rol != 'admin') {
	        	window.location.href = '/';
	        }else{
	        	buscarSubAdmins().then(response =>{ console.log(response);
	        		CambioSubAdmins(response);
	        	});
	        	CambioRol(dataUser.rol);
	        }
	    }else{
	    	window.location.href = '/';
	    }
	}

	const procesarSubAdmin = async function(){

		if (email == '') {
			Swal.fire({
			  title: 'Por favor complete todos los campos para continuar.',
			  icon: 'info',
			});
		}else{

			let restricciones = [];

			if (document.getElementById('preguntasAdmin').checked) {

				restricciones.push('preguntas');
			}

			if (document.getElementById('documentosAdmin').checked) {

				restricciones.push('documentos');
			}

			if (document.getElementById('serviciosAdmin').checked) {

				restricciones.push('servicios');
			}

			if (document.getElementById('citasAdmin').checked) {

				restricciones.push('citas');
			}

			if (document.getElementById('planesAdmin').checked) {

				restricciones.push('planes');
			}

			if (document.getElementById('cuponesAdmin').checked) {

				restricciones.push('cupones');
			}

			if (document.getElementById('usuariosAdmin').checked) {

				restricciones.push('usuarios');
			}

			if (document.getElementById('serviciosPersonalizadosAdmin').checked) {

				restricciones.push('serviciosPersonalizados')
			}

			if (idSubAdmin != false) {
				editarRestricciones(idSubAdmin, restricciones).then(response =>{
					window.location.reload();
				})
			}else{

				let data = {
					email: email,
					password: '12345678',
					nombre: 'Sub Admin',
					rol: 'subAdmin',
					restricciones: restricciones,
					telefono: '0000000000',
				};

				resgistrarUsuario(data).then(response =>{
					if (response != false) {
						window.location.reload();
					}else{
						Swal.fire({
						  title: 'Lo sentimos pero hubo un error al tratar de procesar los datos.',
						  icon: 'error',
						});
					}
				})
			}
		}
	}

	const cambiarEstado = async function(id, estado){

		if(estado){
	      CambioTituloModal('¿Desea desactivar este usuario?')
	      CambioMostrarEditarSubAdmin(true);
	      CambioEstadoSubAdmin(estado);
	      CambioIdSubAdmin(id);
	    }else{
	      CambioTituloModal('¿Desea activar este usuario?')
	      CambioMostrarEditarSubAdmin(true);
	      CambioEstadoSubAdmin(estado);
	      CambioIdSubAdmin(id);
	    }
	}

	const terminarDeCambiarEstado = async function(){
		editarEstadoSubAdmin(idSubAdmin, estadoSubAdmin).then(response =>{
	      window.location.reload();
	    })
	}

	const gestionarSubAdmin = async function(item){
		CambioIdSubAdmin(item.id);
		CambioPantalla('gestionar');
		CambioEmail(item.data.email);

		setTimeout(ponerDatos, 500, item);
	}

	const ponerDatos = async function(item){

		document.getElementById('email').value = item.data.email;
		document.getElementById('email').setAttribute("readonly", "");

		item.data.restricciones.forEach(element =>{
			document.getElementById(element+'Admin').checked = true;
		});
	}

	if (pantalla == 'gestionar') {
		return(
			<section class="seccion1HomeAdmin">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h4 class="tituloPaginaAdmin">Gestionar Sub administrador</h4>
							<div class="mb-3">
							    <label for="exampleInputEmail1" class="form-label">Email</label>
							    <input type="text" class="form-control" id="email" onChange={() => CambioEmail(document.getElementById('email').value)} />
							</div>

							<h6 class="tituloPaginaAdmin">Restricciones</h6>

							<div class="row">
				              <div class="col-sm">
				                <div class="diasSemana">

				                	<div class="formDia">
					                    <div class="mb-3">
					                      <input class="form-check-input margenServiciosLeft" type="checkbox" id="preguntasAdmin" />
					                      <label class="form-check-label margenServiciosLeft" for="preguntasAdmin">
					                        No podra gestionar Preguntas Frecuentes
					                      </label>
					                    </div>
									</div>

									<div class="formDia">
					                    <div class="mb-3">
					                      <input class="form-check-input margenServiciosLeft" type="checkbox" id="documentosAdmin" />
					                      <label class="form-check-label margenServiciosLeft" for="documentosAdmin">
					                        No podra gestionar Documentos
					                      </label>
					                    </div>
									</div>

									<div class="formDia">
					                    <div class="mb-3">
					                      <input class="form-check-input margenServiciosLeft" type="checkbox" id="serviciosAdmin" />
					                      <label class="form-check-label margenServiciosLeft" for="serviciosAdmin">
					                        No podra gestionar Servicios
					                      </label>
					                    </div>
									</div>

									<div class="formDia">
					                    <div class="mb-3">
					                      <input class="form-check-input margenServiciosLeft" type="checkbox" id="citasAdmin" />
					                      <label class="form-check-label margenServiciosLeft" for="citasAdmin">
					                        No podra gestionar Citas
					                      </label>
					                    </div>
									</div>

									<div class="formDia">
					                    <div class="mb-3">
					                      <input class="form-check-input margenServiciosLeft" type="checkbox" id="planesAdmin" />
					                      <label class="form-check-label margenServiciosLeft" for="planesAdmin">
					                        No podra gestionar Planes
					                      </label>
					                    </div>
									</div>

									<div class="formDia">
					                    <div class="mb-3">
					                      <input class="form-check-input margenServiciosLeft" type="checkbox" id="serviciosPersonalizadosAdmin" />
					                      <label class="form-check-label margenServiciosLeft" for="serviciosPersonalizadosAdmin">
					                        No podra gestionar Servicios Personalizados
					                      </label>
					                    </div>
									</div>

									<div class="formDia">
					                    <div class="mb-3">
					                      <input class="form-check-input margenServiciosLeft" type="checkbox" id="cuponesAdmin" />
					                      <label class="form-check-label margenServiciosLeft" for="cuponesAdmin">
					                        No podra gestionar Cupones
					                      </label>
					                    </div>
									</div>

									<div class="formDia">
					                    <div class="mb-3">
					                      <input class="form-check-input margenServiciosLeft" type="checkbox" id="usuariosAdmin" />
					                      <label class="form-check-label margenServiciosLeft" for="usuariosAdmin">
					                        No podra gestionar Usuarios
					                      </label>
					                    </div>
									</div>

								</div>
				              </div>
				            </div>

							<button onClick={procesarSubAdmin} class="btn btn-success letraBotonWarning margen">Guardar</button>
							<button onClick={() => window.location.reload()} class="btn btn-danger" id="botonCancelar">Cancelar</button>
						</div>
					</div>
				</div>
			</section>
		);
	}else{

		return(
			<section class="seccion1HomeAdmin">
				<div class="container">
					<div class="row">
						<div class="col-sm paddingArribaAbajo">
							<button onClick={() => CambioPantalla('gestionar')} class="btn btn-primary">Crear Sub administrador</button>
						</div>
					</div><br/>
					<div class="row">
						<div class="col-sm">
							<table class="table table-dark table-sm">
							  <thead>
							    <tr>
							      <th scope="col">Email</th>
							      <th scope="col">Restricciones</th>
							      <th scope="col">Accion</th>
							    </tr>
							  </thead>
							  <tbody class="table-group-divider">
							  	{subAdmins.map((item) =>(
							  		<tr>
								      <td>{item.data.email}</td>
								      <td>
								      	<LasRestricciones
								      		restricciones={item.data.restricciones}
								      	/>
								      </td>
								      <td>
								      	<button onClick={() => gestionarSubAdmin(item)} class="btn btn-primary margen">Editar</button>
										<BotonActivo
				                            estado={item.data.activo}
				                            cambiarEstado={() => cambiarEstado(item.id, item.data.activo)}
				                        />
								      </td>
								    </tr>
							  	))}
							  </tbody>
							</table>
						</div>
					</div>
				</div>

				<ModalAuth mostrar={mostrarEditarSubAdmin}>
		          <div class="container">
		            <div class="row">
		              <div class="col-sm modalCambioEstado">
		                <h4>{tituloModal}</h4>
		                <button onClick={() => terminarDeCambiarEstado()} class="btn btn-success margenBotonTextCustom">Si</button>
		                <button onClick={() => {
		                  CambioMostrarEditarSubAdmin(false);
		                  CambioIdSubAdmin(false);
		                }} class="btn btn-danger margenBotonTextCustom">No</button>
		              </div>        
		            </div>
		          </div>
		        </ModalAuth>
			</section>
		);
	}
}

export default AdminAdministradoresScreen;