import React, {useState, useEffect} from 'react';
import './carrito-styles.css';
import {buscarProducto, comprarProducto, searchCupon, editarCupon, validarSiHayPlanContratado} from '../../backend/carrito/carrito';
import Login from '../../components/login/login';
import Swal from 'sweetalert2';
import {enviarCodigo, smsValidarCodigo, resgistrarUsuario, reenviarCodigo, iniciarSesion, recuperarPassword, guardarNuevaPassword} from '../../backend/auth/auth.js';
import {imgPagoSeguro, imgSsl, imgTajetasDeCredito, imgPagoOxxo, imgPagoSpei} from '../../assets/imgs.js';
import {FormasDePago, SeccionReferencia, DescuentoPlan} from '../../components/carrito/carrito';
import {Link} from 'react-router-dom';

class CarritoScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      usuario: '',
      pantalla: '',
      infoProducto: '',
      producto: '',
      tituloCarrito: '',
      authForm: 'loginCarrito',
      loginEmail: '',
      loginPassword: '',
      registroEmail: '',
      registroPassword: '',
      confirmPassword: '',
      registroNombre: '',
      telefono: '',
      codigo: '',
      hayTiempo: false,
      recuperarEmail: '',
      nombreDeProductoCarrito: '',
      codigoDescuento: '',
      precioProducto: '',
      tarjeta: '',
      oxxo: '',
      spei: '',
      nombreTarjeta: '',
      numeroTarjeta: '',
      mesTarjeta: '',
      anoTarjeta: '',
      codigoTarjeta: '',
      conektaTokenId: '',
      uid: '',
      nombre: '',
      email: '',
      telefono: '',
      pagarCon: '',
      mensajePagoFinaliza: '',
      dataProducto: '',
      tipoPasoSiguiente: '',
      montodescuento: 0,
      tempPrecio: '',
      cupon: '',
      nombrePlan: '',
      pantallaTituloPlan: '',
      pantallaDescuentoPlan: '',
      elDescuentoPlan: 0,
      dataCompra: '',
      idOrden: '',
      pagado: true,
      idCompra: '',
    };
  }

  componentDidMount = async () =>{
    let usuario = await localStorage.getItem('usuarioLegalizarlo');
    let uid = '';
    if (usuario != null) {
        let dataUser = JSON.parse(usuario);
        if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {
          window.location.href = '/admin/home';
        }else{
          uid = dataUser.uid;
          this.setState({
            usuario: dataUser,
            pantalla: 'pago',
            uid: dataUser.uid,
            nombre: dataUser.nombre,
            email: dataUser.email,
            telefono: dataUser.telefono,
          });//key_oZaJi2Ug3ntQ6w51NLS12vh
          window.Conekta.setPublicKey('key_MVE55XE2ofJvbvG9vZhmJ7Y');
        }
    }

    document.getElementById('estilosMenu').innerHTML = '.navbar>.container-fluid{'+
              'justify-content: center;'+
            '}'+
            '.itemsLegalizarloMenu{'+
            'display: none;'+
            '}';

    let carrito = await localStorage.getItem('carrito');
    if (carrito != null) {
      let dataProducto = JSON.parse(carrito);

      this.setState({
        infoProducto: dataProducto,
      });

      if (dataProducto.tipoDeCompra == 'documentos') {
        this.setState({
          tituloCarrito: 'Documento',
          tipoPasoSiguiente: 'Edita tu nuevo documento',
        });
      }else if (dataProducto.tipoDeCompra == 'servicios') {
        this.setState({
          tituloCarrito: 'Servicio',
          tipoPasoSiguiente: 'Agendar cita',
        });
      }else if (dataProducto.tipoDeCompra == 'planes') {
        this.setState({
          tituloCarrito: 'Plan',
          tipoPasoSiguiente: 'Navegar por Legalizarlo',
        });
      }else if (dataProducto.tipoDeCompra == 'serviciosPersonalizados') {
        this.setState({
          tituloCarrito: 'Servicio Personalizado',
          tipoPasoSiguiente: 'Agendar cita',
        });
      }

      await buscarProducto(dataProducto).then(response =>{
        if (response != false) { console.log(response);
          let suma = parseFloat(response.data.precio); /*+ 9.51;*/
          this.setState({
            producto: response,
            nombreDeProductoCarrito: response.data.nombre,
            precioProducto: suma,
            tempPrecio:suma,
            dataProducto: dataProducto,
          });

          this.validarPlan(dataProducto, suma);
        }else{
          window.location.href = '/';
        }
      });
    }else{
      window.location.href = '/';
    }
  }

  componentDidUpdate = async () =>{
    document.getElementById('estilosMenu').innerHTML = '.navbar>.container-fluid{'+
              'justify-content: center;'+
            '}'+
            '.itemsLegalizarloMenu{'+
            'display: none;'+
            '}';
  }

  validarPlan = async (dataProducto, elPrecio) =>{

    let usuario = await localStorage.getItem('usuarioLegalizarlo');
    if (usuario != null) {
      let dataUser = JSON.parse(usuario);

      let hayPlan = await validarSiHayPlanContratado(dataUser.uid, 'planes');
      console.log(hayPlan);
      if (hayPlan != false) {

        if (hayPlan.data.pagado && hayPlan.data.caracteristicas.activo) {

          if (dataProducto.tipoDeCompra == 'documentos') { 

            if (hayPlan.data.caracteristicas.dataPlan.documentosIlimitados) {

              this.setState({
                pantalla: 'resumen',
                nombrePlan: hayPlan.data.caracteristicas.dataPlan.nombre,
                pantallaTituloPlan: 'titulo',
                pantallaDescuentoPlan: 'descuento',
                elDescuentoPlan: 100,
                precioProducto: 0,
                tempPrecio: 0,
              });
            }else if (parseInt(hayPlan.data.caracteristicas.dataPlan.descuentoDocumentos) > 0 || hayPlan.data.caracteristicas.dataPlan.descuentoDocumentos != '') {

              let planPorcentaje = parseInt(hayPlan.data.caracteristicas.dataPlan.descuentoDocumentos);
              let numero = '';

              if (planPorcentaje < 10) {
                numero = parseFloat('0.0'+planPorcentaje);
              }else{
                numero = parseFloat('0.'+planPorcentaje);
              }

              let operacion = elPrecio * numero;
              let resta = elPrecio - operacion;

              this.setState({
                nombrePlan: hayPlan.data.caracteristicas.dataPlan.nombre,
                pantallaTituloPlan: 'titulo',
                pantallaDescuentoPlan: 'descuento',
                elDescuentoPlan: planPorcentaje,
                precioProducto: resta.toFixed(2),
                tempPrecio: resta.toFixed(2),
              });
            }
          }else if (dataProducto.tipoDeCompra == 'servicios') {

            if (hayPlan.data.caracteristicas.dataPlan.serviciosIlimitados) {

              this.setState({
                pantalla: 'resumen',
                nombrePlan: hayPlan.data.caracteristicas.dataPlan.nombre,
                pantallaTituloPlan: 'titulo',
                pantallaDescuentoPlan: 'descuento',
                elDescuentoPlan: 100,
                precioProducto: 0,
                tempPrecio: 0,
              });
            }else if (parseInt(hayPlan.data.caracteristicas.dataPlan.descuentoServicios) > 0 || hayPlan.data.caracteristicas.dataPlan.descuentoServicios != '') {

              let planPorcentaje = parseInt(hayPlan.data.caracteristicas.dataPlan.descuentoServicios);
              let numero = '';

              if (planPorcentaje < 10) {
                numero = parseFloat('0.0'+planPorcentaje);
              }else{
                numero = parseFloat('0.'+planPorcentaje);
              } 

              let operacion = elPrecio * numero;
              let resta = elPrecio - operacion;

              this.setState({
                nombrePlan: hayPlan.data.caracteristicas.dataPlan.nombre,
                pantallaTituloPlan: 'titulo',
                pantallaDescuentoPlan: 'descuento',
                elDescuentoPlan: planPorcentaje,
                precioProducto: resta.toFixed(2),
                tempPrecio: resta.toFixed(2),
              });
            }
          }
        }
      }
    }
  }

  codigoSms = async () =>{

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    if (this.state.telefono === '') {
      Swal.fire({
        title: 'Por favor introduzca un numero para continuar.',
        icon: 'info',
      });
    }else if (isNaN(this.state.telefono)) {
      Swal.fire({
        title: 'Solo se aceptan caracteres numerico.',
        icon: 'info',
      });
    }else{
      if(this.state.hayTiempo){
        Swal.fire({
          title: 'No puede reenviar el codigo hasta que culmine el tiempo de espera.',
          icon: 'info',
        });
      }else{

        document.getElementById('mensajeCodigo').innerHTML = '';

        enviarCodigo(this.state.telefono).then(response =>{
          if (response) {

            Swal.close();

            document.getElementById('mensajeCodigo').innerHTML = '<span style="color: green; margin-left: 15px;">Codigo enviado</span><span id="tiempo"></span>';
            this.cuentaRegresiva();
          }else{
            document.getElementById('mensajeCodigo').innerHTML = '';
          }
        })
      }
    }
  }

  cuentaRegresiva = async () =>{
    var timer = 300, minutes, seconds;
      var interVal = setInterval(() =>{
          minutes = parseInt(timer / 60, 10);
          seconds = parseInt(timer % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          document.getElementById('tiempo').innerHTML = minutes+' : '+seconds; //jQuery('#tiempo').html();

          this.setState({
            hayTiempo: true,
          });

          if (--timer < 0) {
              this.setState({
                hayTiempo: false,
              });
              clearInterval(interVal);
              document.getElementById('mensajeCodigo').innerHTML = '';
          }
      },1000);
  };

  validarCodigo = async () =>{

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    if (this.state.registroEmail === '' || this.state.registroPassword === '' || this.state.confirmPassword === '' || this.state.registroNombre === '' || this.state.telefono === '') {
      Swal.fire({
        title: 'Por favor complete todos los campos para continuar.',
        icon: 'info',
      });
    }else if(this.state.registroPassword != this.state.confirmPassword) {
      Swal.fire({
        title: 'La contraseña no coincide.',
        icon: 'info',
      });
    }else{
      smsValidarCodigo(this.state.telefono, this.state.codigo).then(response =>{
        if (response) {
          this.procesarUsuario();
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'El código de verificación no es valido.',
          });
        }
      })
    }
  }

  procesarUsuario = async () =>{

    let datos = {
      email: this.state.registroEmail,
      password: this.state.registroPassword,
      nombre: this.state.registroNombre,
      telefono: this.state.telefono,
      rol: 'cliente',
    }

    resgistrarUsuario(datos).then(response =>{
      if (response != false) {
        this.terminarAuth(response);

      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Lo sentimos pero no pudimos registrar sus datos.',
        });
      }
    })
  }

  accionReenvio = async () =>{

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    if (this.state.hayTiempo) {
      Swal.fire({
        title: 'No puede reenviar el codigo hasta que culmine el tiempo de espera.',
        icon: 'info',
      })
    }else{
      reenviarCodigo(this.state.telefono).then(response =>{
        if (response) {

          Swal.close();

          document.getElementById('mensajeCodigo').innerHTML = '<span style="color: green; margin-left: 15px;">Codigo enviado</span><span id="tiempo"></span>';
          this.cuentaRegresiva();
        }else{
          document.getElementById('mensajeCodigo').innerHTML = '';
        }
      })
    }
  }

  enviarCorreoRecuperacion = async () =>{
    if (this.state.emailRecuperar === '') {
      Swal.fire({
        title: 'Complete todos los campos para continuar.',
        icon: 'info',
      })
    }else{
      recuperarPassword(this.state.emailRecuperar).then(response =>{
        if (response) {
          Swal.fire({
            title: 'Hemos enviado un mensaje al correo: '+this.state.emailRecuperar+'.',
            icon: 'success',
          });

          this.setState({
            emailRecuperar: '',
            recuperarEmail: '',
            authForm: 'loginCarrito',
          })
        }else{
          Swal.fire({
            title: 'Lo sentimos hubo error al autenticar sus datos.',
            icon: 'error',
          })
        }
      })
    }
  }

  procesarLogin = async () =>{

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    if (this.state.loginEmail === '' || this.state.loginPassword === '') {
      Swal.fire({
        title: 'Complete todos los campos para continuar.',
        icon: 'info',
      })
    }else{
      iniciarSesion(this.state.loginEmail, this.state.loginPassword).then(response =>{
        if (response != false) {
          this.terminarAuth(response);
        }else{
          Swal.fire({
            title: 'Lo sentimos pero hubo un error al tratar de iniciar sesión, por favor verifique que sus datos sean correctos o que ya este registrado.',
            icon: 'info',
          })
        }
      })
    }
  }

  terminarAuth = async (datos) =>{

    Swal.close();

    await localStorage.setItem('usuarioLegalizarlo', JSON.stringify(datos));

    if (datos.datosActualizados) {

        this.setState({
          pantalla: 'pago',
          uid: datos.uid,
          nombre: datos.nombre,
          email: datos.email,
          telefono: datos.telefono,
        });

        this.validarPlan(this.state.infoProducto, this.state.precioProducto);

        window.Conekta.setPublicKey('key_MVE55XE2ofJvbvG9vZhmJ7Y');
    }else{

      await localStorage.setItem('alertActualizar', 'alert');

      window.location.href = '/cliente/home';
    }
  }

  elegirTipoPago = async (tipoDePago) =>{
    if (tipoDePago == 'tarjeta') {
      document.getElementById('tarjeta').classList.add('formaDePagoActive');
      document.getElementById('tarjetaCirculo').classList.add('circuloActive');
      document.getElementById('oxxo').classList.remove('formaDePagoActive');
      document.getElementById('oxxoCirculo').classList.remove('circuloActive');
      document.getElementById('spei').classList.remove('formaDePagoActive');
      document.getElementById('speiCirculo').classList.remove('circuloActive');

      this.setState({
        tarjeta: 'tarjeta',
        oxxo: '',
        spei: '',
      });
    }else if (tipoDePago == 'oxxo') {
      document.getElementById('tarjeta').classList.remove('formaDePagoActive');
      document.getElementById('tarjetaCirculo').classList.remove('circuloActive');
      document.getElementById('oxxo').classList.add('formaDePagoActive');
      document.getElementById('oxxoCirculo').classList.add('circuloActive');
      document.getElementById('spei').classList.remove('formaDePagoActive');
      document.getElementById('speiCirculo').classList.remove('circuloActive');

      this.setState({
        tarjeta: '',
        oxxo: 'oxxo',
        spei: '',
      });
    }else if (tipoDePago == 'spei') {
      document.getElementById('tarjeta').classList.remove('formaDePagoActive');
      document.getElementById('tarjetaCirculo').classList.remove('circuloActive');
      document.getElementById('oxxo').classList.remove('formaDePagoActive');
      document.getElementById('oxxoCirculo').classList.remove('circuloActive');
      document.getElementById('spei').classList.add('formaDePagoActive');
      document.getElementById('speiCirculo').classList.add('circuloActive');

      this.setState({
        tarjeta: '',
        oxxo: '',
        spei: 'spei',
      });
    }
  }

  crearToken = async () =>{

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    if (this.state.conektaTokenId != '') {

      Swal.close();

      this.setState({
        pantalla: 'resumen',
      })
    }else{
      let datos = {
          card: {
            number: this.state.numeroTarjeta,
            name: this.state.nombreTarjeta,
            exp_year: this.state.anoTarjeta,
            exp_month: this.state.mesTarjeta,
            cvc: this.state.codigoTarjeta,
          },
      };

      if (datos.card.number === '' || datos.card.name === '' || datos.card.exp_year === '' || datos.card.exp_month === '' || datos.card.cvc === '' ) {
        Swal.fire({
          title: 'Complete todos los campos para continuar.',
          icon: 'info',
        });
      }else if (isNaN(datos.card.number) || isNaN(datos.card.exp_year) || isNaN(datos.card.exp_month) || isNaN(datos.card.cvc)) {
        Swal.fire({
          title: 'El numero de tarjeta, el cvc, mes y año de vencimiento deben ser numericos.',
          icon: 'info',
        });
      }else{
        window.Conekta.Token.create(datos, this.conektaSuccessResponseHandler, this.conektaErrorResponseHandler);
      }
    }
  }

  conektaSuccessResponseHandler = async (token) =>{
      
      Swal.close();

      console.log(token.id);
      this.setState({
        conektaTokenId: token.id,
      });
      //this.procesarOrden();
      this.setState({
        pantalla: 'resumen',
        pagarCon: 'Pago con tarjeta de credito o debito',
      });
  };

  conektaErrorResponseHandler = async (response) =>{
      console.log(response);
      Swal.fire({
        title: response.message_to_purchaser,
        icon: 'error',
      });
  };

  procesarPago = async () =>{

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    let pagarCon = '';

    if (this.state.pagarCon == 'Pago con tarjeta de credito o debito') {
      pagarCon = 'tarjeta';
    }else if (this.state.pagarCon == 'Pago con OXXO') {
      pagarCon = 'OXXO';
    }else if (this.state.pagarCon == 'Pago con SPEI') {
      pagarCon = 'SPEI';
    }

    comprarProducto(this.state.conektaTokenId, this.state.producto, this.state.precioProducto, pagarCon, this.state.dataProducto.tipoDeCompra, this.state.codigoDescuento, this.state.elDescuentoPlan).then(response =>{
      if (response != false) { console.log(response);

        Swal.close();

        this.setState({
          mensajePagoFinaliza: '¡Pago exitoso! Tu ID de Compra es: '+response.idOrden,
          idOrden: response.idOrden,
          pantalla: 'finalizado',
          referenciaOXXO: response.referenciaOXXO,
          dataCompra: response.dataCompra,
          idCompra: response.idCompra,
        });
        if(this.state.codigoDescuento !== '')
        {
          let cupon = this.state.cupon;

          let total = (cupon[0].data.numerousos - 1);
          if(total <= 0)
          {
            cupon[0].data.numerousos = 0;
            cupon[0].data.estatuscupon = 'inactivo';
          }
          else
          {
            cupon[0].data.numerousos = total;
          }
          editarCupon(cupon[0].id, cupon[0].data);
        }
        if (pagarCon == 'OXXO' || pagarCon == 'SPEI') {

          this.setState({
            pagado: false,
          });

          window.setTimeout(this.imprimirFicha, 2000);
        }
      }else{
        Swal.fire({
          title: 'Lo sentimos pero hubo un error al tratar de procesar su pago.',
          icon: 'error',
        });
      }
    })
  }

  imprimirFicha = async () =>{
    window.html2canvas(document.querySelector("#laFactura")).then(canvas => {
        this.nuevoCanvas(canvas);
    });
  }

  nuevoCanvas = async (canvas) =>{
    var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    var id = "";
      for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

    //var canvas = document.querySelector('canvas');
    var dataURL = canvas.toDataURL();
    this.simulateDownloadImageClick(dataURL, id+'.png');
  }

  simulateDownloadImageClick = async (uri, filename) =>{
    var link = document.createElement('a');
    if (typeof link.download !== 'string') {
        window.open(uri);
    } else {
        link.href = uri;
        link.download = filename;
        this.accountForFirefox(link);
    }
  }

  clickLink = async (link)  =>{
    link.click();
  }

  accountForFirefox = async (link)  =>{
    document.body.appendChild(link);
    this.clickLink(link);
    document.body.removeChild(link);
  }

  irADondeDice = async (dice) =>{
    window.location.href = dice;
  }

  validCupon = async()=>{
      //console.log("Este es el código ",document.getElementById("codigoDescuento").value);
      var cup = await searchCupon(document.getElementById("codigoDescuento").value); 
      //console.log(cup);
      this.setState({
        cupon: cup,
      });

      if(cup.length != 0){

        if(cup[0].data.estatuscupon == "activo")
        {
          //console.log(cup[0].data.fecha.toDate().toString());
          let now = new Date();
          let fechacupon = new Date(cup[0].data.fecha);
          //console.log(fechacupon);
          const diffTime = Math.abs(now - fechacupon);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          //console.log(diffDays);
          //console.log(cup[0].data.diasduracion);
          if((diffDays > cup[0].data.diasduracion) && cup[0].data.diasduracion > 0 )
          {
            Swal.fire({
              title: 'Por favor prueba con otro código, el código ingresado no funciona',
              icon: 'info',
            });
            cup[0].data.estatuscupon = 'inactivo';
            await editarCupon(cup[0].id, cup[0].data);
          }
          else
          {
            let sufijo;
            let montos;
            if(cup[0].data.tipodescuento == 'porcentaje')
            {
                sufijo = "%";
                montos = this.state.tempPrecio - ((this.state.tempPrecio * cup[0].data.monto) / 100)  ;
            }
            else
            {
                sufijo = "$";
                montos = this.state.tempPrecio - cup[0].data.monto ;
            }
            this.setState({
              montodescuento : cup[0].data.monto+sufijo,
            });
            this.setState({
              precioProducto : montos.toFixed(2),
            });
            this.setState({ codigoDescuento : cup[0].id,});
          }

        }
        else
        {
          Swal.fire({
            title: 'Por favor prueba con otro código, el código ingresado no funciona',
            icon: 'info',
          });
        }
      }else{
        Swal.fire({
          title: 'Por favor prueba con otro código, el código ingresado no funciona',
          icon: 'info',
        });
      }
      /*this.state.precioProducto
      this.state.montodescuento*/
  }

  verFactura = async () =>{

    window.open('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/firebase/factura.php?'+
          'numeroFactura='+this.state.idOrden+
          '&fechaMostrar='+this.state.dataCompra.fechaMostrar+
          '&nombre='+this.state.usuario.nombre+
          '&apellido='+this.state.usuario.apellido+
          '&email='+this.state.usuario.email+
          '&direccion='+this.state.usuario.direccion+
          '&pais='+this.state.usuario.pais+
          '&codigoPostal='+this.state.usuario.codigoPostal+
          '&telefono='+this.state.usuario.telefono+
          '&identificacion='+this.state.usuario.identificacion+
          '&nombreProducto='+this.state.producto.data.nombre+
          '&costoProducto='+this.state.dataCompra.costoSinIva+
          '&total='+this.state.dataCompra.costo+
          '&tipoPago='+this.state.dataCompra.tipoPago,
          '_blank'
    );
  }

  gestionarCompra = async () =>{

    if (this.state.pagado == false) {

      Swal.fire({
          title: 'No puede gestionar este producto hasta ser cancelado.',
          icon: 'info',
      });
    }else{

      if (this.state.infoProducto.tipoDeCompra == 'documentos') {

        await localStorage.setItem('idCompra', this.state.idCompra);
        window.location.href = '/cliente/editarDocumento';
      }else if (this.state.infoProducto.tipoDeCompra == 'servicios' || this.state.infoProducto.tipoDeCompra == 'serviciosPersonalizados') {

        await localStorage.setItem('idAgenda', JSON.stringify({
          idAgenda: this.state.idCompra,
          tipo: this.state.infoProducto.tipoDeCompra,
        }));
        window.location.href = '/cliente/agendar';
      }
    }
  }

  render(){
    if (this.state.pantalla == 'pago') {
      return(
        <section class="seccion1Carrito">
          <style id="estilosMenu">

          </style>
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <h1 class="tituloCarrito">Compra de {this.state.tituloCarrito}</h1><br />
                <p class="infoEstado"><span>Contacto</span> > <span class="estadoActivo">Pago</span> > <span>Resumen</span></p>
              </div>
            </div><br />
            <div class="row">
              <div class="col-sm-8">
                <h5 class="tituloIndicacion">2. Elige tu forma pago</h5>
                <div onClick={() => this.elegirTipoPago('tarjeta')} class="formaDePago" id="tarjeta">
                  <div class="datosTipoDePago">
                    <div class="tipoOpcionYNombre">
                      <div class="circulo" id="tarjetaCirculo">
                      </div>
                      <span class="nombreOpcion">Tarjeta de Crédito o Débito</span>
                    </div>
                    <img src={imgTajetasDeCredito} class="imgTajetasDeCredito" />
                  </div>
                  <FormasDePago
                    tipoDeForm={this.state.tarjeta}
                    nombreTarjeta={() => this.setState({nombreTarjeta: document.getElementById('nombreTarjeta').value})}
                    numeroTarjeta={() => this.setState({numeroTarjeta: document.getElementById('numeroTarjeta').value})}
                    mesTarjeta={() => this.setState({mesTarjeta: document.getElementById('mesTarjeta').value})}
                    anoTarjeta={() => this.setState({anoTarjeta: document.getElementById('anoTarjeta').value})}
                    codigoTarjeta={() => this.setState({codigoTarjeta: document.getElementById('codigoTarjeta').value})}
                    crearToken={this.crearToken}
                    procesarOXXO={() => this.setState({pantalla: 'resumen', pagarCon: 'Pago con OXXO',})}
                    procesarSPEI={() => this.setState({pantalla: 'resumen', pagarCon: 'Pago con SPEI',})}
                  />
                </div>
                <div onClick={() => this.elegirTipoPago('oxxo')} class="formaDePago" id="oxxo">
                  <div class="datosTipoDePago">
                    <div class="tipoOpcionYNombre">
                      <div class="circulo" id="oxxoCirculo">
                      </div>
                      <span class="nombreOpcion">Pagar en Efectivo vía Oxxo</span>
                    </div>
                    <img src={imgPagoOxxo} class="imgTajetasDeCredito" />
                  </div>
                  <FormasDePago
                    tipoDeForm={this.state.oxxo}
                    nombreTarjeta={() => this.setState({nombreTarjeta: document.getElementById('nombreTarjeta').value})}
                    numeroTarjeta={() => this.setState({numeroTarjeta: document.getElementById('numeroTarjeta').value})}
                    mesTarjeta={() => this.setState({mesTarjeta: document.getElementById('mesTarjeta').value})}
                    anoTarjeta={() => this.setState({anoTarjeta: document.getElementById('anoTarjeta').value})}
                    codigoTarjeta={() => this.setState({codigoTarjeta: document.getElementById('codigoTarjeta').value})}
                    crearToken={this.crearToken}
                    procesarOXXO={() => this.setState({pantalla: 'resumen', pagarCon: 'Pago con OXXO',})}
                    procesarSPEI={() => this.setState({pantalla: 'resumen', pagarCon: 'Pago con SPEI',})}
                  />
                </div>
                <div onClick={() => this.elegirTipoPago('spei')} class="formaDePago" id="spei">
                  <div class="datosTipoDePago">
                    <div class="tipoOpcionYNombre">
                      <div class="circulo" id="speiCirculo">
                      </div>
                      <span class="nombreOpcion">Pago con Transferencia vía SPEI</span>
                    </div>
                    <img src={imgPagoSpei} class="imgPagoSpei" />
                  </div>
                  <FormasDePago
                    tipoDeForm={this.state.spei}
                    nombreTarjeta={() => this.setState({nombreTarjeta: document.getElementById('nombreTarjeta').value})}
                    numeroTarjeta={() => this.setState({numeroTarjeta: document.getElementById('numeroTarjeta').value})}
                    mesTarjeta={() => this.setState({mesTarjeta: document.getElementById('mesTarjeta').value})}
                    anoTarjeta={() => this.setState({anoTarjeta: document.getElementById('anoTarjeta').value})}
                    codigoTarjeta={() => this.setState({codigoTarjeta: document.getElementById('codigoTarjeta').value})}
                    crearToken={this.crearToken}
                    procesarOXXO={() => this.setState({pantalla: 'resumen', pagarCon: 'Pago con OXXO',})}
                    procesarSPEI={() => this.setState({pantalla: 'resumen', pagarCon: 'Pago con SPEI',})}
                  />
                </div>
              </div>
              <div class="col-sm-4 flex">
                <div class="hrVertical"></div>
                <div class="formPedido">
                  <h5 class="tituloIndicacion">Tu Pedido:</h5>
                  <br />
                  <span class="nombreDeProductoCarrito">{this.state.nombreDeProductoCarrito}</span>
                  <br />
                  <div class="hrHorizontal"></div>
                  <br />
                  <br />
                  <div class="input-group2">
                    <input id="codigoDescuento" placeholder="Codigo de descuento" type="text" onChange={() => this.setState({codigoDescuento: document.getElementById('codigoDescuento').value})} class="inputLogin inputConBoton" />
                    <button class="botonEnviar botonDescuento" onClick={this.validCupon}>Agregar</button>
                  </div>
                  <br />
                  <div class="divPrecios">
                    <div>
                      <DescuentoPlan
                        pantalla={this.state.pantallaTituloPlan}
                        descuento={''}
                        nombrePlan={this.state.nombrePlan}
                      />
                      <p class="infoPrecio">Desc. Por cupon</p>
                      <p class="infoPrecio">IVA</p>
                      <p class="infoPrecio bold">Total</p>
                    </div>
                    <div>
                      <DescuentoPlan
                        pantalla={this.state.pantallaDescuentoPlan}
                        descuento={this.state.elDescuentoPlan}
                        nombrePlan={''}
                      />
                      <p class="precios">{this.state.montodescuento}</p>
                      <p class="precios">16%</p>
                      <p class="precios bold">${this.state.precioProducto} MXN</p>
                    </div>
                  </div>
                  <br />
                  <span class="textPreciosIVA">*Todos nuestros precios incluyen IVA :)</span>
                  <br />
                  <div class="divPagoSeguro">
                    <div class="textImgPagoSeguro">
                      <img src={imgSsl} class="imgPagoSeguro" />
                      <span class="textPagoSeguro">ENCRIPTACIÓN SSL PROTEGIDA</span>
                    </div>
                    <div class="textImgPagoSeguro">
                      <img src={imgPagoSeguro} class="imgPagoSeguro" />
                      <span class="textPagoSeguro">PAGO SEGURO GARANTIZADO</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }else if (this.state.pantalla == 'resumen') {
      return(
        <section class="seccion1Carrito">
          <style id="estilosMenu">

          </style>
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <h1 class="tituloCarrito">Compra de {this.state.tituloCarrito}</h1><br />
                <p class="infoEstado"><span>Contacto</span> > <span>Pago</span> > <span class="estadoActivo">Resumen</span></p>
              </div>
            </div><br />
            <div class="row">
              <div class="col-sm">
                <div class="formaDePago paddingTerminarPago">
                  <h5 class="tituloIndicacion">3. Verifica y Paga tu Pedido</h5>
                  <h6 class="tituloIndicacion elCienPorCiento">{this.state.nombreDeProductoCarrito}</h6>
                  <div class="divPrecios elCienPorCiento">
                    <div>
                      <DescuentoPlan
                        pantalla={this.state.pantallaTituloPlan}
                        descuento={''}
                        nombrePlan={this.state.nombrePlan}
                      />
                      <p class="infoPrecio">Desc. Por cupon</p>
                      <p class="infoPrecio">IVA</p>
                      <p class="infoPrecio bold">Total</p>
                    </div>
                    <div>
                      <DescuentoPlan
                        pantalla={this.state.pantallaDescuentoPlan}
                        descuento={this.state.elDescuentoPlan}
                        nombrePlan={''}
                      />
                      <p class="precios">{this.state.montodescuento}</p>
                      <p class="precios">16%</p>
                      <p class="precios bold">${this.state.precioProducto} MXN</p>
                    </div>
                  </div>
                  <hr/>
                  <h6 class="tituloIndicacion elCienPorCiento">Información de contacto</h6>
                  <p class="infoPrecio">{this.state.nombre}</p>
                  <p class="infoPrecio">Correo: {this.state.email}</p>
                  <p class="infoPrecio bold">Telefono: {this.state.telefono}</p>
                  <hr/>
                  <h6 class="tituloIndicacion elCienPorCiento">Método de Pago</h6>
                  <p class="infoPrecio">{this.state.pagarCon}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <div class="divAccionPago">
                  <span onClick={() => this.setState({pantalla: 'pago',})} class="atrasPago">Regresar a Formas De Pago</span>
                  <button onClick={this.procesarPago} class="terminarPago">Pagar</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }else if (this.state.pantalla == 'finalizado') {
      return(
        <section class="seccion1Carrito">
          <style id="estilosMenu">

          </style>
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <h5 class="mensajePagoFinaliza">{this.state.mensajePagoFinaliza}</h5>
                <h1 class="tituloCarrito">¡Enhorabuena {this.state.nombre}!</h1>
                <p class="parrafoFinal">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at ligula non turpis imperdiet dignissim ac ac sem. Sed id odio interdum, rhoncus arcu vel, laoreet nulla. Mauris quis pretium velit, sed interdum felis. Ut consequat ut nulla eu venenatis. Maecenas accumsan sapien ac euismod hendrerit. Donec nec felis urna. Sed varius elit a velit dapibus facilisis. Suspendisse leo sem, pulvinar at massa efficitur, maximus congue velit. Quisque efficitur turpis nec ante blandit viverra. Etiam vel gravida mauris. Duis finibus volutpat nisi ultricies pulvinar. Sed at volutpat quam.</p>
                <br />
                <SeccionReferencia
                  pagarCon={this.state.pagarCon} 
                  referencia={this.state.referenciaOXXO} 
                  precioProducto={this.state.precioProducto}
                />
                <br />
              </div>
            </div>
            <div class="row">
              <div class="col" id="facturaOXXOCanvas">
              </div>
            </div>
            <div class="hrFinal"></div>
            <br />
            <div class="row">
              <div class="col-sm">
                <h1 class="tituloCarrito">¿Que Sigue?</h1>
                <br />
                <div class="opcionesFinales">
                  <div onClick={this.gestionarCompra} class="elegirFinal formaDePagoActive">
                    <div class="tipoOpcionYNombre">
                      <div class="circulo circuloActive">
                      </div>
                      <span class="nombreOpcion">{this.state.tipoPasoSiguiente}</span>
                    </div>
                  </div>
                  <div onClick={() => this.irADondeDice('/cliente/home')} class="elegirFinal">
                    <div class="tipoOpcionYNombre">
                      <div class="circulo">
                      </div>
                      <span class="nombreOpcion">Ingresa a tu panel de usuario</span>
                    </div>
                  </div>
                  {/*<div onClick={this.verFactura} class="elegirFinal">
                    <div class="tipoOpcionYNombre">
                      <div class="circulo">
                      </div>
                      <span class="nombreOpcion">Solicita tu factura</span>
                    </div>
                  </div>*/}
                </div>
                <br />
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <p class="parrafoNecesitas">¿Necesitas ayuda? <a class="negro" href="https://wa.me/message/E4NL5UXQR3ASA1" target="_blank">Contactanos</a></p>
              </div>
            </div>
          </div>
        </section>
      );
    }else{
      return(
        <section class="seccion1Carrito">
          <style id="estilosMenu">

          </style>
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <h1 class="tituloCarrito">Compra de {this.state.tituloCarrito}</h1><br />
                <p class="infoEstado"><span class="estadoActivo">Contacto</span> > <span>Pago</span> > <span>Resumen</span></p>
              </div>
            </div><br />
            <div class="row">
              <div class="col-sm-8">
                <h5 class="tituloIndicacion">1. Información de Contacto</h5>
                <Login
                  pantalla={this.state.authForm}
                  imgLogin={''}
                  cerrarModal={''}
                  onChangeLoginEmail={() => this.setState({loginEmail: document.getElementById('loginEmail').value,})}
                  onChangeLoginPassword={() => this.setState({loginPassword: document.getElementById('loginPassword').value,})}
                  cambiarARegistro={() => this.setState({authForm: 'registroCarrito',})}
                  cambiarALogin={() => this.setState({authForm: 'loginCarrito',})}
                  onChangeRegistroEmail={() => this.setState({registroEmail: document.getElementById('registroEmail').value,})}
                  onChangeRegistroPassword={() => this.setState({registroPassword: document.getElementById('registroPassword').value,})}
                  onChangeConfirmPassword={() => this.setState({confirmPassword: document.getElementById('confirmPassword').value,})}
                  onChangeRegistroNombre={() => this.setState({registroNombre: document.getElementById('registroNombre').value,})}
                  onChangeTelefono={() => this.setState({telefono: document.getElementById('telefono').value,})}
                  onChangeCodigo={() => this.setState({codigo: document.getElementById('codigo').value,})}
                  codigoSms={this.codigoSms}
                  validarCodigo={this.validarCodigo}
                  accionReenvio={this.accionReenvio}
                  procesarLogin={this.procesarLogin}
                  mostrarRecuperacion={() => this.setState({authForm: 'recuperarPasswordCarrito',})}
                  onChangeRecuperarEmail={() => this.setState({recuperarEmail: document.getElementById('recuperarEmail').value,})}
                  procesarRecuperar={this.enviarCorreoRecuperacion}
                />
              </div>
              <div class="col-sm-4 flex">
                <div class="hrVertical"></div>
                <div class="formPedido">
                  <h5 class="tituloIndicacion">Tu Pedido:</h5>
                  <br />
                  <span class="nombreDeProductoCarrito">{this.state.nombreDeProductoCarrito}</span>
                  <br />
                  <div class="hrHorizontal"></div>
                  <br />
                  <br />
                  <div class="input-group2">
                    <input id="codigoDescuento" placeholder="Codigo de descuento" type="text" onChange={() => this.setState({codigoDescuento: document.getElementById('codigoDescuento').value})} class="inputLogin inputConBoton" />
                    <button class="botonEnviar botonDescuento" onClick={this.validCupon}>Agregar</button>
                  </div>
                  <br />
                  <div class="divPrecios">
                    <div>
                      <DescuentoPlan
                        pantalla={this.state.pantallaTituloPlan}
                        descuento={''}
                        nombrePlan={this.state.nombrePlan}
                      />
                      <p class="infoPrecio">Desc. Por cupon</p>
                      <p class="infoPrecio">IVA</p>
                      <p class="infoPrecio bold">Total</p>
                    </div>
                    <div>
                      <DescuentoPlan
                        pantalla={this.state.pantallaDescuentoPlan}
                        descuento={this.state.elDescuentoPlan}
                        nombrePlan={''}
                      />
                      <p class="precios">{this.state.montodescuento}</p>
                      <p class="precios">16%</p>
                      <p class="precios bold">${this.state.precioProducto} MXN</p>
                    </div>
                  </div>
                  <br />
                  <span class="textPreciosIVA">*Todos nuestros precios incluyen IVA :)</span>
                  <br />
                  <div class="divPagoSeguro">
                    <div class="textImgPagoSeguro">
                      <img src={imgSsl} class="imgPagoSeguro" />
                      <span class="textPagoSeguro">ENCRIPTACIÓN SSL PROTEGIDA</span>
                    </div>
                    <div class="textImgPagoSeguro">
                      <img src={imgPagoSeguro} class="imgPagoSeguro" />
                      <span class="textPagoSeguro">PAGO SEGURO GARANTIZADO</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

export default CarritoScreen;
