import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function subirImagenDoc(id, imagen){

	const storageRef = referencia(almacenamiento, 'assets/documentos/'+id+'/'+id);

	await subirArchivo(storageRef, imagen);

	let url = await obtenerURL(storageRef);

	return url;  
}

export async function guardarDocumento(id, data){
	await set(documento(db, "documentos", id), data);

	return true;
}

export async function buscarDocumentos(rol){

	let documentos = [];
	let parametro = '';

	if(rol == 'admin'){
		parametro = donde("nombre", "!=", '');
	}else if(rol == 'cliente'){
		parametro = donde("activo", "==", true);
	}

	const q = buscar(coleccion(db, "documentos"), parametro);

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		documentos.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return documentos;
}

export async function editarDocumento(id, estado){

	let activo = false;
	
	if(estado == false){
		activo = true;
	}

	const washingtonRef = documento(db, "documentos", id);

	await actualizarDoc(washingtonRef, {
		activo: activo,
	});

	return true;
}

export async function editarSolicitado(id, estado){

	let activo = false;
	
	if(estado == false){
		activo = true;
	}

	const washingtonRef = documento(db, "documentos", id);

	await actualizarDoc(washingtonRef, {
		solicitado: activo,
	});

	return true;
}