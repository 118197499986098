import React, {useState, useEffect} from 'react';
import './avisoPrivacidad-styles.css';
import Footer from '../../components/footer/footer';
import {
	consultarAvisoPrivacidad,
} from '../../backend/preguntasYRespuestas/preguntasYRespuestas.js';

function AvisoPrivacidadScreen(){

	const [busquedaAvisoPrivacidad, CambioBusquedaAvisoPrivacidad] = useState(false);
	const [avisoPrivacidad, CambioAvisoPrivacidad] = useState('');

	useEffect(() => {

		if (busquedaAvisoPrivacidad == false) {

			consultarAvisoPrivacidad().then(response =>{

				if (response != false) {

					CambioAvisoPrivacidad(response.data.texto);
					document.getElementById('texto').innerHTML = response.data.texto;
				}
			});

			CambioBusquedaAvisoPrivacidad(true);
		}
	});

	return(
		<>
			<section class="seccion4Docs">
				<div class="container">
					<div class="row">
						<div class="col-sm" id="texto" style={{paddingTop: '10%', paddingBottom: '10%',}}>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default AvisoPrivacidadScreen;