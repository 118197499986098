import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { editarCupon, guardarCupon} from '../../../backend/cupones/cupones.js';
const FormularioDeCupon = (props) => {
  const [cupon, setCupon] = useState({
    codigocupon: props.cupon ? props.cupon.codigocupon : '',
    diasduracion: props.cupon ? props.cupon.diasduracion : '',
    numerousos: props.cupon ? props.cupon.numerousos : '',
    monto: props.cupon ? props.cupon.monto : '',
    tipodescuento:props.cupon? props.cupon.tipodescuento :'',
    estatuscupon:props.cupon? props.cupon.estatuscupon :'',
    fecha:props.cupon? props.cupon.fecha :'',
    id:props.cupon ? props.cupon.id : '',
  });
  const TypeAction = props.typeaction;
  const [errorMsg, setErrorMsg] = useState('');
  const { codigocupon, diasduracion, numerousos, monto, tipodescuento, estatuscupon} = cupon;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const valores = [codigocupon, diasduracion, monto, numerousos, tipodescuento, estatuscupon];
    let errorMsg = '';

    const todosLosCamposLlenos = valores.every((campo) => {
      const valor = `${campo}`.trim();

      return valor !== '' ;
    });

    if (todosLosCamposLlenos) {

      if(numerousos<=0)
      {
        errorMsg = "El número de usos debe ser mayor a cero";
      }
      else{
        if(tipodescuento == 'porcentaje' && ((monto>100) || (monto <1) ))
        {
          errorMsg = "El porcentaje debe estar entre el 1 y 100%";
        }
        else{
          const new_date = new Date();

          const cupon = {
            id: uuidv4(),
            codigocupon,
            diasduracion,
            numerousos,
            monto,
            tipodescuento,
            estatuscupon,
            fecha: new_date.getTime(),
          };
          props.handleOnSubmit(cupon);
          if(TypeAction === "add")
            guardarCupon(cupon.id, cupon).then(reponse =>{   console.log("todo bien") ; document.getElementById('listadecupones').click();    });
          else
          {
            //let dateUpdate = new Date(props.cupon.fecha.seconds * 1000);
            let ngh = new Date(props.cupon.fecha);
            //console.log(ngh.getTime())
            cupon.fecha = ngh.getTime();
            cupon.id = props.idedit ;
            editarCupon(cupon.id,cupon).then(response =>{console.log("editado correctamente");  document.getElementById('listadecupones').click();});
          }
        }
      }
    } else {
      errorMsg = 'Por favor, rellene todos los campos.';
    }
    setErrorMsg(errorMsg);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    /*switch (nombre) {
      case 'numerousos':
        if (valor === '' || parseInt(valor) === +valor) {
          setCupon((prevState) => ({
            ...prevState,
            [nombre]: valor
          }));
        }
        break;
      case 'monto':
        if (valor === '' || valor.match(/^\d{1,}(\.\d{0,2})?$/)) {
          setCupon((prevState) => ({
            ...prevState,
            [nombre]: valor
          }));
        }
        break;
      default:*/
        setCupon((prevState) => ({
          ...prevState,
          [name]: value
        }));
    /*}*/
  };

  return (
    <div className="main-form container pl-5 pt-5">
      <h1>Agregar Cupón</h1>
      <Row>
        <div className='col-12 d-flex justify-content-end'>
          <NavLink to="/admin/cupones/listado" className="link" activeClassName="active" id="listadecupones" exact>
              Lista de Cupones
          </NavLink>
        </div>
      </Row>
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}
      <Form onSubmit={handleOnSubmit}>
        <Row>
          <Form.Group as={Col} controlId="nombre">
            <Form.Label>Código de cupón</Form.Label>
            <Form.Control
              className="input-control"
              type="text"
              name="codigocupon"
              value={codigocupon}
              placeholder="Ingrese el código"
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="diasduracion">
            <Form.Label>Días de duración</Form.Label>
            <Form.Control
              className="input-control"
              type="number"
              name="diasduracion"
              value={diasduracion}
              placeholder="Días de duración"
              onChange={handleInputChange}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="numerousos">
            <Form.Label>Número de Usos</Form.Label>
            <Form.Control
              className="input-control"
              type="number"
              name="numerousos"
              value={numerousos}
              placeholder="Número de usos"
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" as={Col} controlId="estatuscupon">
            <Form.Label>Estatus del cupón</Form.Label>
            <Form.Check type="radio" onChange={handleInputChange}  name="estatuscupon" id="estatuscupon1" checked={estatuscupon === "activo"} value="activo" label="Activo" />
            <Form.Check type="radio" onChange={handleInputChange}  name="estatuscupon" id="estatuscupon2" checked={estatuscupon === "inactivo"} value="inactivo" label="Inactivo" />
          </Form.Group>
        </Row>
       <Row>
          <Form.Group className="mb-3" as={Col} controlId="tipodescuento">
            <Form.Label>Tipo descuento</Form.Label>
            <Form.Check type="radio" onChange={handleInputChange}  name="tipodescuento" id="tipodescuento1" checked={tipodescuento === "porcentaje"}  value="porcentaje" label="Porcentaje" />
            <Form.Check type="radio" onChange={handleInputChange}  name="tipodescuento" id="tipodescuento2" checked={tipodescuento === "moneda"}  value="moneda" label="Moneda" />
          </Form.Group>
          <Form.Group as={Col} controlId="monto">
            <Form.Label>Monto del descuento</Form.Label>
            <Form.Control
              className="input-control"
              type="number"
              name="monto"
              value={monto}
              placeholder="Monto del descuento"
              onChange={handleInputChange}
            />
          </Form.Group>
        </Row>

          <Button variant="primary" type="submit" className="submit-btn">
            Enviar
          </Button>

      </Form>
    </div>
  );
};

export default FormularioDeCupon;
