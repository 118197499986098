import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';
import axios from "axios";
import {meses, dias} from '../../assets/datosCalendario';
import {misComprar} from '../cliente/cliente';

export async function buscarProducto(dataProducto){
	const docRef = documento(db, dataProducto.tipoDeCompra, dataProducto.idProducto);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}

export async function searchCupon(idsearch){
	//console.log("cargo la funcion")
	let cupones = [];
	let parametro = '';

	/*parametro = donde("codigocupon", isEqualTo: idsearch);
	const q = coleccion(db, "cupones");
	*/
	let q = buscar(coleccion(db, "cupones"), donde("codigocupon", "==", idsearch));
	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		cupones.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return cupones;
}

export async function editarCupon(id, cupon){

	const washingtonRef = documento(db, "cupones", id);

	await actualizarDoc(washingtonRef, cupon);

	return true;
}

export async function comprarProducto(conektaTokenId, producto, precioProducto, pagarCon, tipo, codigoDescuento, descuentoPlan){ console.log('EL DESCUENTO: ', descuentoPlan);
	
	let usuario = await localStorage.getItem('usuarioLegalizarlo');
	if (usuario != null) {
		let dataUser = JSON.parse(usuario);

		let validarPlan = await validarSiHayPlanContratado(dataUser.uid, tipo);

		if (validarPlan == false) {

			let pagar = '';
			let precio = 0;

			if (precioProducto > 0) {
				precio = precioProducto / 2;
			}

			let pagado = true;
			let referenciaOXXO = '';
			let caracteristicas = '';

			if (tipo == 'documentos') {
				caracteristicas = {
					editado: false,
				};
			}else if (tipo == 'servicios' || tipo == 'serviciosPersonalizados') {
				caracteristicas = {
					editado: false,
					fecha: 'No asignada',
				};
			}else if (tipo == 'planes') {

				let date = new Date();
				let dia = date.getDate();
				let mes = meses[date.getMonth()].numeroMes;
				let ano = date.getFullYear();

				if (dia < 10) {
					dia = '0'+date.getDate();
				}

				if (producto.data.tipo == 'mensual') {

					let suma = parseInt(mes) + 1;
					if (suma == 2) {

						if (dia > 28) {
							dia = 28;
						}
					}else if (suma > 12) {

						mes = '01';
						ano = date.getFullYear() + 1;
					}else if (suma < 10) {

						mes = '0'+suma;
					}
				}else if (producto.data.tipo == 'anual') {

					ano = date.getFullYear() + 1;
				}

				let fechaExpiracion = ano+'-'+mes+'-'+dia;

				caracteristicas = {
					activo: true,
					fechaExpiracion: fechaExpiracion,
					dataPlan: producto.data,
				};
			} 

			if (pagarCon == 'tarjeta') {
				pagar = await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/conekta/pagosConTarjeta.php', {
					method: 'POST',
				    headers:{
				        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
				    },
				    body: 'producto='+producto.data.nombre+'&unit_price='+precio+'&amount='+precio+'&nombreUsuario='+dataUser.nombre+'&emailUsuario='+dataUser.email+'&telefono='+dataUser.telefono+'&region=nada&token='+conektaTokenId
				}).then(response =>{
					 	return response.json();
				}).then(json =>{
					console.log(json);
					return json;
				});
			}else if (pagarCon == 'OXXO') {
				pagar = await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/conekta/pagosConOxxo.php', {
					  method: 'POST',
				      headers:{
				        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
				      },
				      body: 'producto='+producto.data.nombre+'&unit_price='+precio+'&amount='+precio+'&nombreUsuario='+dataUser.nombre+'&emailUsuario='+dataUser.email+'&telefono='+dataUser.telefono+'&region=nada'
				}).then(response =>{
					 return response.json();
				}).then(json =>{
					 console.log(json);
					 return json;
				});

				pagado = false;
				referenciaOXXO = pagar.referencia;
			}else if (pagarCon == 'SPEI') {
				pagar = await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/conekta/pagosConSpei.php', {
					  method: 'POST',
				      headers:{
				        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
				      },
				      body: 'producto='+producto.data.nombre+'&unit_price='+precio+'&amount='+precio+'&nombreUsuario='+dataUser.nombre+'&emailUsuario='+dataUser.email+'&telefono='+dataUser.telefono+'&region=nada'
				}).then(response =>{
					 return response.json();
				}).then(json =>{
					 console.log(json);
					 return json;
				});

				pagado = false;
				referenciaOXXO = pagar.referencia;
			}

			if (pagar.id != undefined || descuentoPlan == 100) {

				let idPago = '';

				if (pagar.id != undefined) {
					idPago = pagar.id;
				}

				let date = new Date();
				let dia = date.getDate();
				let mes = meses[date.getMonth()].numeroMes;
				let ano = date.getFullYear();

				if (dia < 10) {
					dia = '0'+date.getDate();
				}

				let data = {
					tipo: tipo,
					tipoPago: pagarCon,
					referenciaOXXO: referenciaOXXO,
					pagado: pagado,
					idProducto: producto.id,
					uid: dataUser.uid,
					idOrden: idPago,
					caracteristicas: caracteristicas,
					costo: precioProducto,
					costoSinIva: producto.data.precio,
					fecha: ano+'-'+mes+'-'+dia,
		    		fechaMostrar: dia+'-'+mes+'-'+ano,
				};

				var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
				var id = "";
					for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

				//await agregarDoc(coleccion(db, "compras"), data);

				await set(documento(db, "compras", id), data);

				return {
					idOrden: pagar.id,
					referenciaOXXO: referenciaOXXO,
					dataCompra: data,
					pagado: pagado,
					idCompra: id,
				};
			}else{
				return false;
			}
		}else{
			return false;
		}
	}else{
		 return false;
	}
}

export async function validarSiHayPlanContratado(uid, tipo){ console.log(uid, tipo);

	let esta = false;

	if (tipo == 'planes') {

		let compras = await misComprar(uid);
		let comprasPlanes = [];

		for (let compra of compras) {

			if (compra.compra.data.tipo == 'planes') {
				comprasPlanes.push(compra.compra);
			}
		}

		for (let comprasPlan of comprasPlanes) {
			if (comprasPlan.data.caracteristicas.activo) {
				esta = comprasPlan;
			}
		}

		return esta;
	}else{

		return esta;
	}
}
