import {db, documento, set, leerDatos, agregarDoc, actualizarDoc, coleccion, obtenerDocs, eliminarDoc} from '../conex.js';

export async function guardarRespuesta(data, editando){
	if (editando == false) {
		 await agregarDoc(coleccion(db, "preguntasYRespuestas"), data);

		 return true;
	}else if (editando == true) {
		const preguntaRef = documento(db, "preguntasYRespuestas", data.idPregunta);

		await actualizarDoc(preguntaRef, {
		  	pregunta: data.pregunta,
			respuesta: data.respuesta,
		});

		return true;
	}
}

export async function consultarPreguntas(){
	let preguntas = [];
	const querySnapshot = await obtenerDocs(coleccion(db, "preguntasYRespuestas"));
	querySnapshot.forEach((doc) => {
	  preguntas.push({
	  	id: doc.id,
	  	data: doc.data(),
	  });
	});
	
	return preguntas;
}

export async function eliminarPregunta(id){
	await eliminarDoc(documento(db, "preguntasYRespuestas", id));
	return true;
}

export async function guardarAvisoPrivacidad(avisoPrivacidad){

	await set(documento(db, "avisoPrivacidad", "documento"), {
		texto: avisoPrivacidad,
	});

	return true
}

export async function consultarAvisoPrivacidad(){

	const docRef = documento(db, "avisoPrivacidad", "documento");
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}

export async function guardarTerminosCondiciones(terminosCondiciones){

	await set(documento(db, "terminosCondiciones", "documento"), {
		texto: terminosCondiciones,
	});

	return true
}

export async function consultarTerminosCondiciones(){

	const docRef = documento(db, "terminosCondiciones", "documento");
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}