import React, {useState, useEffect} from 'react';

export function ListaDePlanes({
	tipoPlan,
	planesMensuales,
	planesAnuales,
	comprarPlan,
}){
	if (tipoPlan == 'anual') {

		return(
			<div class="listaDePlanes">
				{planesAnuales.map((item) =>(
					<div class="plan">
						<div class="tituloPlan">
							<h4 class="tituloPlanH4">{item.data.nombre}</h4>
							<p class="descripcionCortaPlan">{item.data.descripcionCortar}</p>

							<p class="precioPlan">${item.data.precio} MXN <br/> Precio Incluye IVA 🙂</p>
							<buttom onClick={() => comprarPlan(item)} class="btn btn-primary colorRosa">Contratar {item.data.nombre}</buttom>
						</div>
						<div class="infoPlan">
							
							<h4 class="tituloPlanH4" style={{color: '#00003c',}}>Incluye:</h4> <br/>

							<h6 class="tituloPlanH4" style={{color: '#00003c', background: '#f5f5fb', paddingTop: 10, paddingBottom: 10,}}>Administrador de documentos</h6><br/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: 'mostrar'}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: 'mostrar'}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: 'mostrar'}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<h6 class="tituloPlanH4" style={{color: '#00003c', background: '#f5f5fb', paddingTop: 10, paddingBottom: 10,}}>Servicios Legales</h6><br/>
						
							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: 'mostrar'}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: 'mostrar'}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: 'mostrar'}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: 'mostrar'}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: 'mostrar'}}
							/>
						
						</div>
						<div class="footerPlan">
							<buttom onClick={() => comprarPlan(item)} class="btn btn-primary colorRosa">Contratar {item.data.nombre}</buttom>
						</div>
					</div>
				))}
			</div>
		);
	}else{

		return(
			<div class="listaDePlanes">
				{planesMensuales.map((item) =>(
					<div class="plan">
						<div class="tituloPlan">
							<h4 class="tituloPlanH4">{item.data.nombre}</h4>
							<p class="descripcionCortaPlan">{item.data.descripcionCortar}</p>

							<p class="precioPlan">${item.data.precio} MXN <br/> Precio Incluye IVA 🙂</p>
							<buttom onClick={() => comprarPlan(item)} class="btn btn-primary colorRosa">Contratar {item.data.nombre}</buttom>
						</div>
						<div class="infoPlan">
							
							<h4 class="tituloPlanH4" style={{color: '#00003c',}}>Incluye:</h4> <br/>

							<h6 class="tituloPlanH4" style={{color: '#00003c', background: '#f5f5fb', paddingTop: 10, paddingBottom: 10,}}>Administrador de documentos</h6><br/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: 'mostrar'}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: 'mostrar'}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: 'mostrar'}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<h6 class="tituloPlanH4" style={{color: '#00003c', background: '#f5f5fb', paddingTop: 10, paddingBottom: 10,}}>Servicios Legales</h6><br/>
						
							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: 'mostrar'}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: 'mostrar'}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: 'mostrar'}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: 'mostrar'}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: ''}}
							/>

							<CheckPlan
								item={item}
								documentosIlimitados={{si: item.data.documentosIlimitados, mostrar: ''}}
								descuentoDocumentos={{si: item.data.descuentoDocumentos, mostrar: ''}}
								documentosLogotipo={{si: item.data.documentosLogotipo, mostrar: ''}}
								atencionEspecializada={{si: item.data.atencionEspecializada, mostrar: ''}}
								descuentoServicios={{si: item.data.descuentoServicios, mostrar: ''}}
								serviciosIlimitados={{si: item.data.serviciosIlimitados, mostrar: ''}}
								proteccionPersonalAEjecutivos={{si: item.data.proteccionPersonalAEjecutivos, mostrar: ''}}
								seguimientoNegocio={{si: item.data.seguimientoNegocio, mostrar: 'mostrar'}}
							/>
						
						</div>
						<div class="footerPlan">
							<buttom onClick={() => comprarPlan(item)} class="btn btn-primary colorRosa">Contratar {item.data.nombre}</buttom>
						</div>
					</div>
				))}
			</div>
		);
	}
}

 export function CheckPlan({
	item,
	documentosIlimitados,
	descuentoDocumentos,
	documentosLogotipo,
	atencionEspecializada,
	descuentoServicios,
	serviciosIlimitados,
	proteccionPersonalAEjecutivos,
	seguimientoNegocio,
}){

	if (documentosIlimitados.si == true && documentosIlimitados.mostrar == 'mostrar') {
		return(
			<>
				<div class="puntoPlan">
					<span class="checkPlan">✓</span>
					<p class="textoPuntoPlan">Creación de documentos<br/> ilimitados al mes</p>
				</div>
			</>
		);
	}else if (descuentoDocumentos.si != '' && descuentoDocumentos.mostrar == 'mostrar') {
		return(
			<>
				<div class="puntoPlan">
					<span class="checkPlan">✓</span>
					<p class="textoPuntoPlan">Contratos a la medida {item.data.descuentoDocumentos}% OFF</p>
				</div>
			</>
		);
	}else if (documentosLogotipo.si == true && documentosLogotipo.mostrar == 'mostrar') {
		return(
			<>
				<div class="puntoPlan">
					<span class="checkPlan">✓</span>
					<p class="textoPuntoPlan">Personalizar documento con logotipo</p>
				</div>
			</>
		);
	}else if (atencionEspecializada.si != '' && atencionEspecializada.mostrar == 'mostrar') {
		return(
			<>
				<div class="puntoPlan">
					<span class="checkPlan">✓</span>
					<p class="textoPuntoPlan">{item.data.atencionEspecializada} llamadas al mes</p>
				</div>
			</>
		);
	}else if (descuentoServicios.si != '' && descuentoServicios.mostrar == 'mostrar') {
		return(
			<>
				<div class="puntoPlan">
					<span class="checkPlan">✓</span>
					<p class="textoPuntoPlan">{item.data.descuentoServicios}% OFF en Paquetes Especializados</p>
				</div>
			</>
		);
	}else if (proteccionPersonalAEjecutivos.si == true && proteccionPersonalAEjecutivos.mostrar == 'mostrar') {
		return(
			<>
				<div class="puntoPlan">
					<span class="checkPlan">✓</span>
					<p class="textoPuntoPlan">Protección personal a Ejecutivos.</p>
				</div>
			</>
		);
	}else if (seguimientoNegocio.si == true && seguimientoNegocio.mostrar == 'mostrar') {
		return(
			<>
				<div class="puntoPlan">
					<span class="checkPlan">✓</span>
					<p class="textoPuntoPlan">Seguimiento prioritario de tu negocio.</p>
				</div>
			</>
		);
	}else{
		return(
			<></>
		);
	}
}