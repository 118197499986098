import React, {useState, useEffect} from 'react';
import './home.css';
import Swal from 'sweetalert2';
import {
	guardarRespuesta, 
	consultarPreguntas, 
	eliminarPregunta, 
	guardarAvisoPrivacidad,
	consultarAvisoPrivacidad,
	guardarTerminosCondiciones,
	consultarTerminosCondiciones,
} from '../../../backend/preguntasYRespuestas/preguntasYRespuestas.js';
import {buscarDocumentos} from '../../../backend/documentos/documentos.js';
import {buscarServicios} from '../../../backend/servicios/servicios';
import ReactQuill from 'react-quill';

function AdminHomeScreen(){

	const [pregunta, CambioPregunta] = useState('');
	const [respuesta, CambioRespuesta] = useState('');
	const [editando, CambioEditando] = useState(false);
	const [idPregunta, CambioIdPregunta] = useState('');
	const [listaDePreguntas, CambioListaDePreguntas] = useState([]);
	const [busquedaPreguntas, CambioBusquedaPreguntas] = useState(false);
	const [paginas, CambioPaginas] = useState([
		{
			id: 'documentos',
			data:{
				nombre: 'Documentos Legales',
			}
		},
		{
			id: 'abogados',
			data:{
				nombre: 'Contacta un abogado',
			},
		},
	]);
	const [documentos, CambioDocumentos] = useState([]);
	const [servicios, CambioServicios] = useState([]);
	const [opciones, CambioOpciones] = useState([]);
	const [busquedaOpciones, CambioBusquedaOpciones] = useState(false);
	const [opcion, CambioOpcion] = useState(false);
	const [busquedaAvisoPrivacidad, CambioBusquedaAvisoPrivacidad] = useState(false);
	const [avisoPrivacidad, CambioAvisoPrivacidad] = useState('');
	const [busquedaTerminosCondiciones, CambioBusquedaTerminosCondiciones] = useState(false);
	const [terminosCondiciones, CambioTerminosCondiciones] = useState('');

	useEffect(() => {

		verRol();

		if (busquedaOpciones == false) {

			consultarProductos();
			CambioBusquedaOpciones(true);
		}

		if (busquedaAvisoPrivacidad == false) {

			consultarAvisoPrivacidad().then(response =>{

				if (response != false) {

					CambioAvisoPrivacidad(response.data.texto);
				}
			});

			CambioBusquedaAvisoPrivacidad(true);
		}

		if (busquedaTerminosCondiciones == false) {

			consultarTerminosCondiciones().then(response =>{

				if (response != false) {

					CambioTerminosCondiciones(response.data.texto);
				}
			});

			CambioBusquedaTerminosCondiciones(true);
		}
	});

	const verRol = async function(){
	    let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
	       	let dataUser = JSON.parse(usuario);
	        if (dataUser.rol != 'admin' && dataUser.rol != 'subAdmin') {
	        	window.location.href = '/';
	        }else{ 

	        	if (dataUser.rol == 'subAdmin') { 

	        		dataUser.restricciones.forEach(element =>{ console.log(element);
	        			if (element == 'preguntas') {
	        				document.getElementById('bloquearPreguntas').remove();
	        			}
	        		})
	        	}

	        	listarPreguntas();
	        }
	    }else{
	    	window.location.href = '/';
	    }
	}

	const consultarProductos = async function(){

		let documentos = await buscarDocumentos('cliente');
		let servicios = await buscarServicios('cliente', '');

		CambioDocumentos(documentos);
		CambioServicios(servicios);
	}

	const listarPreguntas = async function(){

		if (busquedaPreguntas == false) {
			consultarPreguntas().then(response =>{
				CambioListaDePreguntas(response);
				CambioBusquedaPreguntas(true);
			});
		}
	}

	const procesarPregunta = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		let data;

		if (editando == false) {
			data = {
				pregunta: pregunta,
				respuesta: respuesta,
				dataPagina: opcion,
			};
		}else if (editando == true) {
			data = {
				pregunta: pregunta,
				respuesta: respuesta,
				dataPagina: opcion,
				idPregunta: idPregunta,
			};
		}

		if (data.pregunta === '' || data.respuesta === ''|| data.dataPagina == false) {
			Swal.fire({
			  title: 'Por favor complete todos los campos para continuar.',
			  icon: 'info',
			});
		}else{
			guardarRespuesta(data, editando).then(response =>{
				window.location.reload();
			})
		}
	}

	const editarPregunta = async function(item){
		document.getElementById('pregunta').value = item.data.pregunta;
		document.getElementById('respuesta').value = item.data.respuesta;
		CambioPregunta(item.data.pregunta);
		CambioRespuesta(item.data.respuesta);
		CambioEditando(true)
		CambioIdPregunta(item.id);
		document.getElementById('botonCancelar').classList.remove("ocultar");
		document.getElementById('tablaLista').setAttribute('style', 'display: none;');
	}

	const cancelarEditarPregunta = async function(){
		document.getElementById('pregunta').value = '';
		document.getElementById('respuesta').value = '';
		CambioPregunta('');
		CambioRespuesta('');
		CambioEditando(false)
		CambioIdPregunta('');
		document.getElementById('botonCancelar').classList.add("ocultar");
		document.getElementById('tablaLista').setAttribute('style', '');
	}

	const quitarPregunta = async function(id){
		if (window.confirm("¿Desea Eliminar esta pregunta?") == true) { console.log('se cumple');
		  
		  	Swal.fire({
		      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		      showConfirmButton: false,
		      allowOutsideClick: false,
		      allowEnterKey: false,
		      allowEscapeKey: false,
		    });

		  eliminarPregunta(id).then(response =>{
		  	window.location.reload();
		  });
		}
	}

	const ponerListaOpciones = async function(tipo){

		if (tipo == 'paginas') {

			CambioOpciones(paginas);
			CambioOpcion(false);
		}else if (tipo == 'documentos') {

			CambioOpciones(documentos);
			CambioOpcion(false);
		}else if (tipo == 'servicios') {

			CambioOpciones(servicios);
			CambioOpcion(false);
		}
	}

	const ponerOpcion = async function(){

		let idData = document.getElementById('dataPagina').value;
		let dataPagina = false;

		opciones.forEach(element =>{

			if (element.id == idData) {

				dataPagina = {
					id: element.id,
					data:{
						nombre: element.data.nombre,
					},
				};
			}
		});

		CambioOpcion(dataPagina);
	}

	const procesarAvisoPrivacidad = async function(){

		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

	    guardarAvisoPrivacidad(avisoPrivacidad).then(response =>{

	    	Swal.fire({
			  title: 'Guardado',
			  icon: 'success',
			});
	    })
	}

	const procesarTerminosCondiciones = async function(){

		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

	    guardarTerminosCondiciones(terminosCondiciones).then(response =>{

	    	Swal.fire({
			  title: 'Guardado',
			  icon: 'success',
			});
	    })
	}

	return(
		<section class="seccion1HomeAdmin">
			<div class="container" id="bloquearPreguntas">

				<div class="row">
					<div class="col-sm">
						<h4 class="tituloPaginaAdmin">Gestionar Preguntas y respuesta</h4>
						<div class="mb-3">
						    <label for="exampleInputEmail1" class="form-label">Pregunta</label>
						    <input type="text" class="form-control" id="pregunta" onChange={() => CambioPregunta(document.getElementById('pregunta').value)} />
						</div>
						<div class="mb-3">
						  <label for="exampleInputPassword1" class="form-label">Respuesta</label>
						  <input type="text" class="form-control" id="respuesta" onChange={() => CambioRespuesta(document.getElementById('respuesta').value)} />
						</div>
						<div class="mb-3">
							<div class="form-check">
							  <input onClick={() => ponerListaOpciones('paginas')} class="form-check-input" type="radio" name="publicarEn" id="unaPagina" />
							  <label class="form-check-label" for="unaPagina">
							    Publicar en pagina
							  </label>
							</div>
							<div class="form-check">
							  <input onClick={() => ponerListaOpciones('documentos')} class="form-check-input" type="radio" name="publicarEn" id="unDocumento" />
							  <label class="form-check-label" for="unDocumento">
							    Publicar en documento
							  </label>
							</div>
							<div class="form-check">
							  <input onClick={() => ponerListaOpciones('servicios')} class="form-check-input" type="radio" name="publicarEn" id="unServicio" />
							  <label class="form-check-label" for="unServicio">
							    Publicar en Servicio
							  </label>
							</div>
						</div>
						<div class="mb-3">
							<select onChange={ponerOpcion} class="form-select" id="dataPagina">
							  <option value="">Selecionar donde desea publica</option>
							  {opciones.map((item) =>(
							  		<option value={item.id}>{item.data.nombre}</option>
							  ))}
							</select>
						</div>
						<button onClick={procesarPregunta} class="btn btn-warning letraBotonWarning margen">Guardar</button>
						<button onClick={cancelarEditarPregunta} class="btn btn-danger margen ocultar" id="botonCancelar">Cancelar</button>
					</div>
				</div>

				<div class="row">
					<div class="col-sm">
						<table id="tablaLista" class="table table-dark table-sm">
						  <thead>
						    <tr>
						      <th scope="col">Pregunta</th>
						      <th scope="col">Respuesta</th>
						      <th scope="col">Publicada en</th>
						      <th scope="col">Accion</th>
						    </tr>
						  </thead>
						  <tbody class="table-group-divider">
						  	{listaDePreguntas.map((item) =>(
						  		<tr>
							      <td>{item.data.pregunta}</td>
							      <td>{item.data.respuesta}</td>
							      <td>{item.data.dataPagina.data.nombre}</td>
							      <td>
							      	<button onClick={() => editarPregunta(item)} class="btn btn-primary margen">Editar</button>
									<button onClick={() => quitarPregunta(item.id)} class="btn btn-danger">Eliminar</button>
							      </td>
							    </tr>
						  	))}
						  </tbody>
						</table>
					</div>
				</div>

				<div class="row">
					<div class="col-sm">
						<h4 class="tituloPaginaAdmin">Gestionar Aviso de privacidad</h4>
						<br/>
						<ReactQuill theme="snow" value={avisoPrivacidad} onChange={CambioAvisoPrivacidad} />
						<br/>
						<button onClick={procesarAvisoPrivacidad} class="btn btn-warning letraBotonWarning margen">Guardar</button>
					</div>
				</div>

				<div class="row">
					<div class="col-sm">
						<h4 class="tituloPaginaAdmin">Gestionar Términos y Condiciones</h4>
						<br/>
						<ReactQuill theme="snow" value={terminosCondiciones} onChange={CambioTerminosCondiciones} />
						<br/>
						<button onClick={procesarTerminosCondiciones} class="btn btn-warning letraBotonWarning margen">Guardar</button>
					</div>
				</div>
			</div>
		</section>
	);
}

export default AdminHomeScreen;