import React, { useState, useEffect } from 'react';
import './agendar-styles.css';

export function MostrarDia({item, seleccionarDia}){
	return(
		<span onClick={() => seleccionarDia(item)} class={'dia '+item.clase}><span id={item.idDia}>{item.nuemroDia}</span></span>
	);
}

export function ListaDeHorarios({horarios, seleccionarHorario}){

	if (horarios.length > 0) {
		return(
			<>
				<span class="tituloHorarios">Horarios disponible</span>
				{horarios.map((item) =>(
					<span onClick={() => seleccionarHorario(item)} class={'horario '+item.disponibilidad} id={item.id}>{item.horario}</span>
				))}
			</>
		);
	}else{
		return(
			<></>
		);
	}
}