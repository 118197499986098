import React, {useState, useEffect} from 'react';
import './comoFunciona-styles.css';
import {descargaloComoFunciona, encuentraloComoFunciona, flechaComoFunciona, pagaloComoFunciona, personalizaloComoFunciona} from '../../assets/imgs.js';

function ComoFunciona(){
	return(
		<>
			<div class="containerComoFunciona">
				<h2 class="tituloComoFunciona">¿Cómo funciona?</h2>
				<div class="pasosComoFunciona">
					<div class="imgEInfoComoFunciona">
						<img src={encuentraloComoFunciona} class="imgComoFunciona" />
						<p class="parrafoComoFunciona">Encuantra</p>
					</div>
					<img src={flechaComoFunciona} class="flechaComoFunciona" />
					<div class="imgEInfoComoFunciona">
						<img src={personalizaloComoFunciona} class="imgComoFunciona" />
						<p class="parrafoComoFunciona">Personalizalo</p>
					</div>
					<img src={flechaComoFunciona} class="flechaComoFunciona" />
					<div class="imgEInfoComoFunciona">
						<img src={pagaloComoFunciona} class="imgComoFunciona" />
						<p class="parrafoComoFunciona">Pagalo</p>
					</div>
					<img src={flechaComoFunciona} class="flechaComoFunciona" />
					<div class="imgEInfoComoFunciona">
						<img src={descargaloComoFunciona} class="imgComoFunciona" />
						<p class="parrafoComoFunciona">Descargalo</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default ComoFunciona;