import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import 'bootswatch/dist/cosmo/bootstrap.min.css';
import './menu.css';
import {
	logoMenu, 
	imgUser, 
	imgLogin, 
	imgAdminMenu, 
	inicio, 
	documentos, 
	firmas, 
	categorias, 
	ordenes, 
	salir,
	iconoServicios,
	iconoCalendario,
	planes,
	cupones,
	userMenu
} from '../../assets/imgs';
import Swal from 'sweetalert2';
import {
	enviarCodigo, 
	smsValidarCodigo, 
	resgistrarUsuario, 
	reenviarCodigo, 
	iniciarSesion, 
	recuperarPassword, 
	guardarNuevaPassword,
	sesionFacebook
} from '../../backend/auth/auth.js';
import {ModalAuth, ModalCambiarPassword} from '../modals/modals.js';
import Login from '../login/login';

function Menu({rol}){

	const [abrirOCerrar, EstadoMenu] = useState(false);
	const [mostrar, EstadoModal] = useState(false);
	const [loginEmail, EmailLogin] = useState('');
	const [loginPassword, PasswordLogin] = useState('');
	const [authForm, CambiarAuthForm] = useState('login');
	const [registroEmail, EmailRegistro] = useState('');
	const [registroPassword, PasswordRegistro] = useState('');
	const [confirmPassword, PasswordConfirm] = useState('');
	const [registroNombre, NombreRegistro] = useState('');
	const [telefono, ElTelefono] = useState('');
	const [codigo, ElCodigo] = useState('');
	const [hayTiempo, EsteTiempo] = useState(false);
	const [emailRecuperar, LaRecuperacion] = useState('');
	const [emailOpciones, ElEmailOpciones] = useState('');
	const [menuOpcion, EstadoMenuOpciones] = useState(false);
	const [mostrarCambioPassword, ElCambioPassword] = useState(false);
	const [nuevaPassword, LaNuevaPassword] = useState('');
	const [repitaPassword, LaPasswordRepetida] = useState('');
	const [actualPassword, LaActualPassword] = useState('');

	useEffect(() => {
		datosUsuario();
	});

	const datosUsuario = async function(){
		let usuario = await localStorage.getItem('usuarioLegalizarlo');
		if (usuario != null) {
			let dataUser = JSON.parse(usuario);

			if (dataUser.rol == 'subAdmin') { 

	        	dataUser.restricciones.forEach(element =>{ console.log(element);
	        		document.getElementById(element).remove();
	        	})
	        }

			ElEmailOpciones(dataUser.email);
		}
	}

	const accionMenu = function (){

		var listaResponsive = document.getElementById("listaResponsive");

		if (abrirOCerrar == false) {
			EstadoMenu(true);
			listaResponsive.classList.add("listaResponsiveAbierto");
		}else if (abrirOCerrar == true) {
			EstadoMenu(false);
  			listaResponsive.classList.remove("listaResponsiveAbierto");
		}
	}

	const accionMenuAdmin = function (){

		var listaMenuAdmin = document.getElementById("listaMenuAdmin");
		var elementos = ['inicio', 'documentos', 'servicios', 'planes', 'firmas', 'categorias', 'citas', 'ordenes', 'cupones', 'usuarios', 'administradores', 'salir']; //document.getElementsByClassName('elementoMenuAdmin');

		if (abrirOCerrar == false) {
			EstadoMenu(true); document.getElementById('listaMenuAdmin').setAttribute("style", "");
			listaMenuAdmin.classList.add("menuAdminActivo");
			/*elementos.forEach(element =>{
  				let elemento = document.getElementById(element);
  				elemento.classList.remove("elementoMenuAdminInvicible");
  			});*/
		}else if (abrirOCerrar == true) {
			EstadoMenu(false); document.getElementById('listaMenuAdmin').setAttribute("style", "display: none;");
  			listaMenuAdmin.classList.remove("menuAdminActivo");
  			/*elementos.forEach(element =>{
  				let elemento = document.getElementById(element);
  				elemento.classList.add("elementoMenuAdminInvicible");
  			});*/
		}

		setTimeout(quitaElementos, 500);
	}

	const quitaElementos = async function(){
		let usuario = await localStorage.getItem('usuarioLegalizarlo');
		if (usuario != null) {
			let dataUser = JSON.parse(usuario);

			if (dataUser.rol == 'subAdmin') { 

	        	dataUser.restricciones.forEach(element =>{ console.log(element);
	        		if (element != 'preguntas') {
	        			document.getElementById(element).remove();
	        		}
	        	})
	        }
		}
	}

	const formLogin = async function (){
		let usuario = await localStorage.getItem('usuarioLegalizarlo');
		if (usuario != null) {
			window.location.href = '/cliente/home';
		}else{
			if (mostrar == true) {
				EstadoModal(false);
				CambiarAuthForm('login');
			}else if (mostrar == false) {
				EstadoModal(true);
			}
		}
	}

	const codigoSms = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		if (telefono === '') {
			Swal.fire({
			  title: 'Por favor introduzca un numero para continuar.',
			  icon: 'info',
			});
		}else if (isNaN(telefono)) {
			Swal.fire({
			  title: 'Solo se aceptan caracteres numerico.',
			  icon: 'info',
			});
		}else{
			if(hayTiempo){
				Swal.fire({
				  title: 'No puede reenviar el codigo hasta que culmine el tiempo de espera.',
				  icon: 'info',
				});
			}else{

				document.getElementById('mensajeCodigo').innerHTML = '';

				enviarCodigo(telefono).then(response =>{
					if (response) {
						document.getElementById('mensajeCodigo').innerHTML = '<span style="color: green; margin-left: 15px;">Codigo enviado</span><span id="tiempo"></span>';
						cuentaRegresiva();
					}else{
						document.getElementById('mensajeCodigo').innerHTML = '';
					}

					Swal.close();
				})
			}
		}
	}

	const cuentaRegresiva = async function(){
		var timer = 120, minutes, seconds;
	    var interVal = setInterval(() =>{
	        minutes = parseInt(timer / 60, 10);
	        seconds = parseInt(timer % 60, 10);

	        minutes = minutes < 10 ? "0" + minutes : minutes;
	        seconds = seconds < 10 ? "0" + seconds : seconds;

	        document.getElementById('tiempo').innerHTML = minutes+' : '+seconds; //jQuery('#tiempo').html();

	        EsteTiempo(true);

	        if (--timer < 0) {
	            EsteTiempo(false);
	            clearInterval(interVal);
	            document.getElementById('mensajeCodigo').innerHTML = '';
	        }
	   	},1000);
	};

	const validarCodigo = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		if (registroEmail === '' || registroPassword === '' || confirmPassword === '' || registroNombre === '' || telefono === '') {
			Swal.fire({
			  title: 'Por favor complete todos los campos para continuar.',
			  icon: 'info',
			});
		}else if(registroPassword != confirmPassword) {
			Swal.fire({
			  title: 'La contraseña no coincide.',
			  icon: 'info',
			});
		}else{
			smsValidarCodigo(telefono, codigo).then(response =>{
				if (response) {
					procesarUsuario();
				}else{
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'El código de verificación no es valido.',
					});
				}
			})
		}
	}

	const procesarUsuario = async function(){

		let datos = {
			email: registroEmail,
			password: registroPassword,
			nombre: registroNombre,
			telefono: telefono,
			rol: 'cliente',
		}

		resgistrarUsuario(datos).then(response =>{
			if (response != false) {
				terminarAuth(response);
			}else{
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Lo sentimos pero no pudimos registrar sus datos.',
				});
			}
		})
	}

	const terminarAuth = async function(datos){
		await localStorage.setItem('usuarioLegalizarlo', JSON.stringify(datos));
		window.location.href = '/cliente/home';
	}

	const accionReenvio = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		if (hayTiempo) {
			Swal.fire({
			  title: 'No puede reenviar el codigo hasta que culmine el tiempo de espera.',
			  icon: 'info',
			})
		}else{
			reenviarCodigo(telefono).then(response =>{
				if (response) {
					document.getElementById('mensajeCodigo').innerHTML = '<span style="color: green; margin-left: 15px;">Codigo enviado</span><span id="tiempo"></span>';
					cuentaRegresiva();
				}else{
					document.getElementById('mensajeCodigo').innerHTML = '';
				}

				Swal.close();
			})
		}
	}

	const procesarLogin = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		if (loginEmail === '' || loginPassword === '') {
			Swal.fire({
			  title: 'Complete todos los campos para continuar.',
			  icon: 'info',
			})
		}else{
			iniciarSesion(loginEmail, loginPassword).then(response =>{
				if (response != false) {
					terminarAuth(response);
				}else{
					Swal.fire({
					  title: 'Lo sentimos pero hubo un error al tratar de iniciar sesión, por favor verifique que sus datos sean correctos o que ya este registrado.',
					  icon: 'info',
					})
				}
			})
		}
	}

	const enviarCorreoRecuperacion = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		if (emailRecuperar === '') {
			Swal.fire({
			  title: 'Complete todos los campos para continuar.',
			  icon: 'info',
			})
		}else{
			recuperarPassword(emailRecuperar).then(response =>{
				if (response) {
					Swal.fire({
					  title: 'Hemos enviado un mensaje al correo: '+emailRecuperar+'.',
					  icon: 'success',
					});
					EstadoModal(false);
					LaRecuperacion('');
					CambiarAuthForm('login');
				}else{
					Swal.fire({
					  title: 'Lo sentimos hubo error al autenticar sus datos.',
					  icon: 'error',
					})
				}
			})
		}
	}

	const cerrarSesion = async function(){
		await localStorage.clear();
		window.location.href = '/';
	}

	const opcionesDeSesion = async function(){
		let menu = document.getElementById('opcionesDeSesion');
		if (menuOpcion == false) {
			menu.classList.remove('menuOpcionesCerrado');
			EstadoMenuOpciones(true);
		}else if (menuOpcion == true) {
			menu.classList.add('menuOpcionesCerrado');
			EstadoMenuOpciones(false);
		}
	}

	const mostrarModalPassword = async function(){
		if (mostrarCambioPassword == true) {
			ElCambioPassword(false);
		}else if (mostrarCambioPassword == false) {
			ElCambioPassword(true);
		}
	}

	const procesoCambioPassword = async function(){
		let usuario = await localStorage.getItem('usuarioLegalizarlo');
		if (usuario != null) {
			let dataUser = JSON.parse(usuario);
			if (nuevaPassword === ''|| repitaPassword === '' || actualPassword === '') {
				Swal.fire({
				  title: 'Complete todos los campos para continuar.',
				  icon: 'info',
				});
			}else if (nuevaPassword != repitaPassword) {
				Swal.fire({
				  title: 'Por favor escriba 2 veces la contraseña.',
				  icon: 'info',
				});
			}else{
				let login = await iniciarSesion(dataUser.email, actualPassword);
				if (login != false) {
					guardarNuevaPassword(nuevaPassword).then(response =>{
						if (response) {
							window.location.reload();
						}else{
							Swal.fire({
							  title: 'Lo sentimos pero hubo un error al tratar de cambiar su contraseña, por favor intente mas tarde.',
							  icon: 'error',
							});
						}
					})
				}else{
					Swal.fire({
					  title: 'Lo sentimos pero hubo un error al tratar de autenticar el usuario, por favor intente mas tarde.',
					  icon: 'error',
					});
				}
			}
		}
	}

	const procesarFacebook = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		sesionFacebook().then(response =>{
			if (response != false) {
				window.location.href = '/cliente/home';
			}else{
				Swal.fire({
					title: 'Lo sentimos pero hubo un error al tratar de iniciar sesión, por favor verifique que sus datos sean correctos o que ya este registrado.',
					icon: 'error',
				})
			}
		})
	}

	if (rol == 'admin' || rol == 'subAdmin') {
		return(
			<>
				<div class="menuAdmin">
					<div>
						<img onClick={() => accionMenuAdmin()} src={imgAdminMenu} class="iconoMenu" /><br/>
						<div id="listaMenuAdmin" class="listaMenuAdmin" style={{display: 'none',}}>
							<Link to="/admin/home" id="inicio" class="elementoMenuAdmin">
								<img src={inicio} />
								Inicio
							</Link>
							<Link to="/admin/documentos" id="documentos" class="elementoMenuAdmin">
								<img src={documentos} />
								Documentos
							</Link>
							<Link to="/admin/servicios" id="servicios" class="elementoMenuAdmin">
								<img class="iconoServicios" src={iconoServicios} />
								Servicios
							</Link>
							<Link to="/admin/citas" id="citas" class="elementoMenuAdmin">
								<img class="iconoServicios" src={iconoCalendario} />
								Citas
							</Link>
							<Link to="/admin/planes" id="planes" class="elementoMenuAdmin">
								<img class="iconoServicios" src={planes} />
								Planes
							</Link>
							<Link to="/admin/home" id="categorias" class="elementoMenuAdmin">
								<img src={categorias} />
								Categorias
							</Link>
							<Link to="/admin/serviciosPersonalizados" id="ordenes" class="elementoMenuAdmin">
								<img src={ordenes} />
								Servicios Personalizas
							</Link>
							<Link to="/admin/cupones/listado" id="cupones" class="elementoMenuAdmin" style={{height: 45,}}>
								<img src={cupones} />
								Cupones
							</Link>
							<Link to="/admin/usuarios" id="usuarios" class="elementoMenuAdmin" style={{height: 45,}}>
								<img src={userMenu} />
								Usuarios
							</Link>
							<Link to="/admin/administradores" id="administradores" class="elementoMenuAdmin" style={{height: 45,}}>
								<img src={userMenu} />
								Administradores
							</Link>
							<Link onClick={() => cerrarSesion()} to="/admin/home" id="salir" class="elementoMenuAdmin">
								<img src={salir} />
								Salir
							</Link>
						</div>
					</div>
					<Link to="/admin/home" class="navbar-brand">
				      	<img src={logoMenu} style={{width: 193,}} />
				    </Link>
				    <div>
				    	<img onClick={() => opcionesDeSesion()} class="imgUserMenu" src={imgUser} />
				    	<div id="opcionesDeSesion" class="opcionesDeSesion menuOpcionesCerrado">
				    		<h4 class="tituloOpcionesSesion">Usuario:</h4>
				    		<p onClick={() => mostrarModalPassword()} class="emailOpciones">{emailOpciones}</p>
				    		<hr class="hrOpciones"/>
				    		<p class="emailOpciones" onClick={() => cerrarSesion()}>Salir</p>
				    	</div>
				    </div>
				</div>
				<ModalCambiarPassword mostrar={mostrarCambioPassword}>
					<div class="container" id="formAuth">
						<div class="row">
							<div class="col-sm alinearConImgLogin text-center">
								<div class="divBotonCerrar">
									<span onClick={() => mostrarModalPassword()} class="xCerrar"><svg xmlnsSvg="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" tag="i" class="v-icon notranslate v-theme--light v-icon--size-default iconify iconify--tabler" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 6L6 18M6 6l12 12"></path></svg></span>

					                <span >

					                </span>

								</div>
								<h1 class="tituloLogin">Cambiar contraseña</h1>
								<input id="nuevaPassword" placeholder="Nueva contraseña" type="password" onChange={() => LaNuevaPassword(document.getElementById('nuevaPassword').value)} class="inputLogin marginAuto" />
								<input id="repitaPassword" placeholder="Repita la nueva contraseña" type="password" onChange={() => LaPasswordRepetida(document.getElementById('repitaPassword').value)} class="inputLogin marginAuto" />
								<input id="actualPassword" placeholder="Contraseña actual" type="password" onChange={() => LaActualPassword(document.getElementById('actualPassword').value)} class="inputLogin marginAuto" />
								<button onClick={() => procesoCambioPassword()} class="botonSesionEmail">Actualizar</button>
							</div>
						</div>
					</div>
				</ModalCambiarPassword>
			</>
		);
	}else{
		return(
			<>
				<nav class="navbar bg-light menuLegalizarlo">
				  <div class="container px-0">
				    <a href="/" class="navbar-brand">
				      <img src={logoMenu} style={{width: 193,}} />
				    </a>
				    <ul class="navbar-nav itemsLegalizarloMenu">
					  <li class="nav-item marginLeftMenu">
					  	<a href="/documentos" class="nav-link itemMenuLegalizarlo">Documentos Legales</a>
					  </li>
					  <li class="nav-item marginLeftMenu">
					  	<a href="/abogados" class="nav-link itemMenuLegalizarlo">Contacta a un abogado</a>
					  </li>
					  <li class="nav-item marginLeftMenu">
					  	<a href="/planes" class="nav-link itemMenuLegalizarlo">Planes</a>
					  </li>
					  <li class="nav-item marginLeftMenu">
					  	<a href="/nosotros" class="nav-link itemMenuLegalizarlo">Nosotros</a>
					  </li>
					  <li onClick={() => formLogin()} class="nav-item itemMenuLegalizarlo">
					  	<img class="imgUserMenu" src={imgUser} />
					  </li>
					</ul>
				  </div>
				</nav>
				<nav class="navbar navbar-dark bg-dark menuLegalizarloResponsive">
				  <div class="container-fluid">
				  	<Link to="/">
				  		<img src={logoMenu} style={{width: 193,}} />
				  	</Link>
				    <button onClick={() => accionMenu()} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
				      <span class="navbar-toggler-icon"></span>
				    </button>
				  </div>
				</nav>
				<div class="listaResponsive" id="listaResponsive">
					<ul>
						<li>
						 	<Link to="/documentos" class="nav-link itemMenuLegalizarlo">Documentos Legales</Link>
						</li>
						<li>
						  	<Link to="/abogados" class="nav-link itemMenuLegalizarlo">Contacta a un abogado</Link>
						</li>
						<li>
						  	<Link to="/planes" class="nav-link itemMenuLegalizarlo">Planes</Link>
						</li>
						<li>
						  	<Link to="/nosotros" class="nav-link itemMenuLegalizarlo">Nosotros</Link>
						</li>
						<li onClick={() => formLogin()}>
						  	<img class="imgUserMenu" src={imgUser} />
						</li>
					</ul>
				</div>
				<ModalAuth mostrar={mostrar}>
					<Login
						pantalla={authForm}
						imgLogin={imgLogin}
						cerrarModal={() => formLogin()}
						onChangeLoginEmail={() => EmailLogin(document.getElementById('loginEmail').value)}
						onChangeLoginPassword={() => PasswordLogin(document.getElementById('loginPassword').value)}
						cambiarARegistro={() => CambiarAuthForm('registro')}
						cambiarALogin={() => CambiarAuthForm('login')}
						onChangeRegistroEmail={() => EmailRegistro(document.getElementById('registroEmail').value)}
						onChangeRegistroPassword={() => PasswordRegistro(document.getElementById('registroPassword').value)}
						onChangeConfirmPassword={() => PasswordConfirm(document.getElementById('confirmPassword').value)}
						onChangeRegistroNombre={() => NombreRegistro(document.getElementById('registroNombre').value)}
						onChangeTelefono={() => ElTelefono(document.getElementById('telefono').value)}
						onChangeCodigo={() => ElCodigo(document.getElementById('codigo').value)}
						codigoSms={() => codigoSms()}
						validarCodigo={() => validarCodigo()}
						accionReenvio={() => accionReenvio()}
						procesarLogin={() => procesarLogin()}
						mostrarRecuperacion={() => CambiarAuthForm('recuperarPassword')}
						onChangeRecuperarEmail={() => LaRecuperacion(document.getElementById('recuperarEmail').value)}
						procesarRecuperar={() => enviarCorreoRecuperacion()}
						procesarFacebook={procesarFacebook}
					/>
				</ModalAuth>
			</>
		);
	}
}

export default Menu;
