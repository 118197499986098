import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, updatePassword, updateEmail, FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, addDoc, updateDoc, collection, getDocs, deleteDoc, query, where, } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB6MpbdNPYeDL5ETPxt1oX-mfQY_2ylJEI",
  authDomain: "legalizarlo.firebaseapp.com",
  projectId: "legalizarlo",
  storageBucket: "legalizarlo.appspot.com",
  messagingSenderId: "323019347621",
  appId: "1:323019347621:web:d3657abd3184b6fcf35f4c",
  measurementId: "G-MT0RPYL789"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth();
export const providerFacebook = new FacebookAuthProvider();
export const popupSining = signInWithPopup;
export const crearUsuario = createUserWithEmailAndPassword;
export const db = getFirestore(app);
export const obtenerDocs = getDocs;
export const documento = doc;
export const coleccion = collection;
export const agregarDoc = addDoc;
export const set = setDoc;
export const actualizarDoc = updateDoc;
export const eliminarDoc = deleteDoc;
export const sesionUsuario = signInWithEmailAndPassword;
export const leerDatos = getDoc;
export const cambiarPassword = sendPasswordResetEmail;
export const actualizarEmail = updateEmail;
export const actualizarPassword = updatePassword;
export const almacenamiento = getStorage(app);
export const referencia = ref;
export const subirArchivo = uploadBytes;
export const obtenerURL = getDownloadURL;
export const buscar = query;
export const donde = where;
