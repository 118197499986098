import React, {useState, useEffect} from 'react';
import './home-styles.css';
import Footer from '../../../components/footer/footer';
import {
	guardarNuevaEmail, 
	iniciarSesion, 
	enviarCodigo, 
	smsValidarCodigo, 
	resgistrarUsuario, 
	reenviarCodigo, 
	recuperarPassword, 
	guardarNuevaPassword, 
	guardarTelefono, 
	guardarNombre
} from '../../../backend/auth/auth';
import {misComprar, serviciosPersonalizados, desactivarPlan, terminarActualizacion} from '../../../backend/cliente/cliente';
import {ModalAuth, ModalCambiarPassword} from '../../../components/modals/modals';
import {paises} from '../../../assets/paises.js';
import Swal from 'sweetalert2';

import {EstadoItem, BotonAccionDocumento, BotonAccionServicio, AccionServicioPersonalizado, BotonAccionPlan} from '../../../components/cliente/cliente';


import {pdf, word} from '../../../assets/imgs.js';


function ClienteHomeScreen(){

	const [nombre, CambioNombre] = useState('');
	const [apellido, CambioApellido] = useState('');
	const [email, CambioEmail] = useState('');
	const [direccion, CambioDireccion] = useState('');
	const [pais, CambioPais] = useState('');
	const [codigoPostal, CambioCodigoPostal] = useState('');
	const [telefono, CambioTelefono] = useState('');
	const [identificacion, CambioIdentificacion] = useState('');
	const [mostrarFormEmail, CambioMostrarFormEmail] = useState(false);
	const [emailActual, CambioEmailActual] = useState('');
	const [emailNuevo, CambioEmailNuevo] = useState('');
	const [emailNuevoPassword, CambioEmailNuevoPassword] = useState('');
	const [hayTiempo, EsteTiempo] = useState(false);
	const [codigo, ElCodigo] = useState('');
	const [mostrarFormTelefono, CambioMostrarFormTelefono] = useState(false);
	const [telefono2, CambioTelefono2] = useState('');
	const [mostrarFormNombre, CambioMostrarFormNombre] = useState(false);
	const [nombreNuevo, CambioNombreNuevo] = useState('');
	const [mostrarCambioPassword, ElCambioPassword] = useState(false);
	const [nuevaPassword, LaNuevaPassword] = useState('');
	const [repitaPassword, LaPasswordRepetida] = useState('');
	const [actualPassword, LaActualPassword] = useState('');
	const [comprasBuscadas, CambioComprasBuscadas] = useState(false);
	const [documentos, CambioDocumentos] = useState([]);
	const [reuniones, CambioReuniones] = useState([]);
	const [planes, CambioPlanes] = useState([]);
	const [elIdCompra, CambioElIdCompra] = useState('');
	const [mostrarModalDescarga, CambioMostrarModalDescarga] = useState(false);
	const [mostrarModalAgenda, CambioMostrarModalAgenda] = useState(false);
	const [textoDataCita, CambioTextoDataCita] = useState('');
	const [textoDataLink, CambioTextoDataLink] = useState('');
	const [reunionesPerzonalizados, CambioReunionesPerzonalizados] = useState([]);
	const [mostrarModalPlan, CambioMostrarModalPlan] = useState(false);
	const [idPlan, CambioIdPlan] = useState('');

	useEffect(() => {
		verRol();
	});

	const verRol = async function(){
	    let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
	       	let dataUser = JSON.parse(usuario);
	        if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {
	        	window.location.href = '/admin/home';
	        }

			if (comprasBuscadas == false) {

				CambioNombre(dataUser.nombre);
				CambioEmail(dataUser.email);
				CambioTelefono(dataUser.telefono);

				if (dataUser.datosActualizados) {

					CambioApellido(dataUser.apellido);
					CambioDireccion(dataUser.direccion);
					CambioPais(dataUser.pais);
					CambioCodigoPostal(dataUser.codigoPostal);
					CambioIdentificacion(dataUser.identificacion);
				}


				let alerta = await localStorage.getItem('alertActualizar');

				if (alerta != null) {

					Swal.fire({
				        title: 'Actualice sus datos antes de realizar una compra.',
				        icon: 'info',
				    });

					await localStorage.removeItem('alertActualizar');
				}

				let misDocumentos = [];
				let misReuniones = [];
				let misReunionesPersozonalizados = await serviciosPersonalizados(dataUser.uid);
				CambioReunionesPerzonalizados(misReunionesPersozonalizados);
				let misPlanes = [];

				let compras = await misComprar(dataUser.uid);
				for (let compra of compras) {
					if (compra.compra.data.tipo == 'documentos') {
						misDocumentos.push(compra);
					}else if (compra.compra.data.tipo == 'servicios') {
						misReuniones.push(compra);
					}else if (compra.compra.data.tipo == 'planes') {
						misPlanes.push(compra);
					}
				}

				CambioComprasBuscadas(true);
				CambioDocumentos(misDocumentos);
				CambioReuniones(misReuniones);
				CambioPlanes(misPlanes);
			}
	    }else{
	    	window.location.href = '/';
	    }
	}

	const verEstaPestana = async function(pestana){
		if (pestana == 'misDatos') {
			document.getElementById('misDatos').classList.add('activa');
			document.getElementById('misDatos1').classList.remove('ocutarHomeCliente');
			document.getElementById('misDatos2').classList.remove('ocutarHomeCliente');
			document.getElementById('misDocumentos').classList.remove('activa');
			document.getElementById('misDocumentos1').classList.add('ocutarHomeCliente');
			document.getElementById('misReuniones').classList.remove('activa');
			document.getElementById('misReuniones1').classList.add('ocutarHomeCliente');

			document.getElementById('misReunionesPersozonalizados').classList.remove('activa');
			document.getElementById('misReunionesPersozonalizados1').classList.add('ocutarHomeCliente');

			document.getElementById('misPlanes').classList.remove('activa');
			document.getElementById('misPlanes1').classList.add('ocutarHomeCliente');
		}else if (pestana == 'misDocumentos') {
			document.getElementById('misDatos').classList.remove('activa');
			document.getElementById('misDatos1').classList.add('ocutarHomeCliente');
			document.getElementById('misDatos2').classList.add('ocutarHomeCliente');
			document.getElementById('misDocumentos').classList.add('activa');
			document.getElementById('misDocumentos1').classList.remove('ocutarHomeCliente');
			document.getElementById('misReuniones').classList.remove('activa');
			document.getElementById('misReuniones1').classList.add('ocutarHomeCliente');

			document.getElementById('misReunionesPersozonalizados').classList.remove('activa');
			document.getElementById('misReunionesPersozonalizados1').classList.add('ocutarHomeCliente');

			document.getElementById('misPlanes').classList.remove('activa');
			document.getElementById('misPlanes1').classList.add('ocutarHomeCliente');
		}else if (pestana == 'misReuniones') {
			document.getElementById('misDatos').classList.remove('activa');
			document.getElementById('misDatos1').classList.add('ocutarHomeCliente');
			document.getElementById('misDatos2').classList.add('ocutarHomeCliente');
			document.getElementById('misDocumentos').classList.remove('activa');
			document.getElementById('misDocumentos1').classList.add('ocutarHomeCliente');
			document.getElementById('misReuniones').classList.add('activa');
			document.getElementById('misReuniones1').classList.remove('ocutarHomeCliente');

			document.getElementById('misReunionesPersozonalizados').classList.remove('activa');
			document.getElementById('misReunionesPersozonalizados1').classList.add('ocutarHomeCliente');

			document.getElementById('misPlanes').classList.remove('activa');
			document.getElementById('misPlanes1').classList.add('ocutarHomeCliente');
		}else if (pestana == 'misReunionesPersozonalizados') {
			document.getElementById('misDatos').classList.remove('activa');
			document.getElementById('misDatos1').classList.add('ocutarHomeCliente');
			document.getElementById('misDatos2').classList.add('ocutarHomeCliente');
			document.getElementById('misDocumentos').classList.remove('activa');
			document.getElementById('misDocumentos1').classList.add('ocutarHomeCliente');
			document.getElementById('misReuniones').classList.remove('activa');
			document.getElementById('misReuniones1').classList.add('ocutarHomeCliente');

			document.getElementById('misReunionesPersozonalizados').classList.add('activa');
			document.getElementById('misReunionesPersozonalizados1').classList.remove('ocutarHomeCliente');

			document.getElementById('misPlanes').classList.remove('activa');
			document.getElementById('misPlanes1').classList.add('ocutarHomeCliente');
		}else if (pestana == 'misPlanes') {
			document.getElementById('misDatos').classList.remove('activa');
			document.getElementById('misDatos1').classList.add('ocutarHomeCliente');
			document.getElementById('misDatos2').classList.add('ocutarHomeCliente');
			document.getElementById('misDocumentos').classList.remove('activa');
			document.getElementById('misDocumentos1').classList.add('ocutarHomeCliente');
			document.getElementById('misReuniones').classList.remove('activa');
			document.getElementById('misReuniones1').classList.add('ocutarHomeCliente');

			document.getElementById('misReunionesPersozonalizados').classList.remove('activa');
			document.getElementById('misReunionesPersozonalizados1').classList.add('ocutarHomeCliente');

			document.getElementById('misPlanes').classList.add('activa');
			document.getElementById('misPlanes1').classList.remove('ocutarHomeCliente');
		}
	}

	const procesoCambioPassword = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		let usuario = await localStorage.getItem('usuarioLegalizarlo');
		if (usuario != null) {
			let dataUser = JSON.parse(usuario);
			if (nuevaPassword === ''|| repitaPassword === '' || actualPassword === '') {
				Swal.fire({
				  title: 'Complete todos los campos para continuar.',
				  icon: 'info',
				});
			}else if (nuevaPassword != repitaPassword) {
				Swal.fire({
				  title: 'Por favor escriba 2 veces la contraseña.',
				  icon: 'info',
				});
			}else{
				let login = await iniciarSesion(dataUser.email, actualPassword);
				if (login != false) {
					guardarNuevaPassword(nuevaPassword).then(response =>{
						if (response) {
							window.location.reload();
						}else{
							Swal.fire({
							  title: 'Lo sentimos pero hubo un error al tratar de cambiar su contraseña, por favor intente mas tarde.',
							  icon: 'error',
							});
						}
					})
				}else{
					Swal.fire({
					  title: 'Lo sentimos pero hubo un error al tratar de autenticar el usuario, por favor intente mas tarde.',
					  icon: 'error',
					});
				}
			}
		}
	}

	const mostrarModalPassword = async function(){
		if (mostrarCambioPassword == true) {
			ElCambioPassword(false);
		}else if (mostrarCambioPassword == false) {
			ElCambioPassword(true);
		}
	}

	const cerrarSesion = async function(){
		await localStorage.clear();
		window.location.href = '/';
	}

	const accionDocumento = async function(item){
		if (item.compra.data.pagado == false) {
			Swal.fire({
			  title: 'No puede editar hasta que cancele el documento.',
			  icon: 'info',
			});
		}else if (item.compra.data.caracteristicas.editado == false) {
			await localStorage.setItem('idCompra', item.compra.id);
			window.location.href = '/cliente/editarDocumento';
		}else{
			CambioElIdCompra(item.compra.id);
			CambioMostrarModalDescarga(true);
		}
	}

	const descargarPDF = async function(){
		window.open('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/firebase/pdf.php?idCompra='+elIdCompra, '_blank');
	}

	const descargarWORD = async function(){
		window.open('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/firebase/word.php?idCompra='+elIdCompra, '_blank');
	}

	const accionServicio = async function(item, tipo){
		if (item.compra.data.pagado == false) {
			Swal.fire({
			  title: 'No puede editar hasta que cancele el servicio.',
			  icon: 'info',
			});
		}else if (item.compra.data.caracteristicas.editado == false) {
			await localStorage.setItem('idAgenda', JSON.stringify({
				idAgenda: item.compra.id,
				tipo: tipo,
			}));
			window.location.href = '/cliente/agendar';
		}else{
			CambioMostrarModalAgenda(true);
			CambioTextoDataCita(item.compra.data.caracteristicas.fecha)
			CambioTextoDataLink(item.compra.data.caracteristicas.linkmeet);
		}
	}

	const comprarServicio = async function(idServicio){

		let usuario = await localStorage.getItem('usuarioLegalizarlo');

		if (usuario != null) {
			let dataUser = JSON.parse(usuario);

			if (dataUser.datosActualizados) {

				let data = {
					idProducto: idServicio,
					tipoDeCompra: 'serviciosPersonalizados',
				};
				await localStorage.setItem('carrito', JSON.stringify(data));

				window.location.href = '/carrito';
			}else{

				Swal.fire({
			        title: 'Actualice sus datos antes de realizar una compra.',
			        icon: 'info',
			    });

			    verEstaPestana('misDatos');
			}
		}
	} 


	const cambiarEstadoPlan = async function(){

		desactivarPlan(idPlan).then(response =>{
			window.location.reload();
		})
	}

	const comprarPlan = async function(idPlan){

		let usuario = await localStorage.getItem('usuarioLegalizarlo');

		if (usuario != null) {
			let dataUser = JSON.parse(usuario);

			if (dataUser.datosActualizados) {

				let data = {
					idProducto: idPlan,
					tipoDeCompra: 'planes',
				};
				await localStorage.setItem('carrito', JSON.stringify(data));

				window.location.href = '/carrito';
			}else{

				Swal.fire({
			        title: 'Actualice sus datos antes de realizar un compra.',
			        icon: 'info',
			    });

			    verEstaPestana('misDatos');
			}
		}
	} 

	const selecionarPais = async function(){
	    let elPais = document.getElementById('pais').value;
	    CambioPais(elPais);
	}

	const actualizarDatosDelUsusario = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let data = {
	      nombre: nombre,
	      apellido: apellido,
	      direccion: direccion,
	      pais: pais,
	      codigoPostal: codigoPostal,
	      telefono: telefono,
	      identificacion: identificacion,
	      datosActualizados: true,
	    }; console.log(data);

	    if (data.nombre === '' || data.apellido === '' || data.direccion === '' || data.pais === '' || data.pais == 'Pais' || data.codigoPostal === '' || data.telefono === '' || data.identificacion === '') {
	      Swal.fire({
	        title: 'Complete todos los campos para continuar.',
	        icon: 'info',
	      });
	    }else if (isNaN(data.telefono) || isNaN(data.codigoPostal)) {
	      Swal.fire({
	        title: 'EL telefono y el codigo Postal deben ser numericos.',
	        icon: 'info',
	      });
	    }else{

	    	let usuario = await localStorage.getItem('usuarioLegalizarlo');

	    	if (usuario != null) {

	    		let dataUser = JSON.parse(usuario);

	    		terminarActualizacion(data, dataUser.uid).then(response =>{
			        if (response != false) {
			          window.location.reload();
			        }else{
			          Swal.fire({
			            title: 'Lo sentimos pero hubo un error al tratar de procesar sus datos, intentelo mas tarde.',
			            icon: 'error',
			          });
			        }
			    })
	    	}
	    }
	}

	const verFactura = async function(item){
		console.log(item);

		window.open('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/firebase/factura.php?'+
	        'numeroFactura='+item.compra.id+
	        '&fechaMostrar='+item.compra.data.fechaMostrar+
	        '&nombre='+nombre+
	        '&apellido='+apellido+
	        '&email='+email+
	        '&direccion='+direccion+
	        '&pais='+pais+
	        '&codigoPostal='+codigoPostal+
	        '&telefono='+telefono+
	        '&identificacion='+identificacion+
	        '&nombreProducto='+item.producto.data.nombre+
	        '&costoProducto='+item.compra.data.costoSinIva+
	        '&total='+item.compra.data.costo+
	        '&tipoPago='+item.compra.data.tipoPago,
	        '_blank'
	    );
	}

	const verServicioPersonalizado = async function(idProducto){

		await localStorage.setItem('verServicioPersonalizado', idProducto);

		window.location.href = '/abogados';
	}

	return(
		<div class="divCliente">
			<section class="seccion2Docs divCliente">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<div class="categorias">
								<span id="misDatos" onClick={() => verEstaPestana('misDatos')} class="categoria activa">Mis datos</span>
								| 
								<span id="misDocumentos" onClick={() => verEstaPestana('misDocumentos')} class="categoria">Mis documentos</span>
								| 
								<span id="misReuniones" onClick={() => verEstaPestana('misReuniones')} class="categoria">Mis servicios</span>
								| 
								<span id="misReunionesPersozonalizados" onClick={() => verEstaPestana('misReunionesPersozonalizados')} class="categoria">Mis servicios persozonalizados</span>
								| 
								<span id="misPlanes" onClick={() => verEstaPestana('misPlanes')} class="categoria">Mis planes</span>
								| 
								<span onClick={cerrarSesion} class="categoria">Cerrar Sesión</span>
							</div>
						</div>
					</div>
					<div class="row" id="misDatos1">
		              <div class="col-sm">
		                <div class="formaDePago paddingTerminarPago">

		                  <h6 class="tituloIndicacion elCienPorCiento">Información de usuario</h6>

		                  
	                      <div class="input-group mb-3">
	                        <input id="nombre" onChange={() => CambioNombre(document.getElementById('nombre').value)} value={nombre} type="text" class="form-control" placeholder="Nombre" aria-label="Buscar" aria-describedby="basic-addon1" />
	                      </div>
	                      <div class="input-group mb-3">
	                        <input id="apellido" onChange={() => CambioApellido(document.getElementById('apellido').value)} value={apellido} type="text" class="form-control" placeholder="Apellido" aria-label="Buscar" aria-describedby="basic-addon1" />
	                      </div>
	                      <div class="input-group mb-3">
	                        <input readOnly={true} value={email} id="email" type="text" class="form-control fondoGris" placeholder="Email" aria-label="Buscar" aria-describedby="basic-addon1" />
	                      </div>
	                      <div class="input-group mb-3">
	                        <input id="direccion" onChange={() => CambioDireccion(document.getElementById('direccion').value)} value={direccion} type="text" class="form-control" placeholder="Direccion" aria-label="Buscar" aria-describedby="basic-addon1" />
	                      </div>
	                      <div class="input-group mb-3">
	                        <select onChange={selecionarPais} id="pais" class="form-select" aria-label="Default select example">
	                          <option value="">{pais}</option>
	                          {paises.countries.map((item) =>(
	                            <option value={item.name_es}>{item.name_es}</option>
	                          ))}
	                        </select>
	                      </div>
	                      <div class="input-group mb-3">
	                        <input id="codigoPostal" onChange={() => CambioCodigoPostal(document.getElementById('codigoPostal').value)} value={codigoPostal} type="text" class="form-control" placeholder="Codigo Postal" aria-label="Buscar" aria-describedby="basic-addon1" />
	                      </div>
	                      <div class="input-group mb-3">
	                        <input id="telefono" readOnly={true} value={telefono} type="text" class="form-control" placeholder="Telefono" aria-label="Buscar" aria-describedby="basic-addon1" />
	                      </div>
	                      <div class="input-group mb-3">
	                        <input id="identificacion" onChange={() => CambioIdentificacion(document.getElementById('identificacion').value)} value={identificacion} type="text" class="form-control" placeholder="N° de identificacion fiscal" aria-label="Buscar" aria-describedby="basic-addon1" />
	                      </div>

	                      <button onClick={actualizarDatosDelUsusario} class="terminarPago">Guardar</button>
	                      
		                </div>
		              </div>
		            </div>
		            <div class="row" id="misDatos2">
		              <div class="col-sm">
		                <div class="divAccionPago">
		                  <button onClick={mostrarModalPassword} class="terminarPago">Cambiar Contraseña</button>
		                </div>
		              </div>
		            </div>
		            <div class="row" id="misDocumentos1" class="ocutarHomeCliente">
		              	<div class="col-sm">
		              		<br />
							<table class="table table-dark table-striped">
								<thead>
									<tr>
										<th scope="col">Nombre</th>
										<th scope="col">Descripción</th>
										<th scope="col">Categoría</th>
										<th scope="col">Editado</th>
										<th scope="col">Pagado</th>
										<th scope="col">Acciones</th>
									</tr>
								</thead>
								<tbody>
									{documentos.map((item) =>(
										<tr>
											<td>{item.producto.data.nombre}</td>
											<td>{item.producto.data.descripcionCortar}</td>
											<td>{item.producto.data.categoria}</td>
											<td>
												<EstadoItem
													estado={item.compra.data.caracteristicas.editado}
												/>
											</td>
											<td>
												<EstadoItem
													estado={item.compra.data.pagado}
												/>
											</td>
											<td>
												<BotonAccionDocumento
													estado={item.compra.data.caracteristicas.editado}
													accion={() => accionDocumento(item)}
												/>
												{/*<button onClick={() => verFactura(item)} class="btn btn-info">Factura</button>*/}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
		            </div>

		            <div class="row" id="misReuniones1" class="ocutarHomeCliente">
		              	<div class="col-sm">
		              		<br />
							<table class="table table-dark table-striped">
								<thead>
									<tr>
										<th scope="col">Nombre</th>
										<th scope="col">Descripción</th>
										<th scope="col">Agendado</th>
										<th scope="col">Pagado</th>
										<th scope="col">Fecha</th>
										<th scope="col">Acciones</th>
									</tr>
								</thead>
								<tbody>
									{reuniones.map((item) =>(
										<tr>
											<td>{item.producto.data.nombre}</td>
											<td>{item.producto.data.descripcionCortar}</td>
											<td>
												<EstadoItem
													estado={item.compra.data.caracteristicas.editado}
												/>
											</td>
											<td>
												<EstadoItem
													estado={item.compra.data.pagado}
												/>
											</td>
											<td>
												{item.compra.data.caracteristicas.fecha}
											</td>
											<td>
												<BotonAccionServicio
													estado={item.compra.data.caracteristicas.editado}
													accion={() => accionServicio(item, 'servicios')}
												/>
												{/*<button onClick={() => verFactura(item)} class="btn btn-info">Factura</button>*/}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
		            </div>

		            <div class="row" id="misReunionesPersozonalizados1" class="ocutarHomeCliente">
		              	<div class="col-sm">
		              		<br />
							<table class="table table-dark table-striped">
								<thead>
									<tr>
										<th scope="col">Nombre</th>
										<th scope="col">Descripción</th>
										<th scope="col">Agendado</th>
										<th scope="col">Pagado</th>
										<th scope="col">Fecha</th>
										<th scope="col">Acciones</th>
									</tr>
								</thead>
								<tbody>
									{reunionesPerzonalizados.map((item) =>(
										<tr>
											<td>{item.producto.data.nombre}</td>
											<td>{item.producto.data.descripcionCortar}</td>
											<AccionServicioPersonalizado
												item={item}
												accionPagar={() => comprarServicio(item.producto.id)}
												accionGestionar={() => accionServicio(item, 'serviciosPersonalizados')}
												accionFactura={() => verFactura(item)}
												verServicioPersonalizado={() => verServicioPersonalizado(item.producto.id)}
											/>
										</tr>
									))}
								</tbody>
							</table>
						</div>
		            </div>

		            <div class="row" id="misPlanes1" class="ocutarHomeCliente">
		              	<div class="col-sm">
		              		<br />
							<table class="table table-dark table-striped">
								<thead>
									<tr>
										<th scope="col">Tipo</th>
										<th scope="col">Fecha de vencimiento</th>
										<th scope="col">Pagado</th>
										<th scope="col">Activo</th>
										<th scope="col">Acciones</th>
									</tr>
								</thead>
								<tbody>
									{planes.map((item) =>(
										<tr>
											<td>{item.producto.data.nombre}</td>
											<td>{item.compra.data.caracteristicas.fechaExpiracion}</td>
											<td>
												<EstadoItem
													estado={item.compra.data.pagado}
												/>
											</td>
											<td>
												<EstadoItem
													estado={item.compra.data.caracteristicas.activo}
												/>
											</td>
											<td>
												<BotonAccionPlan
													estado={item.compra.data.caracteristicas.activo}
													accion={() => {

														if (item.compra.data.caracteristicas.activo) {
															CambioMostrarModalPlan(true);
															CambioIdPlan(item.compra.id);
														}else{

															comprarPlan(item.compra.data.idProducto);
														}
													}}
												/>
												<button onClick={() => verFactura(item)} class="btn btn-info">Factura</button>	
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
		            </div>
				</div>
			</section>
			<Footer />

			<ModalAuth mostrar={mostrarModalDescarga}>
				<div class="paddingCliente">

					<div class="divBotonCerrar">
						<span onClick={() => CambioMostrarModalDescarga(false)} class="xCerrar">X</span>
					</div>

					<h5 class="mensajePagoFinaliza">Descargalo en el formato que desees</h5>

		            <div class="botonesDescarga">
		             	<img onClick={descargarPDF} class="botonDescarga" src={pdf} />
		             	<img onClick={descargarWORD} class="botonDescarga" src={word} />
		            </div>
	            </div>
			</ModalAuth>

			<ModalAuth mostrar={mostrarModalAgenda}>
				<div class="paddingCliente">
					<div class="divBotonCerrar">
						<span onClick={() => CambioMostrarModalAgenda(false)} class="xCerrar" style={{color: 'red',}}>X</span>
					</div>
					<div class="row">
		              <div class="col-sm">
		                <div class="datosFinalesCita">
		                  <h6 class="textoCita">{textoDataCita}</h6>
		                  <h6 class="textoCita">Link reunión:{textoDataLink}</h6>
		                </div>
		              </div>
		            </div>
	            </div>
			</ModalAuth>

			<ModalCambiarPassword mostrar={mostrarCambioPassword}>
				<div class="container" id="formAuth">
					<div class="row">
						<div class="col-sm alinearConImgLogin text-center">
							<div class="divBotonCerrar">
								<span onClick={() => mostrarModalPassword(false)} class="xCerrar">X</span>
							</div>
							<h1 class="tituloLogin">Cambiar contraseña</h1>
							<input id="nuevaPassword" placeholder="Nueva contraseña" type="password" onChange={() => LaNuevaPassword(document.getElementById('nuevaPassword').value)} class="inputLogin marginAuto" />
							<input id="repitaPassword" placeholder="Repita la nueva contraseña" type="password" onChange={() => LaPasswordRepetida(document.getElementById('repitaPassword').value)} class="inputLogin marginAuto" />
							<input id="actualPassword" placeholder="Contraseña actual" type="password" onChange={() => LaActualPassword(document.getElementById('actualPassword').value)} class="inputLogin marginAuto" />
							<button onClick={() => procesoCambioPassword()} class="botonSesionEmail">Actualizar</button>
						</div>
					</div>
				</div>
			</ModalCambiarPassword>

			<ModalAuth mostrar={mostrarModalPlan}>
				<h5 class="subTituloLogin">¿Desea desactivar este plan?</h5>
				<h6 class="subTituloLogin">
					Si lo hace tendra que contratarlo de nuevamente<br/>
					en caso de querer activarlo otra vez tendras que comprarlo de nuevo.
				</h6>
                <div style={{textAlign: 'center',}}>
                    <button class="btn btn-primary" onClick={cambiarEstadoPlan}>Si</button>
                    <button onClick={() => {
                      CambioMostrarModalPlan(false);
                      CambioIdPlan('');
                    }} style={{margin: 5,}} class="btn btn-danger">No</button>
                </div>
			</ModalAuth>
		</div>
	);
}

export default ClienteHomeScreen;
