import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function losUsuarios(rol){

	let usuarios = [];
	let parametro = '';

	if(rol == 'admin'){
		parametro = donde("rol", "==", 'cliente');
	}else if(rol == 'cliente'){
		parametro = donde("rol", "==", true);
	}

	const q = buscar(coleccion(db, "usuarios"), parametro);

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		usuarios.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return usuarios;
}