import React, {useState, useEffect} from 'react';
import './home-styles.css';
import {
	imgHomeSeccion1, 
	u, 
	imgHomeSeccion2, 
	imgDoc, 
	imgServi, 
	imgInmediates, 
	imgClaridad, 
	imgCostoBeneficio, 
	imgSeguridadLegal, 
	imgTodoIncluido, 
	imgCompromiso, 
	imgHombreEmpresario, 
	slider1, 
	slider2, 
	slider3, 
	slider4,
	carrusel1,
	carrusel2,
	carrusel3,
	carrusel4,
	carrusel5,
	carrusel6,
	carrusel7,
	carrusel8,
	carrusel9,
	carrusel10,
} from '../../assets/imgs.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Footer from '../../components/footer/footer';
import {buscarDocumentos} from '../../backend/documentos/documentos.js';
import {buscarServicios} from '../../backend/servicios/servicios';

function HomeScreen(){

	const [busquedaProductos, CambioBusquedaProductos] = useState(false);
	const [productos, CambioProductos] = useState([]);

	useEffect(() => {
		verRol();

		if (busquedaProductos == false) {

			consultarProductos();
			CambioBusquedaProductos(true);
		}
	});

	const verRol = async function(){
	    let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
	       	let dataUser = JSON.parse(usuario);
	        if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {
	        	window.location.href = '/admin/home';
	        }
	    }
	}

	const consultarProductos = async function(){

		let losProductos = [];

		let documentos = await buscarDocumentos('cliente');
		let servicios = await buscarServicios('cliente', '');

		documentos.forEach(element =>{
			if (element.data.solicitado == true) {

				losProductos.push({
					producto: element,
					tipo: 'documento',
				});
			}
		})

		servicios.forEach(element =>{
			if (element.data.solicitado == true) {

				losProductos.push({
					producto: element,
					tipo: 'documento',
				});
			}
		})

		CambioProductos(losProductos);
	}

	const verProducto = async function(item){ 

		await localStorage.setItem('idMasSolicitado', item.producto.id);

		if (item.tipo == 'documento') {

			window.location.href = '/documentos';
		}else if (item.tipo == 'servicio') {

			window.location.href = '/abogados';
		}
	}

	return(
		<div class="containerHome">
			<section class="seccion1">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h1 class="tituloSeccion1">Somos la Plataforma Online donde los profesionales formalizan su negocio.</h1>
							<h2 class="tituloSeccion2">Documentos y trámites legales, sin tanto rollo.</h2>
						</div>
						<div class="px-0 col-sm justify-content-end d-flex">
							<img src={imgHomeSeccion1} class="imgHomeSeccion1" />
						</div>
					</div>
				</div>
				<div class="container-shape" data-negative="true">
					<svg xmlns="http://www.w3.org/2000/svg" class="shape" viewBox="0 0 1450 100" preserveAspectRatio="none"><path class="" d="M1450,60C76,66.3,0.8,9.8,00,0v100l1450,0V1C1450,5,1450,60,190,90z"></path></svg>		</div>
			</section>
			<section class="seccion2">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<img src={imgHomeSeccion2} class="imgHomeSeccion2 img-fluid w-100 p-5" />
						</div>
						<div class="col-sm flex-column d-flex align-items-start justify-content-center position-relative">
							<svg class="simbolo1" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><defs></defs><g id="Group_99" data-name="Group 99"><g id="Group_98" data-name="Group 98"><path id="Path_167" data-name="Path 167" class="cls-1" d="M481,170c-.27-.66-.52-1.33-.81-2H344.89l.4.4a11.18,11.18,0,0,1,1.54,1.61,125.1,125.1,0,0,1-90.67,211.15h-.25A125.14,125.14,0,0,1,130.77,256v-.07A125.17,125.17,0,0,1,344,167.13V31.85a239.17,239.17,0,0,0-88-16.63h-.18C123,15.21,15.23,123,15.22,255.88v0C15.13,388.85,122.83,496.7,255.78,496.78h.13c133,.07,240.81-107.68,240.87-240.64v-.23A240,240,0,0,0,481,170Z"/></g></g></svg>
							<h2 class="preguntaSeccion2">¿Qué nos mueve?</h2>
							<p class="parrafoSeccion2">Queremos acompañar a empresarios como tú: que toman en serio su negocio y que han decidido formalizarlo. Para ello, construimos Legalizarlo. Una plataforma online donde te facilitamos de manera rápida y segura, documentos, trámites y asesoría legal para tu negocio. </p>
							<h5 class="letrasAzuleSeccion2">Nos encargamos de la tramitología y las frustraciones, para que tú te enfoques en crecer.</h5>
							<div class="separadorSeccion"></div>
						</div>
					</div>
				</div>
			</section>
			<section class="seccion3">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h2 class="preguntaSeccion3">¿Cómo podemos ayudarte?</h2>
						</div>
					</div>
					<div class="row">
						<div class="col-sm centrar">
							<div class="producto menosRelleno">
								<img src={imgDoc} class="imgProducto" />
								<h3 class="tituloProducto">Descarga <br/> Documentos Legales</h3>
								<p class="parrafoProducto marginInferiorProducto">Encuentra el documento que necesitas, descárgalo y edítalo. Nunca fue tan fácil realizar un contrato, convenio o acuerdo con la información legal que necesitas.</p>
								<button class="btn btn-primary botonLegalizarlo">Ver Documentos</button>
							</div>
							<div class="producto menosRelleno">
								<img src={imgServi} class="imgProducto" />
								<h3 class="tituloProducto">Contacta <br/> a un abogado</h3>
								<p class="parrafoProducto marginInferiorProducto" style={{lineHeight: 1.2,}}>Ya sea una consulta rápida y puntual o todo el seguimiento a tus trámites legales. Aquí encontrarás una selección de nuestros servicios todo-en-uno, sin costos ocultos ni letras pequeñas.</p>
								<button class="btn btn-primary botonLegalizarlo">Ver Servicios</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="seccion4">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h2 class="preguntaSeccion4 centrar">¿Por qué Legalizarlo?</h2>
						</div>
					</div>
					<div class="row">
						<div class="col-sm centrar">
							<div class="porques">
								<img src={imgInmediates} class="imgPorque" />
								<h3 class="tituloPorque">Inmediatez</h3>
								<p class="parrafoPorque">Descarga y personaliza, al instante, los documentos legales más importantes que usarás en tu vida empresarial.</p>
							</div>
							<div class="porques">
								<img src={imgClaridad} class="imgPorque" />
								<h3 class="tituloPorque">Claridad</h3>
								<p class="parrafoPorque">Deja la “tramitología” en nuestras manos. Conocemos cada detalle legal que tu empresa necesita.</p>
							</div>
							<div class="porques">
								<img src={imgCostoBeneficio} class="imgPorque" />
								<h3 class="tituloPorque">Costo-Beneficio</h3>
								<p class="parrafoPorque">Nos interesa tu crecimiento… en serio. Y por ello te ofrecemos servicios de calidad, sin precios excesivos.</p>
							</div>
							<div class="porques">
								<img src={imgSeguridadLegal} class="imgPorque" />
								<h3 class="tituloPorque">Seguridad Legal</h3>
								<p class="parrafoPorque">Todos nuestros trámites y documentos, están realizados y evaluados por abogados expertos en la materia.</p>
							</div>
							<div class="porques">
								<img src={imgTodoIncluido} class="imgPorque" />
								<h3 class="tituloPorque">Todo incluido</h3>
								<p class="parrafoPorque">Te ofrecemos una selección de servicios personalizados que incluyen todo lo que necesitas. Sin gastos ocultos ni choro mareador.</p>
							</div>
							<div class="porques">
								<img src={imgCompromiso} class="imgPorque" />
								<h3 class="tituloPorque">Compromiso</h3>
								<p class="parrafoPorque">Te ofrecemos una selección de servicios personalizados que incluyen todo lo que necesitas. Sin gastos ocultos ni choro mareador.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="seccion5">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h2 class="preguntaSeccion5 centrar">En corto, lo más solicitado</h2>
						</div>
					</div>

					<div class="row">
						<div class="col-sm" style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap',}}>
							{productos.map((item) => (
								<div class="documento">
									<img class="imgServicio" src={item.producto.data.foto} />
									<div class="tituloServicio">
										<h3 class="tituloDocH4">{item.producto.data.nombre}</h3>
									</div>
									<div class="infoDoc">
										<p class="descripcionCorta">{item.producto.data.descripcionCortar}</p>
										<p class="precio">${item.producto.data.precio} MXN <br/> <span style={{fontSize: 16, fontFamily: 'AvenirRegular',}}>Precio Incluye IVA 🙂</span></p>
										<buttom onClick={() => verProducto(item)} class="btn btn-primary colorRosa">Ver {item.tipo}</buttom>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
			<section class="seccion6">
				<div class="container">
					<div class="row">
						<div class="col-sm position-relative">
							<svg class="symbol2 symbol2Ocutar" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250"><defs></defs><g id="Group_214" data-name="Group 214"><circle id="Ellipse_61" data-name="Ellipse 61" class="cls-2" cx="70.12" cy="70.12" r="5.12"/><circle id="Ellipse_62" data-name="Ellipse 62" class="cls-2" cx="70.12" cy="143.29" r="5.12"/><circle id="Ellipse_63" data-name="Ellipse 63" class="cls-2" cx="70.12" cy="106.71" r="5.12"/><circle id="Ellipse_64" data-name="Ellipse 64" class="cls-2" cx="70.12" cy="179.88" r="5.12"/><circle id="Ellipse_65" data-name="Ellipse 65" class="cls-2" cx="106.71" cy="70.12" r="5.12"/><circle id="Ellipse_66" data-name="Ellipse 66" class="cls-2" cx="106.71" cy="143.29" r="5.12"/><circle id="Ellipse_67" data-name="Ellipse 67" class="cls-2" cx="106.71" cy="106.71" r="5.12"/><circle id="Ellipse_68" data-name="Ellipse 68" class="cls-2" cx="106.71" cy="179.88" r="5.12"/><circle id="Ellipse_69" data-name="Ellipse 69" class="cls-2" cx="143.29" cy="70.12" r="5.12"/><circle id="Ellipse_70" data-name="Ellipse 70" class="cls-2" cx="143.29" cy="143.29" r="5.12"/><circle id="Ellipse_71" data-name="Ellipse 71" class="cls-2" cx="143.29" cy="106.71" r="5.12"/><circle id="Ellipse_72" data-name="Ellipse 72" class="cls-2" cx="143.29" cy="179.88" r="5.12"/><circle id="Ellipse_73" data-name="Ellipse 73" class="cls-2" cx="179.88" cy="70.12" r="5.12"/><circle id="Ellipse_74" data-name="Ellipse 74" class="cls-2" cx="179.88" cy="143.29" r="5.12"/><circle id="Ellipse_75" data-name="Ellipse 75" class="cls-2" cx="179.88" cy="106.71" r="5.12"/><circle id="Ellipse_76" data-name="Ellipse 76" class="cls-2" cx="179.88" cy="179.88" r="5.12"/></g></svg>
							<p class="preguntaSeccion6">¿Ya la utilizas?</p>
							<h2 class="tituloSeccion6">Firma Electrónica</h2>
							<p class="parrafoSeccion6">Firma documentos desde cualquier lugar y dispositivo, de manera inmediata y con mayor seguridad y certidumbre legal.</p>
							<a href="#" class="btn btn-primary botonLegalizarlo botonIzquierda">Conocer más</a>
						</div>
						<div class="col-sm position-relative">
							<img src={u} class="ue" />
							<img src={imgHombreEmpresario} class="hombreEmpresario" />
						</div>
					</div>
				</div>
			</section>
			<section class="seccion7">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h2 class="tituloSeccion7">Ellos ya son <br/> nuestro amigo fiel :)</h2>
						</div>
					</div>
					<div class="row">
						<div class="col-sm ocultarCarrusel" style={{paddingTop: '5%',}}>
							<Carousel
								showThumbs={false}
								showArrows={false}
								autoPlay={true}
								infiniteLoop={true}
							>
								<div><img src={slider1} /></div>
								<div><img src={slider2} /></div>
								<div><img src={slider3} /></div>
								<div><img src={slider4} /></div>
							</Carousel>
						</div>

						<div class="col-sm ocultarCarruselEscritorio" style={{paddingTop: '5%',}}>
							<Carousel
								showThumbs={false}
								showArrows={false}
								autoPlay={true}
								infiniteLoop={true}
							>
								<div><img src={carrusel1} /></div>
								<div><img src={carrusel2} /></div>
								<div><img src={carrusel3} /></div>
								<div><img src={carrusel4} /></div>
								<div><img src={carrusel5} /></div>
								<div><img src={carrusel6} /></div>
								<div><img src={carrusel7} /></div>
								<div><img src={carrusel8} /></div>
								<div><img src={carrusel9} /></div>
								<div><img src={carrusel10} /></div>
							</Carousel>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}

export default HomeScreen;
