import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';
import axios from 'axios';

export async function subirImagenServicio(id, imagen){

	const storageRef = referencia(almacenamiento, 'assets/serviciosPersonalizados/'+id+'/'+id);

	await subirArchivo(storageRef, imagen);

	let url = await obtenerURL(storageRef);

	return url;  
}
export async function guardarServicio(id, data){
	await set(documento(db, "serviciosPersonalizados", id), data);

	let usuariosSeleccionados = data.usuariosSeleccionados;

	for (let usuario of data.usuarios) {

		await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/smsmasivos/serviciosPersonalizados.php', {
	      method: 'POST',
	      mode: 'no-cors',
	      headers:{
	        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
	      },
	      body: 'message=Legalizar te ha propuesto un servicio personalizado para tu proyecto '+usuario.nombre+'&numbers='+usuario.telefono+'&country_code=52'
	    }).then((response) =>{

	      return response.json();

	    }).then((json) =>{

	      console.log(json);

	    }).catch((error) =>{

	      console.log(error);

	    });
	    /* send mail */
	    let $message = 'Legalizarlo te ha propuesto un servicio personalizado para tu proyecto '+usuario.nombre;
	    data = {message:$message,email:usuario.email,subject:"Servicios Legalizarlo",type:"mail"};
	    //await fetch('http://legalizarlofirebase.com/backend-legalizarlo/citas/testsendgridcalendar.php', {
	    await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/citas/testsendgridcalendar.php', {
			method: 'POST',
		    headers: {
		        'Accept': 'application/json',
		        'Content-Type': 'application/json',
		    },
	        body: JSON.stringify(data)
	    }).then((response) =>{

	      return response.json();

	    }).then((json) =>{

	      console.log(json);

	    }).catch((error) =>{

	      console.log(error);

	    });









	}

	return true;
}

export async function buscarServicios(rol, uidAdmin){

	let servicios = [];
	let parametro = '';

	if(rol == 'admin'){
		parametro = donde("uidAdmin", "==", uidAdmin);
	}else if(rol == 'cliente'){
		parametro = donde("activo", "==", true);
	}

	const q = buscar(coleccion(db, "serviciosPersonalizados"), parametro);

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		servicios.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return servicios;
}

export async function editarEstadoServicio(id, estado){
	let activo = false;
	
	if(estado == false){
		activo = true;
	}

	const washingtonRef = documento(db, "serviciosPersonalizados", id);

	await actualizarDoc(washingtonRef, {
		activo: activo,
	});

	return true;
}

export async function validarHorarios(horarios, dia, mes, ano, idProducto){
	let citas = [];

	const q = buscar(coleccion(db, "citas"), donde("idProducto", "==", idProducto));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		citas.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	let citasDeHoy = [];

	for (let cita of citas) {
		if (cita.data.dia == dia && cita.data.mes == mes.posicion && cita.data.ano == ano) {
			citasDeHoy.push(cita);
		}
	}

	let horariosDisponibles = [];

	let cero = 0;

	for (let horario of horarios) {
		let disponibilidad = await validarDisponibilidad(citasDeHoy, horario);
		if (disponibilidad) {
			horariosDisponibles.push({
				disponibilidad: 'hay',
				horario: horario,
				id: idProducto+'-'+cero+'-dia',
				cero: cero,
				idProducto: idProducto,
				dia: dia, 
				mes: mes.posicion, 
				ano: ano,
			});
		}else{
			horariosDisponibles.push({
				disponibilidad: 'sin',
				horario: horario,
				id: idProducto+'-'+cero+'-dia',
				cero: cero,
				idProducto: idProducto,
				dia: dia, 
				mes: mes.posicion, 
				ano: ano,
			});
		}
		cero++;
	}

	return horariosDisponibles;
}

let validarDisponibilidad = async function(citas, horario){

	let disponibilidad = true;

	for (let cita of citas) {
		if (horario == cita.data.horario) {
			disponibilidad = false;
		}
	}

	return disponibilidad;
}

export async function guardarCita(uid, dataCita, textoDataCita, idCompra, id){

	let data = {
		disponibilidad: dataCita.disponibilidad,
		horario: dataCita.horario,
		idHorario: dataCita.id,
		idProducto: dataCita.idProducto,
		dia: dataCita.dia,
		mes: dataCita.mes,
		ano: dataCita.ano,
		uid: dataCita.uid,
		textoDataCita: textoDataCita,
		idCompra: idCompra,
	};

	await set(documento(db, "citas", id), data);

	const washingtonRef = documento(db, "compras", idCompra);

	await actualizarDoc(washingtonRef, {
		caracteristicas:{
			editado: true,
			fecha: textoDataCita,
			idCita: id,
		},
	});

	return true;
}

export async function consultaUsuarios(){

	let usuarios = [];

	const q = buscar(coleccion(db, "usuarios"), donde("rol", "==", "cliente"));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		usuarios.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return usuarios;
}

export async function consultarServicioPersonalizado(idProducto){

	const docRef = documento(db, "serviciosPersonalizados", idProducto);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}