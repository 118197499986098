import React, { useState, useEffect } from 'react';
import './citas-styles.css';
import {listarCitas} from '../../../backend/citas/citas';
import {ModalAuth} from '../../../components/modals/modals';
import {EstadoItem, BotonAccionDocumento, BotonAccionServicio, AdminBotonAccionServicio} from '../../../components/cliente/cliente';
import Swal from 'sweetalert2';

function AdminCitasScreen() {
  const [validarRol, CambioValidarRol] = useState(false);
  const [citas, CambioCitas] = useState([]);
  const [citasFinales, CambioCitasFinales] = useState([]);
  const [busquedaCitas, CambioBusquedaCitas] = useState(false);
  const [limiteCitas, CambioLimiteCitas] = useState(20);
  const [tipoBusqueda, CambioTipoBusqueda] = useState('agendadas');
  const [mostrarModalAgenda, CambioMostrarModalAgenda] = useState(false);
  const [textoDataCita, CambioTextoDataCita] = useState('');

  useEffect(() => {
    if (validarRol == false) {
      verRol();
    }
    if (busquedaCitas == false) {
      listarCitas(limiteCitas, tipoBusqueda).then(response =>{ console.log(response);
        CambioCitas(response);
        CambioCitasFinales(response);
        CambioBusquedaCitas(true);
      });
    }
  });

  const verRol = async function(){
      let usuario = await localStorage.getItem('usuarioLegalizarlo');
      if (usuario != null) {
          let dataUser = JSON.parse(usuario);
          if (dataUser.rol != 'admin' && dataUser.rol != 'subAdmin') {
            window.location.href = '/';
          }else{

            if (dataUser.rol == 'subAdmin') { 

                dataUser.restricciones.forEach(element =>{ console.log(element);
                  if (element == 'citas') {
                    window.location.href = '/';
                  }
                })
            }

            CambioValidarRol(true);
          }
      }else{
        window.location.href = '/';
      }
  }

  const accionServicio = async function(item){
    CambioMostrarModalAgenda(true);
    CambioTextoDataCita(item.compra.data.caracteristicas.fecha);
  }

  const verCitasPor = async function(){
    let tipoCita = document.getElementById('tipoCita').value; console.log(tipoCita);
    CambioTipoBusqueda(tipoCita);
    CambioBusquedaCitas(false);
  }

  const verMasCitas = async function(){
    let limite = limiteCitas + 20;
    CambioLimiteCitas(limite);
    CambioBusquedaCitas(false);
  }

  const verPorFecha = async function(){
    let fecha = document.getElementById('date').value;
    let citasDelDia = [];

    let elDia = parseInt(fecha.slice(8, 10)); 
    let elMes = parseInt(fecha.slice(6, 7)); 
    let elAno = parseInt(fecha.slice(0, 6)); 

    if (tipoBusqueda == 'agendadas') {
      for (let cita of citas) { 
        if (cita.cita.data.dia == elDia && cita.cita.data.mes == elMes -1 && cita.cita.data.ano == elAno) {
          citasDelDia.push(cita);
        }
      }
      CambioCitasFinales(citasDelDia);
    }
  }

  return(
      <div class="containerAdminDocs">
        <section class="seccion1AdminDocs">
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <select onChange={verCitasPor} class="form-select" aria-label="Default select example" id="tipoCita">
                  <option selected>Ver por...</option>
                  <option value="agendadas">Agendadas</option>
                  <option value="sinAgendar">Sin agendar</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <div class="form-group"><br/>
                  <label class="control-label" for="date">Ver por fecha</label>
                  <input onChange={verPorFecha} class="form-control" id="date" name="date" placeholder="MM/DD/YYY" type="date"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <br />
                <table class="table table-dark table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Usuario</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Agendado</th>
                      <th scope="col">Pagado</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {citasFinales.map((item) =>(
                      <tr>
                        <td scope="col">{item.usuario.data.nombre}</td>
                        <td scope="col">{item.producto.data.nombre}</td>
                        <td scope="col">
                          <EstadoItem
                            estado={item.compra.data.caracteristicas.editado}
                          />
                        </td>
                        <td scope="col">
                          <EstadoItem
                            estado={item.compra.data.pagado}
                          />
                        </td>
                        <td scope="col">
                          {item.compra.data.caracteristicas.fecha}
                        </td>
                        <td>
                          <AdminBotonAccionServicio
                            estado={item.compra.data.caracteristicas.editado}
                            accion={() => accionServicio(item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <button onClick={verMasCitas} class="btn btn-success">Ver mas</button>
              </div>
            </div>
          </div>
        </section>
        <ModalAuth mostrar={mostrarModalAgenda}>
          <div class="paddingCliente">
            <div class="divBotonCerrar">
              <span onClick={() => CambioMostrarModalAgenda(false)} class="xCerrar" style={{color: 'red',}}>X</span>
            </div>
            <div class="row">
              <div class="col-sm">
                <div class="datosFinalesCita">
                  <h6 class="textoCita">{textoDataCita}</h6>
                </div>
              </div>
            </div>
          </div>
        </ModalAuth>
      </div>
  );
}

export default AdminCitasScreen;