import React, {useState, useEffect} from 'react';
import './preguntasYRespuestas-styles.css';
import {consultarPreguntas} from '../../backend/preguntasYRespuestas/preguntasYRespuestas.js';

function PreguntasYRespuestas({idPagina}){

	const [listaDePreguntas, CambioListaDePreguntas] = useState([]);
	const [preguntaAbierta, CambioPreguntaAbierta] = useState('');
	const [busquedaPreguntas, CambioBusquedaPreguntas] = useState(false);

	useEffect(() => {
		listarPreguntas();
	});

	const listarPreguntas = async function(){

		if (busquedaPreguntas == false) {
			consultarPreguntas().then(response =>{

				let lasPreguntas = [];

				response.forEach(element =>{

					if (element.data.dataPagina.id == idPagina) {

						lasPreguntas.push(element);
					}
				})

				if (lasPreguntas.length == 0) {

					document.getElementById('seccionPreguntas').setAttribute('style', 'display: none;');
				}

				CambioListaDePreguntas(lasPreguntas);
			});

			CambioBusquedaPreguntas(true);
		}
	}

	const verPregunta = async function(item){
		let id = item.id;
		let masOMenosPregunta = document.getElementById('masOMenos-'+id);
		let respuesta = document.getElementById('respuesta-'+id);

		masOMenosPregunta.innerHTML = '-';
		masOMenosPregunta.classList.remove('masOMenosCerrar');
		masOMenosPregunta.classList.add('masOMenosAbierto');

		respuesta.classList.add('respuestaHeight');
		respuesta.innerHTML = item.data.respuesta;

		if (preguntaAbierta != '') {
			let id2 = preguntaAbierta;
			let masOMenosPregunta2 = document.getElementById('masOMenos-'+id2);
			let respuesta2 = document.getElementById('respuesta-'+id2);

			masOMenosPregunta2.innerHTML = '+';
			masOMenosPregunta2.classList.remove('masOMenosAbierto');
			masOMenosPregunta2.classList.add('masOMenosCerrar');

			respuesta2.innerHTML = '';
			respuesta2.classList.remove('respuestaHeight');

			CambioPreguntaAbierta(id);
		}else{
			CambioPreguntaAbierta(id);
		}
	}

	return(
		<>
			<div class="containerPreguntasYRespuestas">
				<h1 class="tituloPreguntasYRespuestas">Preguntas Frecuentes</h1>
				<div class="preguntas">
					{listaDePreguntas.map((item) =>(
						<div class="pregunta">
							<h3 onClick={() => verPregunta(item)} class="tituloPregunta"><span class="masOMenosCerrar" id={'masOMenos-'+item.id}>+</span> {item.data.pregunta}</h3>
							<p class="respuesta" id={'respuesta-'+item.id}></p>
						</div>
					))}
				</div>
			</div>
		</>
	);
}

export default PreguntasYRespuestas;