import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';
import {buscarProducto} from '../carrito/carrito.js';
import {meses, dias} from '../../assets/datosCalendario';

export async function listarCitas(limite, tipo){
	let compras = [];
	let datosCompras = [];
	let citas = [];

	let q = buscar(coleccion(db, "compras"), donde("tipo", "==", "servicios"));
	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		compras.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	for (let compra of compras) {
		let dataProducto = {
			tipoDeCompra: compra.data.tipo,
			idProducto: compra.data.idProducto,
		};
		let producto = await buscarProducto(dataProducto);
		if (producto != false) {
			datosCompras.push({
				compra: compra,
				producto: producto,
			});
		}
	}

	if (tipo == 'agendadas') {
		for (let datoCompra of datosCompras) {
			if (datoCompra.compra.data.caracteristicas.editado) {
				let cita = await laCita(datoCompra.compra.data.caracteristicas.idCita);
				let usuario = await elUsuario(datoCompra.compra.data.uid);
				if (cita != false && usuario != false) {

					let fecha = new Date(meses[cita.data.mes].nombre+' '+cita.data.dia+', '+cita.data.ano+' 00:00:00');

					citas.push({
						compra: datoCompra.compra,
						producto: datoCompra.producto,
						cita: cita,
						usuario: usuario,
						fecha: fecha,
					});
				}
			}
		}

		citas.sort(function(a, b) { 
			a = a.fecha; 
			b = b.fecha; 
			return a>b ? -1 : a<b ? 1 : 0; 
		});

		return citas;
	}else{
		for (let datoCompra of datosCompras) {
			if (datoCompra.compra.data.caracteristicas.editado == false) {
				let usuario = await elUsuario(datoCompra.compra.data.uid);
				if (usuario != false) {
					citas.push({
						compra: datoCompra.compra,
						producto: datoCompra.producto,
						cita: '',
						usuario: usuario,
						fecha: 'Pendiente',
					});
				}
			}
		}

		return citas;
	}
}

async function laCita(idCita){
	const docRef = documento(db, "citas", idCita);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}

async function elUsuario(uid){
	const docRef = documento(db, "usuarios", uid);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}