import React, { useState, useEffect } from 'react';
import FormCupons from './FormCupons';
import { useParams, useLocation } from 'react-router-dom';

const EditCupons = (props) => {
  const { id } = useParams();
  /*const libroParaEditar = libros.find((libro) => libro.id === id);*/
  const location = useLocation();

  const handleOnSubmit = (editar) => {
    //console.log("ejecuté esta función");
  };

  return (
    <div>
      <FormCupons cupon={location.state.data} handleOnSubmit={handleOnSubmit} typeaction ="edit" idedit={id}/>
    </div>
  );
};

export default EditCupons;
