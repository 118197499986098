import React, {useState, useEffect} from 'react';
import './terminosYCondiciones-styles.css';
import Footer from '../../components/footer/footer';
import {
	consultarTerminosCondiciones,
} from '../../backend/preguntasYRespuestas/preguntasYRespuestas.js';

function TerminosYCondicionesScreen(){

	const [busquedaTerminosYCondiciones, CambioBusquedaTerminosYCondiciones] = useState(false);
	const [terminosYCondiciones, CambioTerminosYCondiciones] = useState('');

	useEffect(() => {

		if (busquedaTerminosYCondiciones == false) {

			consultarTerminosCondiciones().then(response =>{

				if (response != false) {

					CambioTerminosYCondiciones(response.data.texto);
					document.getElementById('texto').innerHTML = response.data.texto;
				}
			});

			CambioBusquedaTerminosYCondiciones(true);
		}
	});

	return(
		<>
			<section class="seccion4Docs">
				<div class="container">
					<div class="row">
						<div class="col-sm" id="texto" style={{paddingTop: '10%', paddingBottom: '10%',}}>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default TerminosYCondicionesScreen;