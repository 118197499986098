export const logoMenu = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Flogo-legalizarlo-blanco.svg?alt=media&token=6e2c3943-760f-4c06-b6df-263eb9a4e1f7'; 

export const imgUser = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Fico-usuario.svg?alt=media&token=93dbf72a-90ea-4a41-975c-28a503117d35';

export const imgLogin = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Flogin.webp?alt=media&token=97c82d98-899d-44cf-8776-220db487e949';

export const imgHomeSeccion1 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Flegalizarlo-mujer-empresaria-2.png?alt=media&token=f37aaad7-956e-4a74-96de-415cc9d40f6f&_gl=1*1g9v1gc*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NjUxMjI2Mi4yMC4xLjE2ODY1MTI1MDcuMC4wLjA.';

export const imgHomeSeccion2 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Flegalizarlo-hombre-empresario.png?alt=media&token=ab80a44f-ee3e-4d13-b058-6b0270cb5bb2';

export const imgDoc = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fico-documentos.svg?alt=media&token=e37300e5-129c-4b23-9c64-dec0b0c5b538';

export const imgServi = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fico-abogados.svg?alt=media&token=82aeb079-5f25-462a-b0bc-ff0199565eb0';

export const imgInmediates = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fico-inmediatez.svg?alt=media&token=eaace7a8-eb99-40f4-a3c2-3af242a1ec68';

export const imgClaridad = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fico-claridad.svg?alt=media&token=29a80e06-3a86-4dc8-820b-edd7582baa90';

export const imgCostoBeneficio = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fico-costo-beneficio.svg?alt=media&token=2381cbf0-d22d-41d3-b8b4-3368acad8e0b';

export const imgSeguridadLegal = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fico-seguridad-legal.svg?alt=media&token=51ce55a1-c9e1-4e7a-a260-c8d5f418af09';

export const imgTodoIncluido = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fico-todo-incluido.svg?alt=media&token=7d129474-c549-412d-b3aa-9190eba3b9fd';

export const imgCompromiso = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fico-compromiso.svg?alt=media&token=b975add5-c929-49e9-8935-4f207de186b7';

export const imgHombreEmpresario = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Flegalizarlo-hombre-empresario-2.png?alt=media&token=d939fecf-e7fb-489c-ae8d-55f51941a893';

export const slider1 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fslider1.png?alt=media&token=67fd43c5-dad2-49c4-9fe2-acd87fd305eb';

export const slider2 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fslider2.png?alt=media&token=6a13f0b2-3592-4e07-91da-f34caa4772d7';

export const slider3 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fslider3.png?alt=media&token=374199be-88d6-4c5e-8ede-4a23661614db';

export const slider4 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fhome%2Fslider4.png?alt=media&token=b0f3bb27-90c1-4545-b083-8a0a943b59fc';

export const imgAdminMenu = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2FiconoMenuAdmin.png?alt=media&token=a60298ed-d000-452a-b5d0-6e5e75414cb6';

export const inicio = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Finicio.png?alt=media&token=8aba788a-f1a9-4a0a-b593-d3a7d6c5d0f8';

export const documentos = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Fdocumento.png?alt=media&token=c3fee51f-b247-4fa6-949e-35eccc89154c';

export const firmas = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Ffirmas.png?alt=media&token=47dd18c9-b977-4e2f-ad3e-e0ee234c2ea5';

export const categorias = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Fcategorias.png?alt=media&token=7f3fa67d-1cd1-41cc-b5e2-6da7258c1a2f';

export const ordenes = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Fdocumento.png?alt=media&token=c3fee51f-b247-4fa6-949e-35eccc89154c';

export const salir = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Fsalir.png?alt=media&token=922a42bd-0958-4020-bcd0-fac48a267c77';

export const logoTransparente = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Ffooter%2Flogo_menu_legalizarlo%20(1).png?alt=media&token=3028cdf6-6b0a-4804-a265-0ed7d6749ba5';

export const facebookLegalizarlo = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Ffooter%2Fico-facebook.png?alt=media&token=7fc85776-4151-4a2f-819b-dae14b2075bb';

export const instagramLegalizarlo = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Ffooter%2Fico-instagram.png?alt=media&token=51b2c0e8-4f12-489e-9ced-3a93c80b9b11';

export const linkeningLegalizarlo = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Ffooter%2Fico-linkedin.png?alt=media&token=cef85fa7-16fa-43fa-8356-68fe25efe0c3';

export const icoDocs = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fdocumentos%2FicoDocs.png?alt=media&token=5ce1c710-55b6-40b5-b970-43008f4f68d8';

export const icoSearch = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fdocumentos%2FicoSearch.png?alt=media&token=3e80d72f-778e-46c1-9dd7-1143b2553a85';

export const descargaloComoFunciona = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2FcomoFunciona%2Fdescargalo.png?alt=media&token=6a1b753b-1106-4882-8587-b4fc668a04f2';

export const encuentraloComoFunciona = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2FcomoFunciona%2Fencuentralo.png?alt=media&token=a2ee9182-0d87-427b-8cee-07ed0bafca41';

export const flechaComoFunciona = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2FcomoFunciona%2FflechaComoFunciona.png?alt=media&token=a63765d8-9c13-4ff7-a1ea-25e8e17e4739';

export const pagaloComoFunciona = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2FcomoFunciona%2Fpagalo.png?alt=media&token=425a4cfe-5135-448e-9dff-cb97ef0bf4eb';

export const personalizaloComoFunciona = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2FcomoFunciona%2Fpersonalizalo.png?alt=media&token=65a9e9d6-7a95-498b-bd54-cef8c600db1b';

export const abogadosSeccion1 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fabogado%2Flegalizarlo-empresarios.webp?alt=media&token=f6783bd3-1ae4-4c56-8779-791fad6b93da';

export const flechaAbajo = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fdocumentos%2FflechaAbajo.png?alt=media&token=652a9932-50d4-48c5-b5a7-2b6914daaa15';

export const u = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fdocumentos%2Felement-symbol-m2.png?alt=media&token=1e99f8fe-8e5f-4a89-9dc1-4ccb0b3060a4';

export const imgPagoSeguro = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrito%2Fico-pago-seguro.png?alt=media&token=919ba5b2-2c65-48c7-815f-b867d7c7dcd6';

export const imgSsl = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrito%2Fico-ssl.png?alt=media&token=de642ae8-3589-4be6-ae33-f413259694e5';

export const imgTajetasDeCredito = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrito%2Flogos-pago-tarjeta.png?alt=media&token=f5820a1c-9677-4ac8-bdbf-17100666fba1';

export const imgPagoOxxo = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrito%2Flogos-pago-efectivo.png?alt=media&token=b20d41c6-bc3a-46ac-a156-27566e5d1121';

export const imgPagoSpei = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrito%2Flogos-pago-spei.png?alt=media&token=76a1759e-a872-4638-9648-220b1b8eab46';

export const pdf = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fdocumentos%2FPDF_file_icon.svg.png?alt=media&token=57187c7e-2292-4494-893d-a6ff84ae75b2';

export const word = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fdocumentos%2Fword%20(1).png?alt=media&token=4384de7a-653f-4ae1-a003-44f42d075b35';

export const iconoServicios = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fservicios%2FiconoServicios.png?alt=media&token=794a59f2-dd36-47e6-a1ad-cd67f71ee944';

export const iconoCalendario = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2FiconoCalendario.png?alt=media&token=eaaef0d9-133d-408a-a2bf-a4fa21edd459';

export const planes = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Fplanes.png?alt=media&token=2e7a12e6-7a2d-486b-a5da-988b00dc4ba6';


export const cupones = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2Fcupones.png?alt=media&token=687d6f0d-614b-4838-b26c-bc8545bb8e7b';

export const logoabogadotop = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fservicios%2F2ewgYiDyd3W4NnBCnaCx%2Felement-symbol-m3.svg?alt=media&token=7e51b328-06e3-4261-ba55-64b139298d34&_gl=1*blnp63*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NTkyNzY2Ny4xOC4xLjE2ODU5Mjc3MjMuMC4wLjA.';


export const fondoSeccion1Planes = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fplanes%2Felement-symbol-g5.png?alt=media&token=8477032c-d4cd-46f2-bb2f-d12e8e30311a';

export const puntosMorados = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fplanes%2Felement-grid-magenta.png?alt=media&token=bb2b7ba7-65e6-4fa1-818d-742025927512';

export const ahorros = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fplanes%2Fico-ahorro-significativo.svg?alt=media&token=e162816c-2da1-4fef-86b4-48c30ccfe14b';

export const servicioIntegral = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fplanes%2Fico-servicio-integral.svg?alt=media&token=2905d761-6c7f-4ab9-b9cd-a598d890d454';

export const respaldoLegal = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fplanes%2Fico-respaldo-legal.svg?alt=media&token=ff104377-22ba-4388-863f-363d67bdffe5';

export const atencionPersonal = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fplanes%2Fico-atencion-personal.svg?alt=media&token=86a9738b-27fb-485d-b3b0-943224a8a5ce';

export const userMenu = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fmenu%2FuserMenu.png?alt=media&token=59889e19-2022-4557-aa91-6fc3f50267d1&_gl=1*ln3yoq*_ga*MTQ4NjI0MTY1MS4xNjY4MDk2MDAy*_ga_CW55HF8NVT*MTY4NTQ2MjY4My4xMzAuMS4xNjg1NDYyNzcxLjAuMC4w';

export const carrusel1 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2FLogo%20Guizmarr.jpg?alt=media&token=0b214727-763b-4277-b149-89ae75835d87';

export const carrusel2 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2Flogo%20Castrum.jpg?alt=media&token=3090977e-3c5d-4982-8260-041eccf479e4';

export const carrusel3 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2Flogo-MrMex.jpg?alt=media&token=17a5fcd1-2a62-4ee3-ae40-b8b8840ddb33';

export const carrusel4 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2Flogo%20Rendola.jpg?alt=media&token=04fcea47-fc6a-47ac-8051-ab16c4842a50';

export const carrusel5 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2Flogo%20Rinconi.jpg?alt=media&token=91321419-1275-40bd-85e6-49ab4a5f5523';

export const carrusel6 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2Flogo%20Strive.jpg?alt=media&token=ec521757-6bd3-41a5-b8a3-013c1423fb6b';

export const carrusel7 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2Flogo%20amppare.jpg?alt=media&token=2f930a98-6ba7-4836-aa4a-44069f5d9a35';

export const carrusel8 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2Flogo%20carismass.jpg?alt=media&token=c22b9728-4558-42c2-b979-b1eb24f57a9e';

export const carrusel9 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2Flogo%20intalentum.jpg?alt=media&token=57152938-f575-49c8-a3a3-3fa3cdc79f3c';

export const carrusel10 = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fcarrusel%2Flogo%20neosoft.jpg?alt=media&token=c55a0c3e-8b81-4d62-8414-6927c72f2ecb';