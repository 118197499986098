import React, {useState, useEffect} from 'react';
import './editarDocumento-styles.css';
import {verEstaCompra, actualizarCompra, datosDeLaCompra, subirImagenEditDoc} from '../../../backend/editarDocumento/editarDocumento';
import {CampoPersonalizado, FormLogo} from '../../../components/editarDocumento/editarDocumento';
import {Link} from 'react-router-dom';
import {pdf, word} from '../../../assets/imgs.js';
import Swal from 'sweetalert2';
import {validarSiHayPlanContratado} from '../../../backend/carrito/carrito';

function EditarDocumentoScreen(){

	const [pantalla, CambioPantalla] = useState('');
	const [nombreUsuario, CambioNombreUsuario] = useState('');
	const [elIdCompra, CambioIdComprar] = useState('');
	const [nombreDocumento, CambioNombreDocumento] = useState('');
	const [datosDelProducto, CambioDatosDelProducto] = useState('');
	const [compraBuscada, CambioCompraBuscada] = useState(false);
	const [camposPersonalizados, CambioCamposPersonalizados] = useState([]);
	const [contenidoOriginal, CambioContenidoOriginal] = useState('');
	const [contenidoEditado, CambioContenidoEditado] = useState('');
	const [porcetaje, CambioPorcentaje] = useState('');
	const [divicionPorcentaje, CambioDivicionPorcentaje] = useState(0);
	const [hayLogo, CambioHayLogo] = useState(false);
	const [esElLogo, CambioEsElLogo] = useState(false);
	const [alineacionLogo, CambioAlineacionLogo] = useState('is');

	useEffect(() =>{
		verRol();
	})

	const verRol = async function(){
		let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
	        let dataUser = JSON.parse(usuario);
	        if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {
	        	window.location.href = '/admin/home';
	        }else{
	        	let idCompra = await localStorage.getItem('idCompra');
	        	if (idCompra != null) {

	        		if (compraBuscada == false) {

	        			document.getElementById('estilosMenu').innerHTML = '.navbar>.container-fluid{'+
			              'justify-content: center;'+
			            '}'+
			            '.itemsLegalizarloMenu{'+
			            'display: none;'+
			            '}';

	        			verEstaCompra(idCompra, 'documentos').then(response =>{
	        				if (response != false) {
	        					CambioDatosDelProducto(response);
	        					CambioCompraBuscada(true);
	        					CambioNombreDocumento(response.producto.data.nombre);
	        					CambioCamposPersonalizados(response.producto.data.camposPersonalizados);
	        					document.getElementById('contenido').innerHTML = response.producto.data.contenido;
	        					CambioContenidoOriginal(response.producto.data.contenido);
								CambioContenidoEditado(response.producto.data.contenido);
								calcularPorcentaje('inicio', response.producto.data.camposPersonalizados.length);
	        					CambioNombreUsuario(dataUser.nombre);
	        					CambioIdComprar(idCompra);
	        					validarPlan();
	        				}else{
	        					window.location.href = '/';	
	        				}
	        			})
	        		}
	        	}else{
	        		window.location.href = '/';
	        	}
	    	}
	    }
	}

	const calcularPorcentaje = async function(momento, cantidadFinal){
		if (momento == 'inicio') {
			let divicion = 300 / cantidadFinal; console.log(divicion);
			CambioDivicionPorcentaje(divicion);
		}else if (momento == 'intermedio') {
			document.getElementById('porcetaje').innerHTML = '.cuentaPorcentaje{width: '+cantidadFinal+'px !important;}'
		}
	}

	const terminarEdicion = async function(){
		if (porcetaje != '100%') {
			Swal.fire({
				title: 'Termine de editar el documento para continuar.',
				icon: 'info',
			});
		}else{

			let data = {
				idCompra: elIdCompra,
				caracteristicas:{
					editado: true,
					documento: contenidoEditado,
				},
			}

			actualizarCompra(data).then(response =>{
				CambioPantalla('descarga');
				window.setTimeout(volverAVerDocumento, 1000);
			});
		}
	}

	const volverAVerDocumento = async function(){
		document.getElementById('contenido').innerHTML = contenidoEditado;
	}

	const descargarPDF = async function(){
		window.open('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/firebase/pdf.php?idCompra='+elIdCompra, '_blank');
	}

	const descargarWORD = async function(){
		window.open('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/firebase/word.php?idCompra='+elIdCompra, '_blank');
	}

	const irADondeDice = async function(dice){
		window.location.href = dice;
	}

	const validarPlan = async function(){

	    let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
		    let dataUser = JSON.parse(usuario);

		    let hayPlan = await validarSiHayPlanContratado(dataUser.uid, 'planes');
		    console.log(hayPlan);
		    if (hayPlan != false) {

		        if (hayPlan.data.pagado && hayPlan.data.caracteristicas.activo) {

		        	if (hayPlan.data.caracteristicas.dataPlan.documentosLogotipo) {

		        		CambioHayLogo(true);
		        	}
			    }
			}
		}
	}

	const verLogo = async function(){ console.log('ejecutando');

		Swal.fire({
			html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
					'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
				  '</div>',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEnterKey: false,
			allowEscapeKey: false,
		});

		let logo = document.getElementById('logoDoc');

		const file = logo.files[0];

		const base64 = await subirImagenEditDoc(elIdCompra, file);
		CambioEsElLogo(base64);
		actualizaContenido();
		Swal.close();
	}

	const alinearLogo = async function(alineacion){ console.log(alineacion);

		if (alineacion == 'is') {

			document.getElementById('is').classList.add('formaDePagoActive');
			document.getElementById('isCirculo').classList.add('circuloActive');
			document.getElementById('cs').classList.remove('formaDePagoActive');
			document.getElementById('csCirculo').classList.remove('circuloActive');
			document.getElementById('ds').classList.remove('formaDePagoActive');
			document.getElementById('dsCirculo').classList.remove('circuloActive');
			document.getElementById('ii').classList.remove('formaDePagoActive');
			document.getElementById('iiCirculo').classList.remove('circuloActive');
			document.getElementById('ci').classList.remove('formaDePagoActive');
			document.getElementById('ciCirculo').classList.remove('circuloActive');
			document.getElementById('di').classList.remove('formaDePagoActive');
			document.getElementById('diCirculo').classList.remove('circuloActive');

			CambioAlineacionLogo(alineacion);
		}else if (alineacion == 'cs') {

			document.getElementById('is').classList.remove('formaDePagoActive');
			document.getElementById('isCirculo').classList.remove('circuloActive');
			document.getElementById('cs').classList.add('formaDePagoActive');
			document.getElementById('csCirculo').classList.add('circuloActive');
			document.getElementById('ds').classList.remove('formaDePagoActive');
			document.getElementById('dsCirculo').classList.remove('circuloActive');
			document.getElementById('ii').classList.remove('formaDePagoActive');
			document.getElementById('iiCirculo').classList.remove('circuloActive');
			document.getElementById('ci').classList.remove('formaDePagoActive');
			document.getElementById('ciCirculo').classList.remove('circuloActive');
			document.getElementById('di').classList.remove('formaDePagoActive');
			document.getElementById('diCirculo').classList.remove('circuloActive');
		}else if (alineacion == 'ds') {
			
			document.getElementById('is').classList.remove('formaDePagoActive');
			document.getElementById('isCirculo').classList.remove('circuloActive');
			document.getElementById('cs').classList.remove('formaDePagoActive');
			document.getElementById('csCirculo').classList.remove('circuloActive');
			document.getElementById('ds').classList.add('formaDePagoActive');
			document.getElementById('dsCirculo').classList.add('circuloActive');
			document.getElementById('ii').classList.remove('formaDePagoActive');
			document.getElementById('iiCirculo').classList.remove('circuloActive');
			document.getElementById('ci').classList.remove('formaDePagoActive');
			document.getElementById('ciCirculo').classList.remove('circuloActive');
			document.getElementById('di').classList.remove('formaDePagoActive');
			document.getElementById('diCirculo').classList.remove('circuloActive');
		}else if (alineacion == 'ii') {
			
			document.getElementById('is').classList.remove('formaDePagoActive');
			document.getElementById('isCirculo').classList.remove('circuloActive');
			document.getElementById('cs').classList.remove('formaDePagoActive');
			document.getElementById('csCirculo').classList.remove('circuloActive');
			document.getElementById('ds').classList.remove('formaDePagoActive');
			document.getElementById('dsCirculo').classList.remove('circuloActive');
			document.getElementById('ii').classList.add('formaDePagoActive');
			document.getElementById('iiCirculo').classList.add('circuloActive');
			document.getElementById('ci').classList.remove('formaDePagoActive');
			document.getElementById('ciCirculo').classList.remove('circuloActive');
			document.getElementById('di').classList.remove('formaDePagoActive');
			document.getElementById('diCirculo').classList.remove('circuloActive');
		}else if (alineacion == 'ci') {
			
			document.getElementById('is').classList.remove('formaDePagoActive');
			document.getElementById('isCirculo').classList.remove('circuloActive');
			document.getElementById('cs').classList.remove('formaDePagoActive');
			document.getElementById('csCirculo').classList.remove('circuloActive');
			document.getElementById('ds').classList.remove('formaDePagoActive');
			document.getElementById('dsCirculo').classList.remove('circuloActive');
			document.getElementById('ii').classList.remove('formaDePagoActive');
			document.getElementById('iiCirculo').classList.remove('circuloActive');
			document.getElementById('ci').classList.add('formaDePagoActive');
			document.getElementById('ciCirculo').classList.add('circuloActive');
			document.getElementById('di').classList.remove('formaDePagoActive');
			document.getElementById('diCirculo').classList.remove('circuloActive');
		}else if (alineacion == 'di') {
			
			document.getElementById('is').classList.remove('formaDePagoActive');
			document.getElementById('isCirculo').classList.remove('circuloActive');
			document.getElementById('cs').classList.remove('formaDePagoActive');
			document.getElementById('csCirculo').classList.remove('circuloActive');
			document.getElementById('ds').classList.remove('formaDePagoActive');
			document.getElementById('dsCirculo').classList.remove('circuloActive');
			document.getElementById('ii').classList.remove('formaDePagoActive');
			document.getElementById('iiCirculo').classList.remove('circuloActive');
			document.getElementById('ci').classList.remove('formaDePagoActive');
			document.getElementById('ciCirculo').classList.remove('circuloActive');
			document.getElementById('di').classList.add('formaDePagoActive');
			document.getElementById('diCirculo').classList.add('circuloActive');
		}

		CambioAlineacionLogo(alineacion);
		actualizaContenido();
	}

	const actualizaContenido = async function(idCampo){ console.log(hayLogo, esElLogo);

		const p = contenidoOriginal;
		const nuevo = [];
		const porcetajeProgreso = [0];

		let logoSuperior = '';
		let logoInferior = '';

		if (hayLogo && esElLogo != false) {

			if (alineacionLogo == 'is') { console.log('is');

				logoSuperior = '<div style="width: 100%; padding: 20px; display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">'+
					'<img'+
	                	' src="'+esElLogo+'"'+
	                	' style="width: 100px;"'+
	                	' id="elLogo"'+
	                '/>'+
				'</div>';
			}else if (alineacionLogo == 'cs') { console.log('cs');

				logoSuperior = '<div style="width: 100%; padding: 20px; display: flex; flex-direction: row; justify-content: center; align-items: center;">'+
					'<img'+
	                	' src="'+esElLogo+'"'+
	                	' style="width: 100px;"'+
	                	' id="elLogo"'+
	                '/>'+
				'</div>';
			}else if (alineacionLogo == 'ds') { console.log('ds');

				logoSuperior = '<div style="width: 100%; padding: 20px; display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">'+
					'<img'+
	                	' src="'+esElLogo+'"'+
	                	' style="width: 100px;"'+
	                	' id="elLogo"'+
	                '/>'+
				'</div>';
			}else if (alineacionLogo == 'ii') { console.log('ii');

				logoInferior = '<div style="width: 100%; padding: 20px; display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">'+
					'<img'+
	                	' src="'+esElLogo+'"'+
	                	' style="width: 100px;"'+
	                	' id="elLogo"'+
	                '/>'+
				'</div>';
			}else if (alineacionLogo == 'ci') { console.log('ci');

				logoInferior = '<div style="width: 100%; padding: 20px; display: flex; flex-direction: row; justify-content: center; align-items: center;">'+
					'<img'+
	                	' src="'+esElLogo+'"'+
	                	' style="width: 100px;"'+
	                	' id="elLogo"'+
	                '/>'+
				'</div>';
			}else if (alineacionLogo == 'di') { console.log('di');

				logoInferior = '<div style="width: 100%; padding: 20px; display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">'+
					'<img'+
	                	' src="'+esElLogo+'"'+
	                	' style="width: 100px;"'+
	                	' id="elLogo"'+
	                '/>'+
				'</div>';
			}
		}

		let paraEmpeque = [];

		for (let campo of camposPersonalizados) {
			if (campo.tipoDeCampo == 'grupoDeCasillas') {

				let valor = ''; 

				for (let casilla of campo.data.casillas) {
				 	let unaCasilla = document.getElementById(campo.id+"-"+casilla.valor).checked;
				 	if (unaCasilla) {
				 		valor += document.getElementById(campo.id+"-"+casilla.valor).value+' ';
				 	}
				} 

				if (valor != '') {

					let suma = porcetajeProgreso[0] + divicionPorcentaje;
					porcetajeProgreso.splice(0, 1);
					porcetajeProgreso.push(suma);

					if (nuevo.length > 0) {
						let edicion = nuevo[0].replace('['+campo.data.nombre+' id="'+campo.id+'"]', '<span id="'+campo.id+'-valor" class="textoNormal">'+valor+'</span>');
						nuevo.splice(0, 1);
						nuevo.push(edicion);
					}else{
						let edicion = p.replace('['+campo.data.nombre+' id="'+campo.id+'"]', '<span id="'+campo.id+'-valor" class="textoNormal">'+valor+'</span>');
						nuevo.push(edicion);
					}

					paraEmpeque.push(campo.id+'-valor');
				}
			}else{
				let valor = document.getElementById(campo.id).value;
				if (valor != '') {

					let suma = porcetajeProgreso[0] + divicionPorcentaje;
					porcetajeProgreso.splice(0, 1);
					porcetajeProgreso.push(suma);

					if (nuevo.length > 0) {
						let edicion = nuevo[0].replace('['+campo.data.nombre+' id="'+campo.id+'"]', '<span id="'+campo.id+'-valor" class="textoNormal">'+valor+'</span>');
						nuevo.splice(0, 1);
						nuevo.push(edicion);
					}else{
						let edicion = p.replace('['+campo.data.nombre+' id="'+campo.id+'"]', '<span id="'+campo.id+'-valor" class="textoNormal">'+valor+'</span>');
						nuevo.push(edicion);
					}

					paraEmpeque.push(campo.id+'-valor');
				}
			}
		}

		if (nuevo.length > 0) {
			CambioContenidoEditado(logoSuperior+nuevo[0]+logoInferior);
			document.getElementById('contenido').innerHTML = logoSuperior+nuevo[0]+logoInferior;
			calcularPorcentaje('intermedio', porcetajeProgreso[0]);
			let calculo = (porcetajeProgreso[0] * 100) / 300;
			CambioPorcentaje(parseInt(calculo)+'%');
		}else{
			CambioContenidoEditado(logoSuperior+p+logoInferior);
			document.getElementById('contenido').innerHTML = logoSuperior+p+logoInferior;
			calcularPorcentaje('intermedio', porcetajeProgreso[0]);
			CambioPorcentaje('');
		}

		engrandarTexto(idCampo+'-valor');
	}

	const engrandarTexto = async function(idTexto){

		document.getElementById(idTexto).classList.add('textoGrande');

		window.setTimeout(empequeTexto, 300, idTexto);
	}

	const empequeTexto = async function(idTexto){

		//window.location.href = '/cliente/editarDocumento#'+idTexto;

		document.getElementById(idTexto).classList.remove('textoGrande');
	}

	if (pantalla == 'descarga') {
		return(
	        <section class="seccion1Carrito">
	          <style id="estilosMenu">

	          </style>
	          <div class="container">
	            <div class="row">
	              <div class="col-sm">
	                <h5 class="mensajePagoFinaliza">Documento editado</h5>
	                <h1 class="tituloCarrito">¡Enhorabuena {nombreUsuario}!</h1>
	                <p class="parrafoFinal">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at ligula non turpis imperdiet dignissim ac ac sem. Sed id odio interdum, rhoncus arcu vel, laoreet nulla. Mauris quis pretium velit, sed interdum felis. Ut consequat ut nulla eu venenatis. Maecenas accumsan sapien ac euismod hendrerit. Donec nec felis urna. Sed varius elit a velit dapibus facilisis. Suspendisse leo sem, pulvinar at massa efficitur, maximus congue velit. Quisque efficitur turpis nec ante blandit viverra. Etiam vel gravida mauris. Duis finibus volutpat nisi ultricies pulvinar. Sed at volutpat quam.</p>
	                <br />

	                <div class="row">
		              	<div class="col-sm flex">
		                	<div class="contenido estiloDocumento" id="contenido">
		                	
		                	</div>
		              	</div>
		            </div>

	                <br />

	                <h5 class="mensajePagoFinaliza">Descargalo en el formato que desees</h5>

	                <div class="botonesDescarga">
	                	<img onClick={descargarPDF} class="botonDescarga" src={pdf} />
	                	<img onClick={descargarWORD} class="botonDescarga" src={word} />
	                </div>

	                <br />
	              </div>
	            </div>
	            <div class="hrFinal"></div>
	            <br />
	            <div class="row">
	              <div class="col-sm">
	                <h1 class="tituloCarrito">¿Que Sigue?</h1>
	                <br />
	                <div class="opcionesFinales">
	                  <div onClick={() => irADondeDice('/cliente/home')} class="elegirFinal">
	                    <div class="tipoOpcionYNombre">
	                      <div class="circulo">
	                      </div>
	                      <span class="nombreOpcion">Ingresa a tu panel de usuario</span>
	                    </div>
	                  </div>
	                  {/*<div  class="elegirFinal">
	                    <div class="tipoOpcionYNombre">
	                      <div class="circulo">
	                      </div>
	                      <span class="nombreOpcion">Solicita tu factura</span>
	                    </div>
	                  </div>*/}
	                </div>
	                <br />
	              </div>
	            </div>
	            <div class="row">
	              <div class="col-sm">
	                <p class="parrafoNecesitas">¿Necesitas ayuda? <a class="negro" href="https://wa.me/message/E4NL5UXQR3ASA1" target="_blank">Contactanos</a></p>
	              </div>
	            </div>
	          </div>
	        </section>
	    );
	}else{
		return(
	        <section class="seccion1Carrito">
	          <style id="estilosMenu">

	          </style>
	          <div class="container">
	            <div class="row">
	              	<div class="col-sm">
	                	<h1 class="tituloCarrito">Editar {nombreDocumento}</h1><br />
	                	<style id="porcetaje"></style>
	                	<div class="porcetaje">
	                		<div class="porcetajeInterno cuentaPorcentaje">
	                			<span class="textoPorcentaje">{porcetaje}</span>
	                		</div>
	                	</div>
	              	</div>
	            </div><br />
	            <div class="row">
	              	<div class="col-sm">
	              		<div class="formaEditDoc paddingCuadro">
		              		{camposPersonalizados.map((item) =>(
		              			<CampoPersonalizado
		              				item={item}
		              				actualizaContenido={actualizaContenido}
		              			/>
		              		))}
		              		<FormLogo
		              			hayLogo={hayLogo}
		              			verLogo={verLogo}
		              			alinearLogo={alinearLogo}
		              		/>
	              		</div>
	              	</div>
	              	<div class="col-sm flex">
	                	<div class="hrVertical"></div>
	                	<div class="contenido" id="contenido">
	                	</div>
	              	</div>
	            </div>

	            <div class="row">
		            <div class="ordenarBotones">
		                <div class="divAccionPago">
		                  <Link to="/abogados" class="atrasPago"><span class="preguntaDuda">¿Tienes dudas?</span> Contacta a un asesor</Link>
		                  <button onClick={terminarEdicion} class="terminarPago">Siguiente</button>
		                </div>
		            </div>
		            <div class="col-sm">
					</div>
	            </div>
	          </div>
	        </section>
	    ); 
	}
}

export default EditarDocumentoScreen;