import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function guardarPlan(id, data){
	await set(documento(db, "planes", id), data);

	return true;
}

export async function buscarPlanes(rol){

	let planes = [];
	let parametro = '';

	if(rol == 'admin'){
		parametro = donde("nombre", "!=", "");
	}else if(rol == 'cliente'){
		parametro = donde("activo", "==", true);
	}

	const q = buscar(coleccion(db, "planes"), parametro);

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		planes.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return planes;
}

export async function editarEstadoPlan(id, estado){
	let activo = false;
	
	if(estado == false){
		activo = true;
	}

	const washingtonRef = documento(db, "planes", id);

	await actualizarDoc(washingtonRef, {
		activo: activo,
	});

	return true;
}