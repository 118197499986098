import React, {useState, useEffect} from 'react';
import './administradores-styles.css';

export function LasRestricciones({
	restricciones
}){
	return(
		<>
			{restricciones.map((item) =>(
				<span style={{color: '#fff', fontWeight: 'bold', marginRight: 20,}}>{item}</span>
			))}
		</>
	);
}