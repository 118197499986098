import React, {useState, useEffect} from 'react';
import './documentos.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {FormCampos, BotonActivo, BotonSolicitado} from '../../../components/documentos/documentos.js';
import Swal from 'sweetalert2';
import {subirImagenDoc, guardarDocumento, buscarDocumentos, editarDocumento, editarSolicitado} from '../../../backend/documentos/documentos.js';
import {ModalAuth} from '../../../components/modals/modals.js';

function AdminDocumentosScreen(){

	const [pantalla, CambioPantalla] = useState('lista');
	const [value, setValue] = useState('');
	const [value2, setValue2] = useState('');
	const [tipoDeCampo, CambioTipoDeCampo] = useState('');
	const [camposPersonalizados, CambioCamposPersonalizados] = useState([]);
	const [nombreCampoDeTexto, CambioNombreCampoDeTexto] = useState('');
	const [nombreAreaDeTexto, CambioNombreAreaDeTexto] = useState('');
	const [nombreSeleccionable, CambioNombreSeleccionable] = useState('');
	const [opcionesSeleccionable, CambioOpcionesSeleccionable] = useState([]); 
	const [nombreOpcionSeleccionable, CambioNombreOpcionSeleccionable] = useState('');
	const [nombreGrupoCasillas, CambioNombreGrupoCasillas] = useState('');
	const [nombreCasilla, CambioNombreCasilla] = useState('');
	const [grupoDeCasillas, CambioGrupoDeCasillas] = useState([]);
	const [nombreCampoDeFecha, CambioNombreCampoDeFecha] = useState('');
	const [fecha, CambioFecha] = useState('');
	const [nombreCampoDeNumero, CambioNombreCampoDeNumero] = useState('');
	const [numero, CambioNumero] = useState('');
	const [nombreDelDocumento, CambioNombreDelDocumento] = useState('');
	const [descripcionCortar, CambioDescripcionCortar] = useState('');
	const [descripcion, CambioDescripcion] = useState('');
	const [margenSuperior, CambioMargenSuperior] = useState('');
	const [margenInferior, CambioMargenInferior] = useState('');
	const [margenIzquierdo, CambioMargenIzquierdo] = useState('');
	const [margenDerecho, CambioMargenDerecho] = useState('');
	const [margenDePieDePagina, CambioMargenDePieDePagina] = useState('');
	const [margenDeCabecera, CambioMargenDeCabecera] = useState('');
	const [conImagen, CambioConImagen] = useState(false);
	const [precio, CambioPrecio] = useState('');
	const [valoracion, CambioValoracion] = useState('');
	const [votos, CambioVotos] = useState('');
	const [tiempoEstimado, CambioTiempoEstimado] = useState('');
	const [descargas, CambioDescargas] = useState('');
	const [documentos, CambioDocumentos] = useState([]);
	const [mostrarEditarDocumento, CambioMostrarEditarDocumento] = useState(false);
	const [tituloModal, CambioTituloModal] = useState('');
	const [estadoDocumento, CambioEstadoDocumento] = useState(false);
	const [idDocumento, CambioIdDocumento] = useState('');
	const [busquedaDocumentos, CambioBusquedaDocumentos] = useState(false);
	const [rolVisto, CambioRoVisto] = useState(false);
	const [solicitado, CambioSolicitado] = useState(false);
	const [mostrarEditarSolicitado, CambioMostrarEditarSolicitado] = useState(false);

	useEffect(() =>{

		if (busquedaDocumentos == false) {
			buscarDocumentos('admin').then(response =>{
				CambioDocumentos(response);
				CambioBusquedaDocumentos(true);
			});
		}

		if (rolVisto == false) {
	      verRol();
	    }
	});

	const verRol = async function(){
	    let usuario = await localStorage.getItem('usuarioLegalizarlo');
	    if (usuario != null) {
	      let dataUser = JSON.parse(usuario);
	      if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {

	      	if (dataUser.rol == 'subAdmin') { 

	        	dataUser.restricciones.forEach(element =>{ console.log(element);
	        		if (element == 'documentos') {
	        			window.location.href = '/';
	        		}
	        	})
	        }

	        CambioRoVisto(true);
	      }else{
	        window.location.href = '/';
	      }
	    }else{
	      window.location.href = '/';
	    }
	}

	const agregarCampo = async function(elTipoDeCampo){

		let campos = camposPersonalizados;

		var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
		var id = "";
			for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

		if (elTipoDeCampo == 'campoDeTexto') {
			if (nombreCampoDeTexto === '') {
				Swal.fire({
				  title: 'Por favor complete todos los campos para continuar.',
				  icon: 'info',
				});
			}else{
				campos.push({
					id: id,
					tipoDeCampo: elTipoDeCampo,
					data: {
						nombre: nombreCampoDeTexto,
					},
				});

				CambioCamposPersonalizados(campos);
				CambioTipoDeCampo('');
				CambioNombreCampoDeTexto('');
			}
		}else if (elTipoDeCampo == 'areaDeTexto') {
			if (nombreAreaDeTexto === '') {
				Swal.fire({
				  title: 'Por favor complete todos los campos para continuar.',
				  icon: 'info',
				});
			}else{
				campos.push({
					id: id,
					tipoDeCampo: elTipoDeCampo,
					data: {
						nombre: nombreAreaDeTexto,
					},
				});

				CambioCamposPersonalizados(campos);
				CambioTipoDeCampo('');
				CambioNombreAreaDeTexto('');
			}
		}else if (elTipoDeCampo == 'seleccionable') {
			if (nombreSeleccionable === '' || opcionesSeleccionable.length <= 1) {
				Swal.fire({
				  title: 'Por favor complete todos los campos para continuar y agregue al menos 2 opciones.',
				  icon: 'info',
				});
			}else{
				campos.push({
					id: id,
					tipoDeCampo: elTipoDeCampo,
					data: {
						nombre: nombreSeleccionable,
						opciones: opcionesSeleccionable,
					},
				});

				CambioCamposPersonalizados(campos);
				CambioNombreSeleccionable('');
				CambioOpcionesSeleccionable([]);
				CambioTipoDeCampo('');
			}
		}else if (elTipoDeCampo == 'grupoDeCasillas') {
			if (nombreGrupoCasillas === '' || grupoDeCasillas.length <= 1) {
				Swal.fire({
				  title: 'Por favor complete todos los campos para continuar y agregue al menos 2 casillas.',
				  icon: 'info',
				});
			}else{
				campos.push({
					id: id,
					tipoDeCampo: elTipoDeCampo,
					data: {
						nombre: nombreGrupoCasillas,
						casillas: grupoDeCasillas,
					},
				});

				CambioCamposPersonalizados(campos);
				CambioNombreGrupoCasillas('');
				CambioGrupoDeCasillas([]);
				CambioTipoDeCampo('');
			}
		}else if (elTipoDeCampo == 'campoDeFecha') {
			if (nombreCampoDeFecha === '' || fecha === '') {
				Swal.fire({
				  title: 'Por favor complete todos los campos para continuar.',
				  icon: 'info',
				});
			}else{
				campos.push({
					id: id,
					tipoDeCampo: elTipoDeCampo,
					data: {
						nombre: nombreCampoDeFecha,
						fecha: fecha,
					},
				});

				CambioCamposPersonalizados(campos);
				CambioNombreCampoDeFecha('');
				CambioFecha('');
				CambioTipoDeCampo('');
			}
		}else if (elTipoDeCampo == 'numero') {
			if (nombreCampoDeNumero === '' || numero === '') {
				Swal.fire({
				  title: 'Por favor complete todos los campos para continuar.',
				  icon: 'info',
				});
			}else{
				campos.push({
					id: id,
					tipoDeCampo: elTipoDeCampo,
					data: {
						nombre: nombreCampoDeNumero,
						numero: numero,
					},
				});

				CambioCamposPersonalizados(campos);
				CambioNombreCampoDeNumero('');
				CambioNumero('');
				CambioTipoDeCampo('');
			}
		}
	}

	const agregarOpcion = async function(){
		let opciones = opcionesSeleccionable;
		if (nombreOpcionSeleccionable === '') {
			Swal.fire({
				title: 'Por favor complete todos los campos para continuar.',
				icon: 'info',
			});
		}else{
			opciones.push(nombreOpcionSeleccionable);
			CambioOpcionesSeleccionable(opciones);
			CambioNombreOpcionSeleccionable('');
			document.getElementById('nombreOpcionSeleccionable').value = '';
		}
	}

	const quitarOpcion = async function(item){
		let opciones = opcionesSeleccionable;
		let index = opciones.indexOf(item);
		opciones.splice(index, 1);
		CambioOpcionesSeleccionable(opciones);
		document.getElementById('opcion-'+item).remove();
	}

	const agregarCasilla = async function(){
		let casillas = grupoDeCasillas;
		if (nombreCasilla === '') {
			Swal.fire({
				title: 'Por favor complete todos los campos para continuar.',
				icon: 'info',
			});
		}else{

			let procesado = nombreCasilla.replace(/\s+/g, '');

			casillas.push({
				nombre: nombreCasilla,
				valor: procesado,
			});
			CambioGrupoDeCasillas(casillas);
			CambioNombreCasilla('');
			document.getElementById('nombreCasilla').value = '';
		}
	}

	const quitarCasilla = async function(item){
		let casillas = grupoDeCasillas;
		let index = casillas.indexOf(item);
		casillas.splice(index, 1);
		CambioGrupoDeCasillas(casillas);
		document.getElementById('casilla-'+item.valor).remove();
	}

	const insertarCampo = async function(){

		let idCampo = document.getElementById('campos').value;
		let campo = '';

		if (idCampo === '') {

		}else{

			camposPersonalizados.forEach(element =>{
				if (element.id == idCampo) {
					campo = element;
				}
			});

			let valorDoc = value+'['+campo.data.nombre+' id="'+campo.id+'"]';
			setValue(valorDoc);
		}
	}

	const preVisualizarImagen = async function(){
		var pdrs = document.getElementById('elInputImagen').files[0].name;
		document.getElementById('labelElInputImagen').innerHTML = pdrs;
		CambioConImagen(true);
	}

	const procesarDocumento = async function(){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
		});

		var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
		var id = "";
			for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

		let tamanohoja = document.getElementById('tamanohoja').value;
		let orientacionhoja = document.getElementById('orientacionhoja').value;
		let categoria = document.getElementById('categoria').value;

		if (value === '' || value2 === '' || nombreDelDocumento === '' || descripcionCortar === '' || descripcion === '' || margenSuperior === '' || margenInferior === '' || margenIzquierdo === '' || margenDerecho === '' || margenDePieDePagina === '' || margenDeCabecera === '' || precio === '' || valoracion === '' || votos === '' || tiempoEstimado === '' || descargas === '' || tamanohoja === '' || orientacionhoja === '') {
			Swal.fire({
				title: 'Por favor complete todos los campos para continuar.',
				icon: 'info',
			});
		}else{

			var imagen = document.getElementById('elInputImagen').files[0];
			let urlImagen = '';

			if (conImagen) {
				urlImagen = await subirImagenDoc(id, imagen);
			}else{
				urlImagen = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2FdocumentosAdmin%2Fdoc.png?alt=media&token=00577dfc-7d7c-4883-9aa0-38efdb112fd1';
			}

			let date = new Date();

			let data = {
				contenido: value,
				paraQueSirve: value2,
				nombre: nombreDelDocumento,
				descripcionCortar: descripcionCortar,
				descripcion: descripcion,
				margenSuperior: margenSuperior,
				margenInferior: margenInferior,
				margenIzquierdo: margenIzquierdo,
				margenDerecho: margenDerecho,
				margenDePieDePagina: margenDePieDePagina,
				margenDeCabecera: margenDeCabecera,
				precio: precio,
				valoracion: valoracion,
				votos: votos,
				tiempoEstimado: tiempoEstimado,
				descargas: descargas,
				camposPersonalizados: camposPersonalizados,
				tamanohoja: tamanohoja,
				orientacionhoja: orientacionhoja,
				categoria: categoria,
				diaCreacion: date.getDate(),
				mesCreacion: date.getMonth() + 1,
				anoCreacion: date.getFullYear(),
				foto: urlImagen,
				activo: true,
				solicitado: solicitado,
			};

			guardarDocumento(id, data).then(reponse =>{
				window.location.reload();
			})
		}
	}

	const cambiarEstado = async function(id, estado){
		if(estado){
			CambioTituloModal('¿Desea desactivar este documento?')
			CambioMostrarEditarDocumento(true);
			CambioEstadoDocumento(estado);
			CambioIdDocumento(id);
		}else{
			CambioTituloModal('¿Desea activar este documento?')
			CambioMostrarEditarDocumento(true);
			CambioEstadoDocumento(estado);
			CambioIdDocumento(id);
		}
	}

	const TerminarDeCambiarEstado = async function(){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
		});

		editarDocumento(idDocumento, estadoDocumento).then(response =>{

			CambioMostrarEditarDocumento(false);
			CambioBusquedaDocumentos(false);
			Swal.close();
		})
	}

	const ponerEnMasSolicitados = async function(){ console.log(solicitado)

		if (solicitado == false) {

			CambioSolicitado(true);
		}else{

			CambioSolicitado(false);
		}
	}

	const cambiarSolicitado = async function(id, estado){

		if(estado){
			CambioTituloModal('¿Desea quitar de los mas solicitados?')
			CambioMostrarEditarSolicitado(true);
			CambioEstadoDocumento(estado);
			CambioIdDocumento(id);
		}else{
			CambioTituloModal('¿Desea agregar a los mas solicitados?')
			CambioMostrarEditarSolicitado(true);
			CambioEstadoDocumento(estado);
			CambioIdDocumento(id);
		}
	}

	const TerminarDeCambiarSolicitado = async function(){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
		});

		editarSolicitado(idDocumento, estadoDocumento).then(response =>{

			CambioMostrarEditarSolicitado(false);
			CambioBusquedaDocumentos(false);
			Swal.close();
		})
	}

	if (pantalla == 'lista') {
		return(
			<div class="containerAdminDocs">
				<section class="seccion1AdminDocs">
					<div class="container">
						<div class="row">
							<div class="col-sm paddingArribaAbajo">
								<button onClick={() => CambioPantalla('gestionar')} class="btn btn-primary">Crear Documento</button>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<br />
								<table class="table table-dark table-striped">
									<thead>
										<tr>
											<th scope="col">Nombre</th>
											<th scope="col">Descripción</th>
											<th scope="col">Categoría</th>
											<th scope="col">Fecha de creación</th>
											<th scope="col">Precio</th>
											<th scope="col">Acciones</th>
										</tr>
									</thead>
									<tbody>
										{documentos.map((item) =>(
											<tr>
												<td>{item.data.nombre}</td>
												<td>{item.data.descripcionCortar}</td>
												<td>{item.data.categoria}</td>
												<td>{item.data.anoCreacion+'-'+item.data.mesCreacion+'-'+item.data.diaCreacion}</td>
												<td>{item.data.precio}</td>
												<td>
													<BotonActivo
														estado={item.data.activo}
														cambiarEstado={() => cambiarEstado(item.id, item.data.activo)}
													/>
													<BotonSolicitado
														estado={item.data.solicitado}
														cambiarEstado={() => cambiarSolicitado(item.id, item.data.solicitado)}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</section>
				<ModalAuth mostrar={mostrarEditarDocumento}>
					<div class="container">
						<div class="row">
							<div class="col-sm modalCambioEstado">
								<h4>{tituloModal}</h4>
								<button onClick={() => TerminarDeCambiarEstado()} class="btn btn-success margenBotonTextCustom">Si</button>
								<button onClick={() => CambioMostrarEditarDocumento(false)} class="btn btn-danger margenBotonTextCustom">No</button>
							</div>				
						</div>
					</div>
				</ModalAuth>

				<ModalAuth mostrar={mostrarEditarSolicitado}>
					<div class="container">
						<div class="row">
							<div class="col-sm modalCambioEstado">
								<h4>{tituloModal}</h4>
								<button onClick={() => TerminarDeCambiarSolicitado()} class="btn btn-success margenBotonTextCustom">Si</button>
								<button onClick={() => CambioMostrarEditarSolicitado(false)} class="btn btn-danger margenBotonTextCustom">No</button>
							</div>				
						</div>
					</div>
				</ModalAuth>
			</div>
		);
	}else{
		return(
			<div class="containerAdminDocs">
				<section class="seccion1AdminDocs">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<button onClick={() => CambioPantalla('lista')} class="btn btn-warning">Cancelar</button>
								<br/>
								<br/>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Campos personalizados del documento</h4>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<button onClick={() => CambioTipoDeCampo('campoDeTexto')} class="btn btn-success margenBotonTextCustom">Campo de texto</button>
								<button onClick={() => CambioTipoDeCampo('areaDeTexto')} class="btn btn-success margenBotonTextCustom">Area de texto</button>
								<button onClick={() => CambioTipoDeCampo('seleccionable')} class="btn btn-success margenBotonTextCustom">Seleccionable</button>
								<button onClick={() => CambioTipoDeCampo('grupoDeCasillas')} class="btn btn-success margenBotonTextCustom">Grupo de casillas</button>
								<button onClick={() => CambioTipoDeCampo('campoDeFecha')} class="btn btn-success margenBotonTextCustom">Campo de fecha</button>
								<button onClick={() => CambioTipoDeCampo('numero')} class="btn btn-success margenBotonTextCustom">Numero</button>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<FormCampos
									tipoDeCampo={tipoDeCampo}
									agregarCampo={agregarCampo}
									CambioNombreCampoDeTexto={CambioNombreCampoDeTexto}
									CambioNombreAreaDeTexto={CambioNombreAreaDeTexto}
									CambioNombreSeleccionable={CambioNombreSeleccionable}
									opcionesSeleccionable={opcionesSeleccionable}
									CambioNombreOpcionSeleccionable={CambioNombreOpcionSeleccionable}
									agregarOpcion={agregarOpcion}
									quitarOpcion={quitarOpcion}
									CambioNombreGrupoCasillas={CambioNombreGrupoCasillas}
									CambioNombreCasilla={CambioNombreCasilla}
									grupoDeCasillas={grupoDeCasillas}
									agregarCasilla={agregarCasilla}
									quitarCasilla={quitarCasilla}
									CambioNombreCampoDeFecha={CambioNombreCampoDeFecha}
									CambioFecha={CambioFecha}
									CambioNombreCampoDeNumero={CambioNombreCampoDeNumero}
									CambioNumero={CambioNumero}
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Seleccionar campo personalizado</h4>
								<select id="campos" class="form-select" aria-label="Default select example">
								  <option value="" selected>Campos personalizados</option>
								  {camposPersonalizados.map((item) =>(
								  	<option value={item.id}>{item.data.nombre}</option>
								  ))}
								</select>
								<br/>
								<button onClick={() => insertarCampo()} class="btn btn-info">Insertar</button>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<br/>
								<ReactQuill theme="snow" value={value} onChange={setValue} />
								<br/>
							</div>
						</div>

						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Para que sirve: </h4>
							</div>
						</div>

						<div class="row">
							<div class="col-sm">
								<br/>
								<ReactQuill theme="snow" value={value2} onChange={setValue2} />
								<br/>
							</div>
						</div>

						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Datos generales</h4>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<div class="mb-3">
								  <label for="" class="form-label">Nombre del documento</label>
								  <input onChange={() => CambioNombreDelDocumento(document.getElementById('nombreDelDocumento').value)} type="text" class="form-control" id="nombreDelDocumento" />
								</div>
								<div class="mb-3">
								  <label for="" class="form-label">Descripción Cortar</label>
								  <textarea onChange={() => CambioDescripcionCortar(document.getElementById('descripcionCortar').value)} type="text" class="form-control" id="descripcionCortar"></textarea>
								</div>
								<div class="mb-3">
								  <label for="" class="form-label">Descripción</label>
								  <textarea onChange={() => CambioDescripcion(document.getElementById('descripcion').value)} type="text" class="form-control" id="descripcion"></textarea>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Características de la hoja</h4>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<div class="mb-3">
								  <label for="" class="form-label">Tamaño de hoja</label>
								  <select class="form-control input-group" id="tamanohoja">
					                <optgroup label="Vertical">
					                  <option value="Letter">Letter</option>
					                  <option value="Legal">Legal</option>
					                  <option value="Executive">Executive</option>
					                </optgroup>
					                <optgroup label="Horizontal">
					                  <option value="Letter-L">Letter-L</option>
					                  <option value="Legal-L">Legal-L</option>
					                  <option value="Executive-L">Executive-L</option>
					                </optgroup>
					              </select>
								</div>
							</div>
							<div class="col-sm">
								<div class="mb-3">
								  <label for="" class="form-label">Orientacion de la hoja</label>
									<select class="form-control input-group" id="orientacionhoja">
						                <option value="L">Horizontal</option>
						                <option value="P">Vertical</option>
						            </select>
						        </div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Márgenes</h4>
								<p>Los margenes deben se expresados en milimetros</p>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<div class="mb-3">
								  <label for="" class="form-label">Margen superior</label>
								  <input onChange={() => CambioMargenSuperior(document.getElementById('margenSuperior').value)} type="number" class="form-control" id="margenSuperior" />
								</div>
							</div>
							<div class="col-sm">
								<div class="mb-3">
								  <label for="" class="form-label">Margen inferior</label>
								  <input onChange={() => CambioMargenInferior(document.getElementById('margenInferior').value)} type="number" class="form-control" id="margenInferior" />
								</div>
							</div>
							<div class="col-sm">
								<div class="mb-3">
								  <label for="" class="form-label">Margen izquierdo</label>
								  <input onChange={() => CambioMargenIzquierdo(document.getElementById('margenIzquierdo').value)} type="number" class="form-control" id="margenIzquierdo" />
								</div>
							</div>
							<div class="col-sm">
								<div class="mb-3">
								  <label for="" class="form-label">Margen derecho</label>
								  <input onChange={() => CambioMargenDerecho(document.getElementById('margenDerecho').value)} type="number" class="form-control" id="margenDerecho" />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<div class="mb-3">
								  <label for="" class="form-label">Margen de pie de página</label>
								  <input onChange={() => CambioMargenDePieDePagina(document.getElementById('margenDePieDePagina').value)} type="number" class="form-control" id="margenDePieDePagina" />
								</div>
							</div>
							<div class="col-sm">
								<div class="mb-3">
								  <label for="" class="form-label">Margen de cabecera</label>
								  <input onChange={() => CambioMargenDeCabecera(document.getElementById('margenDeCabecera').value)} type="number" class="form-control" id="margenDeCabecera" />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Categorización e imagen de ejemplo</h4>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<div class="mb-3">
								  	<label for="" class="form-label">Categoría</label>
									<select class="form-control input-group" id="categoria">
						                <option value="sinCategoria">Sin Categoria</option>
						            </select>
						        </div>
							</div>
							<div class="col-sm">
								<div class="inputImagenes" id="inputImagenes">
									<label id="labelElInputImagen" class="labelElInputImagen" for="elInputImagen">Cambiar</label>
									<input onChange={() => preVisualizarImagen()} type="file" class="elInputImagen" id="elInputImagen" accept="image/*" />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Costo del documento para ventas individuales</h4>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<div class="col-sm">
									<div class="mb-3">
									  <label for="" class="form-label">Precio</label>
									  <input onChange={() => CambioPrecio(document.getElementById('precio').value)} type="number" class="form-control" id="precio" />
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Datos como funciona</h4>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<div class="mb-3">
									<label for="" class="form-label">Valoración</label>
									<input onChange={() => CambioValoracion(document.getElementById('valoracion').value)} type="number" max="5" min="1" class="form-control" id="valoracion" />
								</div>
								<div class="mb-3">
								  	<label for="" class="form-label">Votos</label>
								  	<input onChange={() => CambioVotos(document.getElementById('votos').value)} type="text" class="form-control" id="votos" />
								</div>
								<div class="mb-3">
								  	<label for="" class="form-label">Tiempo estimado</label>
								  	<input onChange={() => CambioTiempoEstimado(document.getElementById('tiempoEstimado').value)} type="text" class="form-control" id="tiempoEstimado" />
								</div>
								<div class="mb-3">
								  	<label for="" class="form-label">Tiempo estimado</label>
								  	<input onChange={() => CambioTiempoEstimado(document.getElementById('tiempoEstimado').value)} type="text" class="form-control" id="tiempoEstimado" />
								</div>
								<div class="mb-3">
								  	<label for="" class="form-label">Descargas</label>
								  	<input onChange={() => CambioDescargas(document.getElementById('descargas').value)} type="text" class="form-control" id="descargas" />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<h4 class="tituloPaginaAdmin">Mostrar en mas solicitados</h4>
							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<div class="form-check">
								  <input onClick={ponerEnMasSolicitados} class="form-check-input" type="checkbox" value="" id="solicitado" />
								  <label class="form-check-label" for="solicitado">
								    Mostrar en mas solicitados
								  </label>
								</div>
							</div>
						</div><br/><br/>
						<div class="row">
							<div class="col-sm">
								<button onClick={() => procesarDocumento()} class="btn btn-primary">Guardar</button>
								<br/>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default AdminDocumentosScreen;