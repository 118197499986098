import React, {useState, useEffect} from 'react';
import './documentos-styles.css';
import FlatList from 'flatlist-react';

export function FormCampos({
	tipoDeCampo,
	agregarCampo,
	CambioNombreCampoDeTexto,
	CambioNombreAreaDeTexto,
	CambioNombreSeleccionable,
	opcionesSeleccionable,
	CambioNombreOpcionSeleccionable,
	agregarOpcion,
	quitarOpcion,
	CambioNombreGrupoCasillas,
	CambioNombreCasilla,
	grupoDeCasillas,
	agregarCasilla,
	quitarCasilla,
	CambioNombreCampoDeFecha,
	CambioFecha,
	CambioNombreCampoDeNumero,
	CambioNumero,
}){
	if (tipoDeCampo == 'campoDeTexto') {
		return(
			<>
				<div class="containerFormCampos">
					<div class="mb-3">
					  <label for="" class="form-label labelColorBlanco">Nombre del campo de texto</label>
					  <input onChange={() => CambioNombreCampoDeTexto(document.getElementById('nombreCampoDeTexto').value)} type="text" class="form-control" id="nombreCampoDeTexto" />
					</div>
					<div class="mb-3">
						<button onClick={() => agregarCampo(tipoDeCampo)} class="btn btn-dark">Agregar</button>
					</div>
				</div>
			</>
		);
	}else if (tipoDeCampo == 'areaDeTexto') {
		return(
			<>
				<div class="containerFormCampos">
					<div class="mb-3">
					  <label for="" class="form-label labelColorBlanco">Nombre del area de texto</label>
					  <input onChange={() => CambioNombreAreaDeTexto(document.getElementById('nombreAreaDeTexto').value)} type="text" class="form-control" id="nombreAreaDeTexto" />
					</div>
					<div class="mb-3">
						<button onClick={() => agregarCampo(tipoDeCampo)} class="btn btn-dark">Agregar</button>
					</div>
				</div>
			</>
		);
	}else if (tipoDeCampo == 'seleccionable') {
		return(
			<>
				<div class="containerFormCampos">
					<div class="mb-3">
					  <label for="" class="form-label labelColorBlanco">Nombre del seleccionable</label>
					  <input onChange={() => CambioNombreSeleccionable(document.getElementById('nombreSeleccionable').value)} type="text" class="form-control" id="nombreSeleccionable" />
					</div>
					<div class="containerOpciones">
						<div class="mb-3">
						  <label for="" class="form-label labelColorBlanco">Nombre de la opcion</label>
						  <input onChange={() => CambioNombreOpcionSeleccionable(document.getElementById('nombreOpcionSeleccionable').value)} type="text" class="form-control" id="nombreOpcionSeleccionable" />
						</div>
						<div class="mb-3">
							<button onClick={() => agregarOpcion()} class="btn btn-dark">Agregar Opcion</button>
						</div>
						<div class="mb-3">
							{opcionesSeleccionable.map((item) =>(
								<span class="opcion" id={"opcion-"+item}>{item} <span class="equis" onClick={() => quitarOpcion(item)}>X</span></span>
							))}
						</div>
					</div>
					<div class="mb-3"> <br/>
						<button onClick={() => agregarCampo(tipoDeCampo)} class="btn btn-dark">Agregar</button>
					</div>
				</div>
			</>
		);
	}else if (tipoDeCampo == 'grupoDeCasillas') {
		return(
			<>
				<div class="containerFormCampos">
					<div class="mb-3">
					  <label for="" class="form-label labelColorBlanco">Nombre del grupo de casillas</label>
					  <input onChange={() => CambioNombreGrupoCasillas(document.getElementById('nombreGrupoCasillas').value)} type="text" class="form-control" id="nombreGrupoCasillas" />
					</div>
					<div class="containerOpciones">
						<div class="mb-3">
						  <label for="" class="form-label labelColorBlanco">Nombre de la casilla</label>
						  <input onChange={() => CambioNombreCasilla(document.getElementById('nombreCasilla').value)} type="text" class="form-control" id="nombreCasilla" />
						</div>
						<div class="mb-3">
							<button onClick={() => agregarCasilla()} class="btn btn-dark">Agregar Casilla</button>
						</div>
						<div class="mb-3">
							{grupoDeCasillas.map((item) =>(
								<span class="opcion" id={"casilla-"+item.valor}>{item.nombre} <span class="equis" onClick={() => quitarCasilla(item)}>X</span></span>
							))}
						</div>
					</div>
					<div class="mb-3"> <br/>
						<button onClick={() => agregarCampo(tipoDeCampo)} class="btn btn-dark">Agregar</button>
					</div>
				</div>
			</>
		);
	}else if (tipoDeCampo == 'campoDeFecha') {
		return(
			<>
				<div class="containerFormCampos">
					<div class="mb-3">
					  <label for="" class="form-label labelColorBlanco">Nombre del campo de fecha</label>
					  <input onChange={() => CambioNombreCampoDeFecha(document.getElementById('nombreCampoDeFecha').value)} type="text" class="form-control" id="nombreCampoDeFecha" />
					</div>
					<div class="containerOpciones">
						<div class="mb-3">
						  <label for="" class="form-label labelColorBlanco">Fecha</label>
						  <input onChange={() => CambioFecha(document.getElementById('fecha').value)} type="date" class="form-control" id="fecha" />
						</div>
					</div>
					<div class="mb-3"> <br/>
						<button onClick={() => agregarCampo(tipoDeCampo)} class="btn btn-dark">Agregar</button>
					</div>
				</div>
			</>
		);
	}else if (tipoDeCampo == 'numero') {
		return(
			<>
				<div class="containerFormCampos">
					<div class="mb-3">
					  <label for="" class="form-label labelColorBlanco">Nombre del campo numero</label>
					  <input onChange={() => CambioNombreCampoDeNumero(document.getElementById('nombreCampoDeNumero').value)} type="text" class="form-control" id="nombreCampoDeNumero" />
					</div>
					<div class="containerOpciones">
						<div class="mb-3">
						  <label for="" class="form-label labelColorBlanco">Numero</label>
						  <input onChange={() => CambioNumero(document.getElementById('numero').value)} type="number" class="form-control" id="numero" />
						</div>
					</div>
					<div class="mb-3"> <br/>
						<button onClick={() => agregarCampo(tipoDeCampo)} class="btn btn-dark">Agregar</button>
					</div>
				</div>
			</>
		);
	}else{
		return(
			<></>
		);
	}
}

export function BotonActivo({estado, cambiarEstado}){
	if(estado){
		return(
			<button style={{margin: 2.5,}} onClick={cambiarEstado} class="btn btn-danger">Desactivar</button>
		);
	}else{
		return(
			<button style={{margin: 2.5,}} onClick={cambiarEstado} class="btn btn-success">Activar</button>
		);
	}
}

export function BotonSolicitado({estado, cambiarEstado}){
	if(estado){
		return(
			<button style={{margin: 2.5,}} onClick={cambiarEstado} class="btn btn-danger">No mostrar en mas solicitados</button>
		);
	}else{
		return(
			<button style={{margin: 2.5,}} onClick={cambiarEstado} class="btn btn-success">Mostrar en mas solicitados</button>
		);
	}
}