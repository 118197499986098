import React from 'react';
import FormCupons from './FormCupons';

const AddCupons = () => {
  const handleOnSubmit = (cupon) => {
    //console.log(cupon);
  };

  return (
    <React.Fragment>

      <FormCupons handleOnSubmit={handleOnSubmit} typeaction ="add"/>
    </React.Fragment>
  );
};

export default AddCupons;
