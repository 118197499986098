import React, { useState, useEffect } from 'react';
import './usuarios-styles.css';
import {CheckPlan} from '../planes/planes';

export function InfoPlanUsuario({datosPlan}){

	if (datosPlan != false) {

		return(
			<div class="divPrecios elCienPorCiento">
                <div>
                	<p class="infoPrecio bold">Nombre</p>
                	<p class="infoPrecio bold">Caracteristicas</p>
                    <p class="infoPrecio">
                    	<CheckPlan
							item={{id: '', data: datosPlan.data.caracteristicas.dataPlan,}}
							documentosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.documentosIlimitados, mostrar: 'mostrar'}}
							descuentoDocumentos={{si: datosPlan.data.caracteristicas.dataPlan.descuentoDocumentos, mostrar: ''}}
							documentosLogotipo={{si: datosPlan.data.caracteristicas.dataPlan.documentosLogotipo, mostrar: ''}}
							atencionEspecializada={{si: datosPlan.data.caracteristicas.dataPlan.atencionEspecializada, mostrar: ''}}
							descuentoServicios={{si: datosPlan.data.caracteristicas.dataPlan.descuentoServicios, mostrar: ''}}
							serviciosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.serviciosIlimitados, mostrar: ''}}
							proteccionPersonalAEjecutivos={{si: datosPlan.data.caracteristicas.dataPlan.proteccionPersonalAEjecutivos, mostrar: ''}}
							seguimientoNegocio={{si: datosPlan.data.caracteristicas.dataPlan.seguimientoNegocio, mostrar: ''}}
						/>
                    </p>
                    <p class="infoPrecio">
                    	<CheckPlan
							item={{id: '', data: datosPlan.data.caracteristicas.dataPlan,}}
							documentosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.documentosIlimitados, mostrar: ''}}
							descuentoDocumentos={{si: datosPlan.data.caracteristicas.dataPlan.descuentoDocumentos, mostrar: ''}}
							documentosLogotipo={{si: datosPlan.data.caracteristicas.dataPlan.documentosLogotipo, mostrar: 'mostrar'}}
							atencionEspecializada={{si: datosPlan.data.caracteristicas.dataPlan.atencionEspecializada, mostrar: ''}}
							descuentoServicios={{si: datosPlan.data.caracteristicas.dataPlan.descuentoServicios, mostrar: ''}}
							serviciosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.serviciosIlimitados, mostrar: ''}}
							proteccionPersonalAEjecutivos={{si: datosPlan.data.caracteristicas.dataPlan.proteccionPersonalAEjecutivos, mostrar: ''}}
							seguimientoNegocio={{si: datosPlan.data.caracteristicas.dataPlan.seguimientoNegocio, mostrar: ''}}
						/>
                    </p>
                    <p class="infoPrecio">
                    	<CheckPlan
							item={{id: '', data: datosPlan.data.caracteristicas.dataPlan,}}
							documentosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.documentosIlimitados, mostrar: ''}}
							descuentoDocumentos={{si: datosPlan.data.caracteristicas.dataPlan.descuentoDocumentos, mostrar: ''}}
							documentosLogotipo={{si: datosPlan.data.caracteristicas.dataPlan.documentosLogotipo, mostrar: ''}}
							atencionEspecializada={{si: datosPlan.data.caracteristicas.dataPlan.atencionEspecializada, mostrar: ''}}
							descuentoServicios={{si: datosPlan.data.caracteristicas.dataPlan.descuentoServicios, mostrar: 'mostrar'}}
							serviciosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.serviciosIlimitados, mostrar: ''}}
							proteccionPersonalAEjecutivos={{si: datosPlan.data.caracteristicas.dataPlan.proteccionPersonalAEjecutivos, mostrar: ''}}
							seguimientoNegocio={{si: datosPlan.data.caracteristicas.dataPlan.seguimientoNegocio, mostrar: ''}}
						/>
                    </p>
                    <p class="infoPrecio">
                    	<CheckPlan
							item={{id: '', data: datosPlan.data.caracteristicas.dataPlan,}}
							documentosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.documentosIlimitados, mostrar: ''}}
							descuentoDocumentos={{si: datosPlan.data.caracteristicas.dataPlan.descuentoDocumentos, mostrar: ''}}
							documentosLogotipo={{si: datosPlan.data.caracteristicas.dataPlan.documentosLogotipo, mostrar: ''}}
							atencionEspecializada={{si: datosPlan.data.caracteristicas.dataPlan.atencionEspecializada, mostrar: ''}}
							descuentoServicios={{si: datosPlan.data.caracteristicas.dataPlan.descuentoServicios, mostrar: ''}}
							serviciosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.serviciosIlimitados, mostrar: ''}}
							proteccionPersonalAEjecutivos={{si: datosPlan.data.caracteristicas.dataPlan.proteccionPersonalAEjecutivos, mostrar: 'mostrar'}}
							seguimientoNegocio={{si: datosPlan.data.caracteristicas.dataPlan.seguimientoNegocio, mostrar: ''}}
						/>
                    </p>
                </div>
                <div>
                    <p class="precios">
                    	{datosPlan.data.caracteristicas.dataPlan.nombre}
                    </p>
                    <p class="precios">
                    	<br/>
                    </p>
                    <p class="infoPrecio">
                    	<CheckPlan
							item={{id: '', data: datosPlan.data.caracteristicas.dataPlan,}}
							documentosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.documentosIlimitados, mostrar: ''}}
							descuentoDocumentos={{si: datosPlan.data.caracteristicas.dataPlan.descuentoDocumentos, mostrar: 'mostrar'}}
							documentosLogotipo={{si: datosPlan.data.caracteristicas.dataPlan.documentosLogotipo, mostrar: ''}}
							atencionEspecializada={{si: datosPlan.data.caracteristicas.dataPlan.atencionEspecializada, mostrar: ''}}
							descuentoServicios={{si: datosPlan.data.caracteristicas.dataPlan.descuentoServicios, mostrar: ''}}
							serviciosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.serviciosIlimitados, mostrar: ''}}
							proteccionPersonalAEjecutivos={{si: datosPlan.data.caracteristicas.dataPlan.proteccionPersonalAEjecutivos, mostrar: ''}}
							seguimientoNegocio={{si: datosPlan.data.caracteristicas.dataPlan.seguimientoNegocio, mostrar: ''}}
						/>
                    </p>
                    <p class="infoPrecio">
                    	<CheckPlan
							item={{id: '', data: datosPlan.data.caracteristicas.dataPlan,}}
							documentosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.documentosIlimitados, mostrar: ''}}
							descuentoDocumentos={{si: datosPlan.data.caracteristicas.dataPlan.descuentoDocumentos, mostrar: ''}}
							documentosLogotipo={{si: datosPlan.data.caracteristicas.dataPlan.documentosLogotipo, mostrar: ''}}
							atencionEspecializada={{si: datosPlan.data.caracteristicas.dataPlan.atencionEspecializada, mostrar: 'mostrar'}}
							descuentoServicios={{si: datosPlan.data.caracteristicas.dataPlan.descuentoServicios, mostrar: ''}}
							serviciosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.serviciosIlimitados, mostrar: ''}}
							proteccionPersonalAEjecutivos={{si: datosPlan.data.caracteristicas.dataPlan.proteccionPersonalAEjecutivos, mostrar: ''}}
							seguimientoNegocio={{si: datosPlan.data.caracteristicas.dataPlan.seguimientoNegocio, mostrar: ''}}
						/>
                    </p>
                    <p class="infoPrecio">
                    	<CheckPlan
							item={{id: '', data: datosPlan.data.caracteristicas.dataPlan,}}
							documentosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.documentosIlimitados, mostrar: ''}}
							descuentoDocumentos={{si: datosPlan.data.caracteristicas.dataPlan.descuentoDocumentos, mostrar: ''}}
							documentosLogotipo={{si: datosPlan.data.caracteristicas.dataPlan.documentosLogotipo, mostrar: ''}}
							atencionEspecializada={{si: datosPlan.data.caracteristicas.dataPlan.atencionEspecializada, mostrar: ''}}
							descuentoServicios={{si: datosPlan.data.caracteristicas.dataPlan.descuentoServicios, mostrar: ''}}
							serviciosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.serviciosIlimitados, mostrar: 'mostrar'}}
							proteccionPersonalAEjecutivos={{si: datosPlan.data.caracteristicas.dataPlan.proteccionPersonalAEjecutivos, mostrar: ''}}
							seguimientoNegocio={{si: datosPlan.data.caracteristicas.dataPlan.seguimientoNegocio, mostrar: ''}}
						/>
                    </p>
                    <p class="infoPrecio">
                    	<CheckPlan
							item={{id: '', data: datosPlan.data.caracteristicas.dataPlan,}}
							documentosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.documentosIlimitados, mostrar: ''}}
							descuentoDocumentos={{si: datosPlan.data.caracteristicas.dataPlan.descuentoDocumentos, mostrar: ''}}
							documentosLogotipo={{si: datosPlan.data.caracteristicas.dataPlan.documentosLogotipo, mostrar: ''}}
							atencionEspecializada={{si: datosPlan.data.caracteristicas.dataPlan.atencionEspecializada, mostrar: ''}}
							descuentoServicios={{si: datosPlan.data.caracteristicas.dataPlan.descuentoServicios, mostrar: ''}}
							serviciosIlimitados={{si: datosPlan.data.caracteristicas.dataPlan.serviciosIlimitados, mostrar: ''}}
							proteccionPersonalAEjecutivos={{si: datosPlan.data.caracteristicas.dataPlan.proteccionPersonalAEjecutivos, mostrar: ''}}
							seguimientoNegocio={{si: datosPlan.data.caracteristicas.dataPlan.seguimientoNegocio, mostrar: 'mostrar'}}
						/>
                    </p>
                </div>
            </div>
		);
	}else{

		return(
			<div class="divPrecios elCienPorCiento">
                <div>
                    <p class="infoPrecio bold">No hay plan contratado</p>
                </div>
                <div>
                    <p class="precios"></p>
                </div>
            </div>
		);
	}
}