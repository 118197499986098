import {
	auth, 
	providerFacebook,
	popupSining,
	crearUsuario, 
	db, 
	documento, 
	set, 
	sesionUsuario, 
	leerDatos, 
	cambiarPassword, 
	actualizarPassword,
	actualizarEmail,
	actualizarDoc,
} from '../conex.js';
import {FacebookAuthProvider} from "firebase/auth";

export async function enviarCodigo(telefono){
	const envio = await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/smsmasivos/smsmasivos.php', {
        method: 'POST',
        headers:{
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: "funcion=validacion&code_phone=52&phone_number="+telefono+'&api=hola'
    }).then((response) =>{
        console.log(response);
        return response.json();
    }).then((json) =>{
        console.log(json);
        return json;
    }).catch((error) =>{
        console.log(error);
        return false;
    });

    return envio;
}

export async function smsValidarCodigo(telefono, codigo){
	const envio = await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/smsmasivos/smsmasivos.php', {
        method: 'POST',
        headers:{
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: "funcion=verificacion&code_phone=52&phone_number="+telefono+'&client_verication_code='+codigo+'&api=hola'
    }).then((response) =>{
        console.log(response);
        return response.ok;
    }).catch(() =>{
        return false;
    });

    return envio;
}

export async function resgistrarUsuario(datos){
	let registro = await crearUsuario(auth, datos.email, datos.password).then((userCredential) => {
	    // Signed in
	    const user = userCredential.user;
	    // ...
	    return user;
	})
	.catch((error) => {
	    const errorCode = error.code;
	    const errorMessage = error.message;
	    // ..
	    return false;
	});

	if (registro != false) {

		let usuariosConekta = await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/conekta/crearUsuarioConekta.php', {
	      method: 'POST',
          headers:{
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
          body: 'accion=crear&name='+datos.nombre+'&email='+datos.email+'&phone='+datos.telefono
	    }).then(response =>{
	    	return response.json();
	    }).then(json =>{
	    	console.log(JSON.parse(json));
	    	return JSON.parse(json);
	    })

	    let losDatos = {
			email: datos.email,
			nombre: datos.nombre,
			telefono: datos.telefono,
			rol: datos.rol,
			foto: 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fusuario%2Fperfil.jpg?alt=media&token=d650d435-248f-4da7-a38c-ba26373cd653',
			uid: registro.uid,
			idConekta: usuariosConekta.id,
		};

	    if (datos.rol == 'subAdmin') {
	    	losDatos = {
	    		activo: true,
			  	email: datos.email,
				nombre: datos.nombre,
				telefono: datos.telefono,
				rol: datos.rol,
				foto: 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fusuario%2Fperfil.jpg?alt=media&token=d650d435-248f-4da7-a38c-ba26373cd653',
				uid: registro.uid,
				idConekta: usuariosConekta.id,
				restricciones: datos.restricciones,
			};
	    }

		await set(documento(db, "usuarios", registro.uid), losDatos);

		return {
		  	email: datos.email,
			nombre: datos.nombre,
			telefono: datos.telefono,
			rol: datos.rol,
			foto: 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fusuario%2Fperfil.jpg?alt=media&token=d650d435-248f-4da7-a38c-ba26373cd653',
			uid: registro.uid,
		};
	}else{
		return false;
	}
}

export async function reenviarCodigo(telefono){
	const envio = await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/smsmasivos/smsmasivos.php', {
	  	method: 'POST',
	  	headers:{
	  		"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
	  	},
	  	body: "funcion=reenvio&code_phone=52&phone_number="+telefono+'&api=hola'
	}).then((response) =>{
	  	console.log(response);
	  	return response.ok;
	}).catch(() =>{
	  	return false;
	});

	return envio;
}

export async function iniciarSesion(email, password){
	let usuario = await sesionUsuario(auth, email, password).then((userCredential) => {
	    // Signed in
	    const user = userCredential.user;
	    // ...
	    return user;
	})
	.catch((error) => {
	    const errorCode = error.code;
	    const errorMessage = error.message;
	    return false;
	});
	if (usuario != false) {
		const docRef = documento(db, "usuarios", usuario.uid);
		const docSnap = await leerDatos(docRef);
		if (docSnap.exists()) {
			return docSnap.data();
		}else{
			return false;
		}
	}else{
		return false;
	}
}

export async function recuperarPassword(email){
	let actualizando = await cambiarPassword(auth, email).then(() => {
	    // Password reset email sent!
	    // ..
	    return true;
	})
	.catch((error) => {
	    const errorCode = error.code;
	    const errorMessage = error.message;
	    // ..
	    return false;
	});

	return actualizando;
}

export async function guardarNuevaPassword(newPassword){
	const user = auth.currentUser;
	let actualizacion = await actualizarPassword(user, newPassword).then(() => {
	  return true;
	}).catch((error) => {
	  return false;
	});
	return actualizacion;
}

export async function guardarNuevaEmail(emailNuevo, datos){
	const user = auth.currentUser;
	let actualizacion = await actualizarEmail(user, emailNuevo).then(() => {
	  return true;
	}).catch((error) => {
	  return false;
	});

	if (actualizacion) {

		await actualizarDoc(documento(db, "usuarios", datos.uid), {
		  	email: emailNuevo,
		});

		let datosNuevo = {
		  	email: emailNuevo,
			nombre: datos.nombre,
			telefono: datos.telefono,
			rol: datos.rol,
			foto: datos.foto,
			uid: datos.uid,
			idConekta: datos.idConekta,
		};

		await localStorage.setItem('usuarioLegalizarlo', JSON.stringify(datosNuevo));

		return true;
	}else{
		return false;
	}
}

export async function guardarTelefono(telefono, datos){
	await actualizarDoc(documento(db, "usuarios", datos.uid), {
		telefono: telefono,
	});

	let datosNuevo = {
		email: datos.email,
		nombre: datos.nombre,
		telefono: telefono,
		rol: datos.rol,
		foto: datos.foto,
		uid: datos.uid,
		idConekta: datos.idConekta,
	};

	await localStorage.setItem('usuarioLegalizarlo', JSON.stringify(datosNuevo));

	return true;
}

export async function guardarNombre(nombre, datos){
	await actualizarDoc(documento(db, "usuarios", datos.uid), {
		nombre: nombre,
	});

	let datosNuevo = {
		email: datos.email,
		nombre: nombre,
		telefono: datos.telefono,
		rol: datos.rol,
		foto: datos.foto,
		uid: datos.uid,
		idConekta: datos.idConekta,
	};

	await localStorage.setItem('usuarioLegalizarlo', JSON.stringify(datosNuevo));

	return true;
}

export async function sesionFacebook(){

	const provider = new FacebookAuthProvider();

	let iniciandoSesion = await popupSining(auth, provider)
	.then((result) => {
	    // The signed-in user info.
	    const user = result.user;

	    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
	    const credential = FacebookAuthProvider.credentialFromResult(result);
	    const accessToken = credential.accessToken;

	    // IdP data available using getAdditionalUserInfo(result)
	    // ...

	    return user;
	})
	  .catch((error) => {
	    // Handle Errors here.
	    const errorCode = error.code;
	    const errorMessage = error.message;
	    // The email of the user's account used.
	    const email = error.customData.email;
	    // The AuthCredential type that was used.
	    const credential = FacebookAuthProvider.credentialFromError(error);

	    // ...

	    //return errorMessage;
	    return false;
	});

	if (iniciandoSesion != false) {

		const docRef = documento(db, "usuarios", iniciandoSesion.uid);
		const docSnap = await leerDatos(docRef);
		if (docSnap.exists()) {

			await localStorage.setItem('usuarioLegalizarlo', JSON.stringify(docSnap.data()));
			
			return true;
		}else{

			let usuariosConekta = await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/conekta/crearUsuarioConekta.php', {
			      method: 'POST',
		          headers:{
		            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
		          },
		          body: 'accion=crear&name='+iniciandoSesion.displayName+'&email='+iniciandoSesion.email+'&phone=0000000000'
		    }).then(response =>{
		    	return response.json();
		    }).then(json =>{
		    	console.log(JSON.parse(json));
		    	return JSON.parse(json);
		    })

		    let losDatos = {
				email: iniciandoSesion.email,
				nombre: iniciandoSesion.displayName,
				telefono: '0000000000',
				rol: 'cliente',
				foto: 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fusuario%2Fperfil.jpg?alt=media&token=d650d435-248f-4da7-a38c-ba26373cd653',
				uid: iniciandoSesion.uid,
				idConekta: usuariosConekta.id,
			};
			await set(documento(db, "usuarios", iniciandoSesion.uid), losDatos);

			await localStorage.setItem('usuarioLegalizarlo', JSON.stringify(losDatos));

			return true;
		}
	}else{

		return false;
	}
}