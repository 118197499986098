import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';



export async function guardarCupon(id, data){
	try {

		await set(documento(db, "cupones", id), data);

   	} catch (err) {
    	 alert(err)
   	}
	return true;
}

export async function buscarCupones(){
	//console.log("cargo la funcion")
	let cupones = [];
	let parametro = '';
	/*
	if(rol == 'admin'){
		parametro = donde("nombre", "!=", '');
	}else if(rol == 'cliente'){
		parametro = donde("activo", "==", true);
	}*/

	const q = coleccion(db, "cupones");

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		cupones.push({
			id: doc.id,
			data: doc.data(),
		});
	});
	
	return cupones;
}

export async function editarCupon(id, cupon){

	const washingtonRef = documento(db, "cupones", id);

	await actualizarDoc(washingtonRef, cupon);

	return true;
}
export async function editarDocumento(id, estado){

	let activo = false;
	
	if(estado == false){
		activo = true;
	}

	const washingtonRef = documento(db, "documentos", id);

	await actualizarDoc(washingtonRef, {
		activo: activo,
	});

	return true;
}
