import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function subirImagenServicio(id, imagen){

	const storageRef = referencia(almacenamiento, 'assets/servicios/'+id+'/'+id);

	await subirArchivo(storageRef, imagen);

	let url = await obtenerURL(storageRef);

	return url;  
}

export async function guardarServicio(id, data){
	await set(documento(db, "servicios", id), data);

	return true;
}

export async function buscarServicios(rol, uidAdmin){

	let servicios = [];
	let parametro = '';

	if(rol == 'admin'){
		parametro = donde("uidAdmin", "==", uidAdmin);
	}else if(rol == 'cliente'){
		parametro = donde("activo", "==", true);
	}

	const q = buscar(coleccion(db, "servicios"), parametro);

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		servicios.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return servicios;
}

export async function editarEstadoServicio(id, estado){
	let activo = false;
	
	if(estado == false){
		activo = true;
	}

	const washingtonRef = documento(db, "servicios", id);

	await actualizarDoc(washingtonRef, {
		activo: activo,
	});

	return true;
}

export async function validarHorarios(horarios, dia, mes, ano, idProducto){
	let citas = [];

	const q = buscar(coleccion(db, "citas"), donde("idProducto", "==", idProducto));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		citas.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	let citasDeHoy = [];

	for (let cita of citas) {
		if (cita.data.dia == dia && cita.data.mes == mes.posicion && cita.data.ano == ano) {
			citasDeHoy.push(cita);
		}
	}

	let horariosDisponibles = [];

	let cero = 0;

	for (let horario of horarios) {
		let disponibilidad = await validarDisponibilidad(citasDeHoy, horario);
		if (disponibilidad) {
			horariosDisponibles.push({
				disponibilidad: 'hay',
				horario: horario,
				id: idProducto+'-'+cero+'-dia',
				cero: cero,
				idProducto: idProducto,
				dia: dia, 
				mes: mes.posicion, 
				ano: ano,
			});
		}else{
			horariosDisponibles.push({
				disponibilidad: 'sin',
				horario: horario,
				id: idProducto+'-'+cero+'-dia',
				cero: cero,
				idProducto: idProducto,
				dia: dia, 
				mes: mes.posicion, 
				ano: ano,
			});
		}
		cero++;
	}

	return horariosDisponibles;
}

let validarDisponibilidad = async function(citas, horario){

	let disponibilidad = true;

	for (let cita of citas) {
		if (horario == cita.data.horario) {
			disponibilidad = false;
		}
	}

	return disponibilidad;
}

export async function guardarCita(uid, dataCita, textoDataCita, idCompra, id,email){
	let inievent1  = dataCita.dia+"-"+dataCita.mes+"-"+dataCita.ano;
	let horaevent1 = dataCita.horario;
	let texto1     = textoDataCita;
	let email1     = email;
	let datasend      = {inievent:inievent1,horaevent:horaevent1,texto:texto1,type:'meet',email:email1,subject:'holamundo',message:'nada de nada'};
	let resp;
    //const hggg= await fetch('http://legalizarlofirebase.com/backend-legalizarlo/citas/testsendgridcalendar.php', {
    const hggg= await fetch('https://mirrordevelop.legalizarlo.com/backend-legalizarlo/citas/testsendgridcalendar.php', {
		method: 'POST',
	    headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
	    },
        body: JSON.stringify(datasend) 
	})
     .then((response) => {
     		//console.log(response);
     		return response.json();
 		})
     .then((json)=> {
     	//console.log(json);
     	resp = json;
 		})
     .catch((error)=> console.log(error));

     if(resp.status == 200)
     {
		let data = {
			disponibilidad: dataCita.disponibilidad,
			horario: dataCita.horario,
			idHorario: dataCita.id,
			idProducto: dataCita.idProducto,
			dia: dataCita.dia,
			mes: dataCita.mes,
			ano: dataCita.ano,
			uid: dataCita.uid,
			textoDataCita: textoDataCita,
			idCompra: idCompra,
			linkmeet:resp.linkmeet
		};

		await set(documento(db, "citas", id), data);

		const washingtonRef = documento(db, "compras", idCompra);

		await actualizarDoc(washingtonRef, {
			caracteristicas:{
				editado: true,
				fecha: textoDataCita,
				idCita: id,
				linkmeet:resp.linkmeet
			},
		});
		return resp.linkmeet;
     }
	return false;
}

export async function editarSolicitado(id, estado){

	let activo = false;
	
	if(estado == false){
		activo = true;
	}

	const washingtonRef = documento(db, "servicios", id);

	await actualizarDoc(washingtonRef, {
		solicitado: activo,
	});

	return true;
}