export const meses = [
	{
		nombre: 'January',
		mes: 'Enero',
		dias: 31,
		posicion: 0,
		numeroMes: '01',
	},
	{
		nombre: 'February',
		mes: 'Febrero',
		dias: 28,
		posicion: 1,
		numeroMes: '02', 
	},
	{
		nombre: 'March',
		mes: 'Marzo',
		dias: 31,
		posicion: 2,
		numeroMes: '03',
	},
	{
		nombre: 'April',
		mes: 'Abril',
		dias: 30,
		posicion: 3,
		numeroMes: '04',
	},
	{
		nombre: 'May',
		mes: 'Mayo',
		dias: 31,
		posicion: 4,
		numeroMes: '05',
	},
	{
		nombre: 'June',
		mes: 'Junio',
		dias: 30,
		posicion: 5,
		numeroMes: '06',
	},
	{
		nombre: 'July',
		mes: 'Julio',
		dias: 31,
		posicion: 6,
		numeroMes: '07',
	},
	{
		nombre: 'August',
		mes: 'Agosto',
		dias: 31,
		posicion: 7,
		numeroMes: '08',
	},
	{
		nombre: 'September',
		mes: 'Septiembre',
		dias: 30,
		posicion: 8,
		numeroMes: '09',
	},
	{
		nombre: 'October',
		mes: 'Octubre',
		dias: 31,
		posicion: 9,
		numeroMes: '10',
	},
	{
		nombre: 'November',
		mes: 'Noviembre',
		dias: 30,
		posicion: 10,
		numeroMes: '11',
	},
	{
		nombre: 'December',
		mes: 'Diciembre',
		dias: 31,
		posicion: 11,
		numeroMes: '12',
	},
];

export const dias = [
	{
		dia: 'Domingo',
		inicial: 'D',
	},
	{
		dia: 'Lunes',
		inicial: 'L',
	},
	{
		dia: 'Martes',
		inicial: 'M',
	},
	{
		dia: 'Miercoles',
		inicial: 'M',
	},
	{
		dia: 'Jueves',
		inicial: 'J',
	},
	{
		dia: 'Viernes',
		inicial: 'V',
	},
	{
		dia: 'Sabado',
		inicial: 'S',
	},
];