import React, { useState, useEffect } from 'react';
import './agendar-styles.css';
import Swal from 'sweetalert2';
import {verEstaCompra} from '../../../backend/editarDocumento/editarDocumento';
import {meses, dias} from '../../../assets/datosCalendario';
import {validarHorarios, guardarCita} from '../../../backend/servicios/servicios';
import {MostrarDia, ListaDeHorarios} from '../../../components/agendar/agendar';
import {Link} from 'react-router-dom';

function AgendarScreen() {

  const [pantalla, CambioPantalla] = useState('');
  const [rolVisto, CambioRolVisto] = useState(false);
  const [datosUsuario, CambioDatosUsuario] = useState(false);
  const [calendarioHecho, CambioCalendarioHecho] = useState(false);
  const [numeroMes, CambioNumeroMes] = useState(0);
  const [mesActual, CambioMesActual] = useState('');
  const [anoActual, CambioAnoActual] = useState('');
  const [diaActual, CambioDiaActual] = useState('');
  const [diaSemanaActual, CambioDiaSemanaActual] = useState('');
  const [diasMesActual, CambioDiasMesActual] = useState([]);
  const [compra, CambioCompra] = useState('');
  const [producto, CambioProducto] = useState('');
  const [nombreProducto, CambioNombreProducto] = useState('');
  const [fechaMinima, CambioFechaMinima] = useState('');
  const [irAnterior, CambioIrAnterior] = useState(false);
  const [diaDeseleccionado, CambioDiaDeseleccionado] = useState('');
  const [losHorarios, CambioHorarios] = useState([]);
  const [dataCita, CambioDataCita] = useState(false);
  const [textoDataCita, CambioTextoDataCita] = useState('Pendiente');
  const [horarioAnteriro, CambioHorarioAnterior] = useState('');
  const [urlGoogle, cambioUrlGoogle] = useState('');

  useEffect(() =>{
    if (rolVisto == false) {
      verRol();
    }
    if (calendarioHecho == false && datosUsuario != false) {
      construirCalendario();
    }
  });

  const verRol = async function(){
    let usuario = await localStorage.getItem('usuarioLegalizarlo');
      if (usuario != null) {
          let dataUser = JSON.parse(usuario);
          if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {
            window.location.href = '/admin/home';
          }else{
            CambioDatosUsuario(dataUser);
            console.log('ejecutando...');
            CambioRolVisto(true);
          }
      }else{
        window.location.href = '/';
      }
  }

  const construirCalendario = async function(){
    let idAgenda = await localStorage.getItem('idAgenda');
    if (idAgenda != null) {

      let dataIdAgenda = JSON.parse(idAgenda);

      document.getElementById('estilosMenu').innerHTML = '.navbar>.container-fluid{'+
          'justify-content: center;'+
        '}'+
        '.itemsLegalizarloMenu{'+
        'display: none;'+
        '}';

      let date = new Date();
      let dia = date.getDate();
      let diaSemana = date.getDay();
      let mes = date.getMonth();
      let ano = date.getFullYear();

      let mesMinimo = mes;
      let anoMinimo = ano;

      CambioFechaMinima({
        mesMinimo: mesMinimo,
        anoMinimo: ano,
      });

      verEstaCompra(dataIdAgenda.idAgenda, dataIdAgenda.tipo).then(response =>{
        if (response != false) {
                    
          CambioCompra(response.compra);
          CambioProducto(response.producto);
          CambioNombreProducto(response.producto.data.nombre);
          establecerCalendario(response.producto, dia, diaSemana, mes, ano);
        }else{
          window.location.href = '/'; 
        }
      });

      CambioCalendarioHecho(true);
    }else{
      window.location.href = '/';
    }
  }

  const establecerCalendario  = async function(elProducto, dia, diaSemana, mes, ano){
    
    CambioMesActual(meses[mes]);
    CambioAnoActual(ano);
    CambioDiaActual(dia);
    CambioDiaSemanaActual(dias[diaSemana]);

    let diasDisponibilidad = elProducto.data.diasDisponibilidad;

    for (let disponible of diasDisponibilidad) {
      document.getElementById('estilosCalendario').innerHTML += '.hayDisponibilidad-'+disponible.posicionDia+'{'+
        'color: #1a8fed;'+
        'font-family: "Avenir Familia", Sans-serif;'+
      '}'
    }

    let diasDelMes = meses[mes].dias;

    let calculoDias = [];

    let Xmas952 = new Date(meses[mes].nombre+' 1, '+ano+' 00:00:00');
    let weekday2 = Xmas952.getDay() - 1; console.log(weekday2)

    for (var cero = 0; cero <= weekday2; cero++) {
        calculoDias.push({
          disponibilidad: 'sin',
          clase: 'sinDisponibilidad',
          nuemroDia: ' ',
          idDia: '',
        });
    }

    let  lugaresDias = [];

    for (var i = 1; i <= diasDelMes; i++) {
      let Xmas95 = new Date(meses[mes].nombre+' '+i+', '+ano+' 00:00:00');
      let weekday = Xmas95.getDay();
      lugaresDias.push(weekday);
    }

    let uno = 1;

    for (let i2 = 0; i2 <= diasDelMes - 1; i2++) {
      if (i2 >= dia) {
        calculoDias.push({
          disponibilidad: 'hay',
          clase: 'sinDisponibilidad hayDisponibilidad-'+lugaresDias[i2],
          nuemroDia: uno,
          lugarSemana: lugaresDias[i2],
          idDia: elProducto.id+'-'+uno,
        });
      }else{
        calculoDias.push({
          disponibilidad: 'sin',
          clase: 'sinDisponibilidad',
          nuemroDia: uno,
          lugarSemana: lugaresDias[i2],
          idDia: elProducto.id+'-'+uno,
        });
      }
      uno++;
    }

    if (diaDeseleccionado != '') {
      document.getElementById(elProducto.id+'-'+diaDeseleccionado).classList.remove('diaSeleccionado');
    }

    CambioDiasMesActual(calculoDias);
  }

  const pasarAOtroMes = async function(proseguir){

      CambioHorarios([]);

      if (proseguir == 'anterior') {

        let sumaMes = mesActual.posicion - 1;
        let sumaAno = anoActual;
        if (sumaMes == -1) {
          sumaMes = 11;
          sumaAno = anoActual - 1;
        }

        let mesYAno = {
          mesMinimo: sumaMes,
          anoMinimo: sumaAno,
        };

        let Xmas952 = new Date(meses[sumaMes].nombre+' 1, '+sumaAno+' 00:00:00');
        let weekday2 = Xmas952.getDay();

        if (mesYAno.mesMinimo == fechaMinima.mesMinimo && mesYAno.anoMinimo == fechaMinima.anoMinimo) {
          CambioIrAnterior(false);
          document.getElementById('mesMinimo').classList.remove('flechaActiva');
          establecerCalendario(producto, 1, weekday2, sumaMes, sumaAno);
        }else{
          if (irAnterior) {
            document.getElementById('estilosCalendario').innerHTML = '';
            establecerCalendario(producto, 1, weekday2, sumaMes, sumaAno);
          }
        }

        
      }else if (proseguir == 'siguiente') {

        document.getElementById('mesMinimo').classList.add('flechaActiva');

        let sumaMes = mesActual.posicion + 1;
        let sumaAno = anoActual;
        if (sumaMes >= 12) {
          sumaMes = 0;
          sumaAno = anoActual + 1;
        }

        let Xmas952 = new Date(meses[sumaMes].nombre+' 1, '+sumaAno+' 00:00:00');
        let weekday2 = Xmas952.getDay();

        document.getElementById('estilosCalendario').innerHTML = '';
        establecerCalendario(producto, 1, weekday2, sumaMes, sumaAno);

        CambioIrAnterior(true);
      }
  }

  const seleccionarDia = async function(elDia){
    if (diaDeseleccionado != '') {
      document.getElementById(producto.id+'-'+diaDeseleccionado).classList.remove('diaSeleccionado');
    }
    document.getElementById(producto.id+'-'+elDia.nuemroDia).classList.add('diaSeleccionado');
    CambioDiaDeseleccionado(elDia.nuemroDia);

    let lugar = elDia.lugarSemana;
    let diasDisponibilidad = producto.data.diasDisponibilidad;
    let dataDia = false;
    for (let diaDisponible of diasDisponibilidad) {
      if (elDia.lugarSemana == diaDisponible.posicionDia) {
        dataDia = diaDisponible;
      }
    }
    if (dataDia != false) {
      
      let desde = dataDia.desde.slice(0, -3);
      let hasta = dataDia.hasta.slice(0, -3);
      let minutos = dataDia.hasta.slice(3, 5);
      let horarios = []; console.log(parseInt(hasta));

      for (var i = parseInt(desde); i <= 12; i++) {
        if (i >= 10) {
          if (i == 12) {
            horarios.push(i+':'+minutos+' PM');
          }else{
            horarios.push(i+':'+minutos+' AM');
          }
        }else{
          horarios.push('0'+i+':'+minutos+' AM');
        }
      }

      for (var i2 = 1; i2 <= parseInt(hasta); i2++) {
        if (i2 >= 10) {
          horarios.push(i2+':'+minutos+' PM');
        }else{
          horarios.push('0'+i2+':'+minutos+' PM');
        }
      }

      let consultaHorario = await validarHorarios(horarios, elDia.nuemroDia, mesActual, anoActual, producto.id);

      CambioHorarios(consultaHorario);
    }else{
      Swal.fire({
        title: 'No hay disponibilidad.',
        icon: 'info',
      });
      CambioHorarios([]);
    }
  }

  const seleccionarHorario = async function(horario){

    if (horario.disponibilidad == 'sin') {
      Swal.fire({
        title: 'No hay disponibilidad.',
        icon: 'info',
      });
    }else{
      let data = {
        disponibilidad: horario.disponibilidad,
        horario: horario.horario,
        id: horario.id,
        idProducto: horario.idProducto,
        dia: horario.dia, 
        mes: horario.mes, 
        ano: horario.ano,
        uid: datosUsuario.uid,
      };
  /*console.log(data)
  console.log(datosUsuario);*/
      CambioDataCita(data);
      document.getElementById('pendiente').classList.add('citado');

      let Xmas952 = new Date(meses[data.mes].nombre+' '+data.dia+', '+anoActual+' 00:00:00');
      let weekday2 = Xmas952.getDay();

      CambioTextoDataCita(dias[weekday2].dia+' '+data.dia+' de '+meses[data.mes].mes+' de '+anoActual+' | '+data.horario);
      
      if (horarioAnteriro != '') {
        document.getElementById(data.id).classList.add('horarioSeleccionado');
        CambioHorarioAnterior(data.id);
        document.getElementById(horarioAnteriro).classList.remove('horarioSeleccionado');
      }else{
        document.getElementById(data.id).classList.add('horarioSeleccionado');
        document.getElementById('noAgendar').classList.remove('noAgendar');
        CambioHorarioAnterior(data.id);
      }
    }
  }

  const procesarCita = async function(){

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    var idNuevo = "";
      for (var i2=0; i2<20; i2++) idNuevo +=characters.charAt(Math.floor(Math.random()*characters.length));

    if (dataCita != false) {
      let esp = await guardarCita(datosUsuario.uid, dataCita, textoDataCita, compra.id, idNuevo,datosUsuario.email).then((response) =>{
        
        Swal.close();

        CambioPantalla('agendado');
        //console.log(response);
        cambioUrlGoogle(response);
      });
    }
  }

  const irADondeDice = async function(dice){
    window.location.href = dice;
  }

  if (pantalla == 'agendado') {
    return(
        <section class="seccion1Carrito">
          <style id="estilosMenu">

          </style>
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <h5 class="mensajePagoFinaliza">Nos vemos pronto</h5>
                <h1 class="tituloCarrito">¡Llamada agendada!</h1>
                <p class="parrafoFinal">
                  ¡Genia! Estamos ansisos de conocer tu proyecto e involucrarnos en ello. Sabemos que el mundo<br/>
                  jurídico puede ser agotador. Te recomendamod que prepares tu dudas puntuales para poder<br/>
                  aporvechar mejor tu proxima llamada. <strong>¡Ah! Tenviamos a tu correo electrónico todos los<br/>
                  datos para acceder a tu cita. ¡Nos vemos pronto!</strong>
                </p>
                <br />
                <br />
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <div class="datosFinalesCita">
                  <h6 class="textoCita">{textoDataCita} </h6>
                  <h6 class="textoCita"> Enlace a la reunión: <a href='{urlGoogle}'>{urlGoogle}</a></h6>
                </div>
              </div>
            </div><br/><br/>

            <div class="row">
              <div class="col-sm">
                <h6 class="textoCita">
                  NOTA: Revisa nuestra lista de documentos que necesitarás para poder<br/>
                  realizar tu {nombreProducto}. {/*<Link to="/documentos" class="linkDocs">Consulta el listado aquí</Link>*/}
                </h6>
              </div>
            </div><br/><br/>

            <div class="hrFinal"></div>
            <br />
            <div class="row">
              <div class="col-sm">
                <h1 class="tituloCarrito">¿Que Sigue?</h1>
                <br />
                <div class="opcionesFinales">
                  <div onClick={() => irADondeDice('/cliente/home')} class="elegirFinal">
                    <div class="tipoOpcionYNombre">
                      <div class="circulo">
                      </div>
                      <span class="nombreOpcion">Ingresa a tu panel de usuario</span>
                    </div>
                  </div>
                  {/*<div class="elegirFinal">
                    <div class="tipoOpcionYNombre">
                      <div class="circulo">
                      </div>
                      <span class="nombreOpcion">Solicita tu factura</span>
                    </div>
                  </div>*/}
                </div>
                <br />
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <p class="parrafoNecesitas">¿Necesitas ayuda? <a class="negro" href="https://wa.me/message/E4NL5UXQR3ASA1" target="_blank">Contactanos</a></p>
              </div>
            </div>
          </div>
        </section>
      );
  }else{
    return (
      <section class="seccion1Carrito">
        <style id="estilosMenu">

        </style>
        <style id="estilosCalendario">
        </style>
        <div class="container">
          <div class="row">
            <div class="col-sm">
              <h1 class="tituloCarrito">Agendar Llamada</h1><br />
            </div>
          </div><br />

          <div class="row">
            <div class="col-sm">
              <div class="formaDePago paddingCuadro width600">
                <h5 class="tituloAgenda">Contacta a un abogado para<br/>comenzar con tu {nombreProducto}</h5>
                <span class="selecionHoraFecha">Seleccione una fecha y hora</span>
                <div class="puntosDisponibilidad">

                  <div class="puntoDisponibilidad">
                    <div class="puntoAzul"></div>
                    <span class="disponibilidad">Hay disponibilidad</span>
                  </div>

                  <div class="puntoDisponibilidad">
                    <div class="puntoMarino"></div>
                    <span class="disponibilidad">Poca disponibilidad</span>
                  </div>

                  <div class="puntoDisponibilidad">
                    <div class="puntoGris"></div>
                    <span class="disponibilidad">Sin disponibilidad</span>
                  </div>

                </div>

                <div class="hrFinal"></div><br/><br/>

                <div class="row">
                  <div class="col-sm-8">

                    <div class="mes">
                      <span onClick={() => pasarAOtroMes('anterior')} id="mesMinimo" class="flecha">{'<'}</span>
                      <span class="textoMes">{mesActual.mes} {anoActual}</span>
                      <span onClick={() => pasarAOtroMes('siguiente')} class="flecha flechaActiva">{'>'}</span>
                    </div>

                    <div class="diasDelMes">

                    
                      <span class="inicialSemama">DO</span>
                      <span class="inicialSemama">LU</span>
                      <span class="inicialSemama">MA</span>
                      <span class="inicialSemama">MI</span>
                      <span class="inicialSemama">JU</span>
                      <span class="inicialSemama">VI</span> 
                      <span class="inicialSemama">SA</span>

                      {diasMesActual.map((item) =>(
                        <MostrarDia
                          item={item}
                          seleccionarDia={seleccionarDia}
                        />
                      ))}

                    </div>

                  </div>
                  <div class="col-sm-4 flex">
                    <div class="hrVertical"></div>
                    <div class="listaDeHorarios">
                      <ListaDeHorarios
                        horarios={losHorarios}
                        seleccionarHorario={seleccionarHorario}
                      />
                    </div>
                  </div>
                </div><br/><br/>

                <div class="hrFinal"></div><br/><br/>

                <h6 class="dataCita">Tu fecha y hora: <span id="pendiente" class="pendiente">{textoDataCita}</span></h6>
              </div>
            </div>
          </div>

          <div class="row">
              <div class="col-sm">
                <div class="divAccionPago">
                  <Link to="/cliente/home" class="atrasPago">Agendar después</Link>
                  <button onClick={() => procesarCita()} class="terminarPago noAgendar" id="noAgendar">Agendar</button>
                </div>
              </div>
            </div>
        </div>
      </section>
    );
  }
}

export default AgendarScreen;
