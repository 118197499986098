import React, {useState} from 'react';

function Login ({
	pantalla, 
	imgLogin, 
	cerrarModal, 
	onChangeLoginEmail, 
	onChangeLoginPassword, 
	cambiarARegistro, 
	cambiarALogin, 
	onChangeRegistroEmail, 
	onChangeRegistroPassword, 
	onChangeConfirmPassword, 
	onChangeRegistroNombre, 
	onChangeTelefono, 
	onChangeCodigo, 
	codigoSms, 
	validarCodigo, 
	accionReenvio, 
	procesarLogin, 
	mostrarRecuperacion, 
	onChangeRecuperarEmail, 
	procesarRecuperar,
	procesarFacebook
}){
	if (pantalla == 'login') {
		return(
			<div class="container" id="formAuth">
				<div class="row">
					<div class="containerImgLogin">
						<img class="imgLogin" src={imgLogin} />
					</div>
					<div class="col-sm alinearConImgLogin">
						<div class="divBotonCerrar">
							<span onClick={cerrarModal} class="xCerrar"><svg xmlnsSvg="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" tag="i" class="v-icon notranslate v-theme--light v-icon--size-default iconify iconify--tabler" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 6L6 18M6 6l12 12"></path></svg></span>
						</div>
						<h1 class="tituloLogin">Iniciar Sesión</h1>
						<div onClick={procesarFacebook} class="botonfacebook">
							<i aria-hidden="true" class="fab fa-facebook-f iconMargen"></i>
							Iniciar con Facebook
						</div>
						<h5 class="subTituloLogin">——— Logueate con tu email ———</h5>
						<input id="loginEmail" placeholder="Email" type="text" onChange={onChangeLoginEmail} class="inputLogin" />
						<input id="loginPassword" placeholder="Contraseña" type="password" onChange={onChangeLoginPassword} class="inputLogin" />
						<button onClick={procesarLogin} class="botonSesionEmail">Iniciar Sesión</button>
						<a onClick={mostrarRecuperacion} class="botonRecuperar" href="#">Recuperar contraseña</a>
						<span class="textoRegistro">¿No tienes cuenta? <a href="#" onClick={cambiarARegistro} class="botonRegistro">Crea una cuenta</a></span>
					</div>
				</div>
			</div>
		);
	}else if (pantalla == 'registro') {
		return(
			<div class="container" id="formAuth">
				<div class="row">
					<div class="col-sm alinearConImgLogin">
						<div class="divBotonCerrar">
							<span onClick={cerrarModal} class="xCerrar"><svg xmlnsSvg="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" tag="i" class="v-icon notranslate v-theme--light v-icon--size-default iconify iconify--tabler" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 6L6 18M6 6l12 12"></path></svg></span>
						</div>
						<h1 class="tituloLogin">Registrarse</h1>
						<div onClick={procesarFacebook} class="botonfacebook">
							<i aria-hidden="true" class="fab fa-facebook-f iconMargen"></i>
							Registrate con facebook
						</div>
						<h5 class="subTituloLogin">——— Registro con tu email ———</h5>
						<input id="registroEmail" placeholder="Email" type="text" onChange={onChangeRegistroEmail} class="inputLogin" />
						<input id="registroPassword" placeholder="Contraseña" type="password" onChange={onChangeRegistroPassword} class="inputLogin" />
						<input id="confirmPassword" placeholder="Confirme su contraseña" type="password" onChange={onChangeConfirmPassword} class="inputLogin" />
						<input id="registroNombre" placeholder="Nombre" type="text" onChange={onChangeRegistroNombre} class="inputLogin" />
						
						<div class="input-group">
							<input id="telefono" placeholder="Telefono" type="text" onChange={onChangeTelefono} class="inputLogin inputConBoton" />
							<button class="botonEnviar" onClick={codigoSms}>Enviar</button>
						</div>
						<span class="botonReenviar" onClick={accionReenvio}>Reenviar Codigo</span>
						<br/>
						<div id="mensajeCodigo"></div>

						<div class="input-group">
							<input id="codigo" placeholder="Codigo" type="text" onChange={onChangeCodigo} class="inputLogin inputConCodigo" />
							<button onClick={validarCodigo} class="botonEnviar">Comprobar</button>
						</div>

						<span class="textoRegistro">¿Ya tienes cuenta? <a href="#" onClick={cambiarALogin} class="botonRegistro inputConCodigo">Iniciar Sesión</a></span>
					</div>
					<div class="containerImgLogin">
						<img class="imgRegistro" src={imgLogin} />
					</div>
				</div>
			</div>
		);
	}else if (pantalla == 'recuperarPassword') {
		return(
			<div class="container" id="formAuth">
				<div class="row">
					<div class="col-sm alinearConImgLogin">
						<div class="divBotonCerrar">
							<span onClick={cerrarModal} class="xCerrar"><svg xmlnsSvg="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" tag="i" class="v-icon notranslate v-theme--light v-icon--size-default iconify iconify--tabler" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 6L6 18M6 6l12 12"></path></svg></span>
						</div>
						<h1 class="tituloLogin">Recuperar contraseña</h1>
						<input id="recuperarEmail" placeholder="Email" type="text" onChange={onChangeRecuperarEmail} class="inputLogin inputRecuperar" />
						<button onClick={procesarRecuperar} class="botonSesionEmail">Enviar</button>
					</div>
				</div>
			</div>
		);
	}else if (pantalla == 'loginCarrito') {
		return(
			<div class="container" id="formAuth">
				<div class="row">
					<div class="col-sm alinearConImgLogin">
						<h1 class="tituloLogin">Iniciar Sesión</h1>
						<div class="botonfacebook">
							<i aria-hidden="true" class="fab fa-facebook-f iconMargen"></i>
							Iniciar con Facebook
						</div>
						<h5 class="subTituloLogin">——— Logueate con tu email ———</h5>
						<input id="loginEmail" placeholder="Email" type="text" onChange={onChangeLoginEmail} class="inputLogin" />
						<input id="loginPassword" placeholder="Contraseña" type="password" onChange={onChangeLoginPassword} class="inputLogin" />
						<button onClick={procesarLogin} class="botonSesionEmail">Iniciar Sesión</button>
						<a onClick={mostrarRecuperacion} class="botonRecuperar" href="#">Recuperar contraseña</a>
						<span class="textoRegistro">¿No tienes cuenta? <a href="#" onClick={cambiarARegistro} class="botonRegistro">Crea una cuenta</a></span>
					</div>
				</div>
			</div>
		);
	}else if (pantalla == 'registroCarrito') {
		return(
			<div class="container" id="formAuth">
				<div class="row">
					<div class="col-sm alinearConImgLogin">
						<h1 class="tituloLogin">Registrarse</h1>
						<div class="botonfacebook">
							<i aria-hidden="true" class="fab fa-facebook-f iconMargen"></i>
							Registrate con facebook
						</div>
						<h5 class="subTituloLogin">——— Registro con tu email ———</h5>
						<input id="registroEmail" placeholder="Email" type="text" onChange={onChangeRegistroEmail} class="inputLogin" />
						<input id="registroPassword" placeholder="Contraseña" type="password" onChange={onChangeRegistroPassword} class="inputLogin" />
						<input id="confirmPassword" placeholder="Confirme su contraseña" type="password" onChange={onChangeConfirmPassword} class="inputLogin" />
						<input id="registroNombre" placeholder="Nombre" type="text" onChange={onChangeRegistroNombre} class="inputLogin" />
						
						<div class="input-group2">
							<input id="telefono" placeholder="Telefono" type="text" onChange={onChangeTelefono} class="inputLogin inputConBoton" />
							<button class="botonEnviar" onClick={codigoSms}>Enviar</button>
						</div>
						<span class="botonReenviar" onClick={accionReenvio}>Reenviar Codigo</span>
						<br/>
						<div id="mensajeCodigo"></div>

						<div class="input-group2">
							<input id="codigo" placeholder="Codigo" type="text" onChange={onChangeCodigo} class="inputLogin inputConCodigo" />
							<button onClick={validarCodigo} class="botonEnviar">Comprobar</button>
						</div>

						<span class="textoRegistro">¿Ya tienes cuenta? <a href="#" onClick={cambiarALogin} class="botonRegistro inputConCodigo">Iniciar Sesión</a></span>
					</div>
				</div>
			</div>
		);
	}else if (pantalla == 'recuperarPasswordCarrito') {
		return(
			<div class="container" id="formAuth">
				<div class="row">
					<div class="col-sm alinearConImgLogin">
						<h1 class="tituloLogin">Recuperar contraseña</h1>
						<input id="recuperarEmail" placeholder="Email" type="text" onChange={onChangeRecuperarEmail} class="inputLogin inputRecuperar" />
						<button onClick={procesarRecuperar} class="botonSesionEmail">Enviar</button>
						<a onClick={cambiarALogin} class="botonRecuperar" href="#">Cancelar</a>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
