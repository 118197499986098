import React, {useState, useEffect} from 'react';
import './planes-styles.css';
import {fondoSeccion1Planes, puntosMorados, ahorros, servicioIntegral, respaldoLegal, atencionPersonal} from '../../assets/imgs.js';
import {buscarPlanes} from '../../backend/planes/planes';
import {ListaDePlanes} from '../../components/planes/planes';

function PlanesScreen(){

	const [tipoPlan, CambioTipoPlan] = useState('mensual');
	const [busquedaPlanes, CambioBusquedaPlanes] = useState(false);
	const [planesMensuales, CambioPlanesMensuales] = useState([]);
	const [planesAnuales, CambioPlanesAnuales] = useState([]);

	useEffect(() =>{

		if (busquedaPlanes == false) {

			buscarPlanes('cliente').then(response =>{

				let mensuales = [];
				let anuales = [];

				for (let respon of response) {
					if (respon.data.tipo == 'mensual') {
						mensuales.push(respon);
					}else if (respon.data.tipo == 'anual') {
						anuales.push(respon);
					}
				}

		        CambioPlanesMensuales(mensuales.sort(function(a, b) { 
					a = a.data.lugar; 
					b = b.data.lugar; 
					return a>b ? -1 : a<b ? 1 : 0; 
				}));
		        CambioPlanesAnuales(anuales.sort(function(a, b) { 
					a = a.data.lugar; 
					b = b.data.lugar; 
					return a>b ? -1 : a<b ? 1 : 0; 
				}));
		        CambioBusquedaPlanes(true);
		    })
		}
	});

	const seleccionarTipoPlan = async function(tipo){

		if (tipo == 'mensual') {

			document.getElementById('mensual').classList.add('opcionTipoPlanActivo');
			document.getElementById('mensualText').classList.add('textoOpcionTipoPlanActivo');
			document.getElementById('anual').classList.remove('opcionTipoPlanActivo');
			document.getElementById('anualText').classList.remove('textoOpcionTipoPlanActivo');
		}else if (tipo == 'anual') {

			document.getElementById('mensual').classList.remove('opcionTipoPlanActivo');
			document.getElementById('mensualText').classList.remove('textoOpcionTipoPlanActivo');
			document.getElementById('anual').classList.add('opcionTipoPlanActivo');
			document.getElementById('anualText').classList.add('textoOpcionTipoPlanActivo');
		}

		CambioTipoPlan(tipo);
	}

	const comprarPlan = async function(item){

		let usuario = await localStorage.getItem('usuarioLegalizarlo');

		if (usuario != null) {
			let dataUser = JSON.parse(usuario);

			if (dataUser.datosActualizados) {

				let data = {
					idProducto: item.id,
					tipoDeCompra: 'planes',
				};
				await localStorage.setItem('carrito', JSON.stringify(data));

				window.location.href = '/carrito';
			}else{

			    await localStorage.setItem('alertActualizar', 'alert');

			    window.location.href = '/cliente/home';
			}
		}else{
			let data = {
				idProducto: item.id,
				tipoDeCompra: 'planes',
			};
			await localStorage.setItem('carrito', JSON.stringify(data));

			window.location.href = '/carrito';
		}
	}

	return(
		<div class="containerDocs">
			<section class="seccion1Docs fondoSeccion1Planes">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h1 class="tituloSeccionDocs">
								Tu equipo jurídico<br/>
								al alcance de un clic
							</h1>
						</div>
					</div>
					<div class="row">
						<div class="col-sm" style={{display: 'flex', justifyContent: 'center',}}>
							<p class="parrafoDocs" style={{textAlign: 'left', width: 'auto',}}>
								Genera ahorros consistentes sin abandonar tu protección jurí. Nos quebramos la<br/>
								cabeza para armar planes que cubran lo más importantw de tu negocio. Te<br/>
								ofrecemos 3 paquetes para que elijas lo más conviene a tu empresa.<br/><br/>
								<span style={{fontWeight: 'bold',}}>Paga mensualmente o por anualidad.</span>
							</p>
						</div>
					</div>
				</div>
			</section>

			<section class="seccion2Planes">
				<div class="container">
					<div class="row">
						<div class="col-sm">

							<div class="eleccionPlan">
								<div onClick={() => seleccionarTipoPlan('mensual')} id="mensual" class="opcionTipoPlan borderRedondoIzquierdo opcionTipoPlanActivo">
									<span id="mensualText" class="textoOpcionTipoPlan textoOpcionTipoPlanActivo">Plan Mensual</span>
								</div>
								<div onClick={() => seleccionarTipoPlan('anual')} id="anual" class="opcionTipoPlan borderRedondoDerecho">
									<span id="anualText" class="textoOpcionTipoPlan">Plan Anual</span>
								</div>
							</div>

						</div>
					</div>
				</div>
			</section>

			<section class="seccion2Planes">

				<div class="container">
					<div class="row">
						<div class="col-sm">
							<ListaDePlanes
								tipoPlan={tipoPlan}
								planesMensuales={planesMensuales}
								planesAnuales={planesAnuales}
								comprarPlan={comprarPlan}
							/>
						</div>
					</div>
				</div>

			</section>

			<section class="seccion4">
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<h2 class="tituloSobrePlanes centrar">Beneficios de Contratar un Plan</h2>
						</div>
					</div>
					<div class="row">
						<div class="col-sm centrar">
							<div class="porques margenesPorQues">
								<img style={{width: 100}} src={ahorros} class="imgPorque" />
								<h3 style={{fontSize: 24,}} class="tituloPorque">Ahorros<br/>Significativos</h3>
							</div>
							<div class="porques margenesPorQues">
								<img style={{width: 100}} src={servicioIntegral} class="imgPorque" />
								<h3 style={{fontSize: 24,}} class="tituloPorque">Servicio Integral<br/>para tu empresa</h3>
							</div>
							<div class="porques margenesPorQues">
								<img style={{width: 100}} src={respaldoLegal} class="imgPorque" />
								<h3 style={{fontSize: 24,}} class="tituloPorque">Respaldo Legal<br/>Contituo</h3>
							</div>
							<div class="porques margenesPorQues">
								<img style={{width: 100}} src={atencionPersonal} class="imgPorque" />
								<h3 style={{fontSize: 24,}} class="tituloPorque">Atenciòn<br/>Personalizada</h3>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default PlanesScreen;