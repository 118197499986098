import {
	almacenamiento, 
	referencia, 
	subirArchivo, 
	obtenerURL, 
	db, 
	obtenerDocs, 
	documento, 
	coleccion, 
	agregarDoc, 
	set, 
	actualizarDoc, 
	eliminarDoc, 
	leerDatos, 
	buscar, 
	donde
} from '../conex.js';

export async function verEstaCompra(idCompra, tipo){

	const docRef = documento(db, "compras", idCompra);
	const docSnap = await leerDatos(docRef);

	if (docSnap.exists()) {
		let compra = {
			id: docSnap.id,
			data: docSnap.data(),
		};

		if (compra.data.pagado == false || compra.data.caracteristicas.editado == true) {
			return false;
		}else{
			const docRef2 = documento(db, tipo, compra.data.idProducto);
			const docSnap2 = await leerDatos(docRef2);

			if (docSnap2.exists()) {
				let producto = {
					id: docSnap2.id,
					data: docSnap2.data(),
				};

				return {
					compra: compra,
					producto: producto,
				};
			}else{
				return false;
			}
		}
	} else {
		return false;
	}
}

export async function actualizarCompra(data){
	const washingtonRef = documento(db, "compras", data.idCompra);

	// Set the "capital" field of the city 'DC'
	await actualizarDoc(washingtonRef, {
	  caracteristicas: data.caracteristicas,
	});

	return true;
}

export async function datosDeLaCompra(idCompra){
	const docRef = documento(db, "compras", idCompra);
	const docSnap = await leerDatos(docRef);

	if (docSnap.exists()) {
		let compra = {
			id: docSnap.id,
			data: docSnap.data(),
		};
		return compra;
	} else {
		return false;
	}
}

export async function subirImagenEditDoc(id, imagen){

	const storageRef = referencia(almacenamiento, 'assets/compras/'+id+'/'+id);

	await subirArchivo(storageRef, imagen);

	let url = await obtenerURL(storageRef);

	return url;  
}