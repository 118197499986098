import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { buscarCupones} from '../../../backend/cupones/cupones.js';

function ListCupons(){
	const [busquedaCupones, CambioBusquedaCupones] 	= useState(false);
	const [cuponesv, CambioCupones] 					= useState([]);
	const [rolVisto, CambioRoVisto] = useState(false);

	useEffect(() =>{

		if (busquedaCupones == false) {
			buscarCupones().then(response =>{

				var cp = new Array();
				response.map((item,i) =>{

					cp[i]={
						id: item.id,
						data:{

							codigocupon: item.data.codigocupon,
							diasduracion: item.data.diasduracion,
							estatuscupon: item.data.estatuscupon,
							fecha: new Date(item.data.fecha),
							id: item.data.id,
							monto: item.data.monto,
							numerousos: item.data.numerousos,
							tipodescuento: item.data.tipodescuento,
						}
					}
					console.log(cp);
				});
				console.log(cp)
				CambioCupones(cp);
				CambioBusquedaCupones(true);
			});
		}

		if (rolVisto == false) {
	      verRol();
	    }
	});

	const verRol = async function(){
	      let usuario = await localStorage.getItem('usuarioLegalizarlo');
	      if (usuario != null) {
	        let dataUser = JSON.parse(usuario);
	        if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {

	          if (dataUser.rol == 'subAdmin') { 

	              dataUser.restricciones.forEach(element =>{ console.log(element);
	                if (element == 'cupones') {
	                  window.location.href = '/';
	                }
	              })
	          }

	          CambioRoVisto(true);
	        }else{
	          window.location.href = '/';
	        }
	      }else{
	        window.location.href = '/';
	      }
	}

	function printDate(fecha)
	{
		return new Date(fecha);
	}
	return(
		<div className="main-form container pl-5 pt-5">
			<h1> Listado de cupones</h1>
	      	<Row>
	        	<div className='col-12 d-flex justify-content-end'>
					<NavLink to="/admin/cupones/agregar" className="link" activeClassName="active">
	                  Agregar Cupon
	                </NavLink>
	        	</div>
	      	</Row>
	      	<table className="table table-dark table-striped">
	      		<thead>
	      		<tr>
					<td>Código de Cupón</td>
					<td>Días duración</td>
					<td>Número de usos</td>
					<td>Monto del descuento</td>
					<td>Tipo de descuento</td>
					<td>Estatus del cupon</td>
					<td>Fecha de creación</td>
					<td>Accion</td>
					{/*<td>
						<BotonActivo
							estado={item.data.activo}
							cambiarEstado={() => cambiarEstado(item.id, item.data.activo)}
						/>
					</td>*/}
				</tr>
				</thead>
				<tbody>
	      		{cuponesv.map((item,i) =>(
					<tr key={i}>
						<td key={i+1}>{item.data.codigocupon}</td>
						<td key={i+2}>{item.data.diasduracion}</td>
						<td key={i+3}>{item.data.numerousos}</td>
						<td key={i+4}>{item.data.monto}</td>
						<td key={i+5}>{item.data.tipodescuento}</td>
						<td key={i+6}>{item.data.estatuscupon}</td>
						{/*<td key={i+7}>{()=>{ let dateUP= new Date(item.data.fecha * 1000); return 'hola';}}</td>*/}
						<td key={i+7}>{ item.data.fecha.getDate()+'/'+(item.data.fecha.getMonth()+1)+'/'+item.data.fecha.getFullYear()}</td>
						<td>

							 <NavLink to={`/admin/cupones/editar/${item.id}`}
							        state={
							                item
							  }>
							          Editar
							  </NavLink>

						</td>
					</tr>
				))}
			</tbody>
			</table>
		</div>
	);
}
export default ListCupons;
