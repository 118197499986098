import React, {useState, useEffect} from 'react';
import './editarDocumento-styles.css';

export function CampoPersonalizado({
	item,
	actualizaContenido,
}){
	if (item.tipoDeCampo == 'campoDeTexto') {
		return(
			<>
				<div class="mb-3">
					<label class="form-label labelLegalizar">{item.data.nombre}</label>
					<input type="text" id={item.id} onChange={() => actualizaContenido(item.id)} class="form-control" placeholder={item.data.nombre} aria-label={item.data.nombre} aria-describedby="basic-addon1" />
				</div>
			</>
		);
	}else if (item.tipoDeCampo == 'areaDeTexto') {
		return(
			<>
				<div class="mb-3">
					<label class="form-label labelLegalizar">{item.data.nombre}</label>
					<textarea id={item.id} onChange={() => actualizaContenido(item.id)} class="form-control" placeholder={item.data.nombre} aria-label={item.data.nombre} aria-describedby="basic-addon1">
					</textarea>
				</div>
			</>
		);
	}else if (item.tipoDeCampo == 'seleccionable') {
		return(
			<>
				<div class="mb-3">
					<label class="form-label labelLegalizar">{item.data.nombre}</label>
					<select id={item.id} onChange={() => actualizaContenido(item.id)} class="form-control " placeholder={item.data.nombre} aria-label={item.data.nombre} aria-describedby="basic-addon1">
						<option value="">{item.data.nombre}</option>
						{item.data.opciones.map((elItem) =>(
							<option value={elItem}>{elItem}</option>
						))}
					</select>
				</div>
			</>
		);
	}else if (item.tipoDeCampo == 'grupoDeCasillas') {
		return(
			<>
				<div class="mb-3">
					<label class="form-label labelLegalizar">{item.data.nombre}</label>
					{item.data.casillas.map((elItem) =>(
						<div class="form-check">
						  <input class="form-check-input" type="checkbox" value={elItem.nombre} id={item.id+"-"+elItem.valor} onChange={() => actualizaContenido(item.id)} />
						  <label class="form-check-label labelLegalizar" for="flexCheckDefault">
						    {elItem.nombre}
						  </label>
						</div>
					))}
				</div>
			</>
		);
	}else if (item.tipoDeCampo == 'campoDeFecha') {
		return(
			<>
				<div class="mb-3">
					<label class="form-label labelLegalizar">{item.data.nombre} ({item.data.fecha})</label>
					<input type="date" id={item.id} onChange={() => actualizaContenido(item.id)} min={item.data.fecha} class="form-control" placeholder={item.data.nombre} aria-label={item.data.nombre} aria-describedby="basic-addon1" />
				</div>
			</>
		);
	}else if (item.tipoDeCampo == 'numero') {
		return(
			<>
				<div class="mb-3">
					<label class="form-label labelLegalizar">{item.data.nombre} ({item.data.numero})</label>
					<input type="number" id={item.id} onChange={() => actualizaContenido(item.id)} max={item.data.numero} class="form-control" placeholder={item.data.nombre} aria-label={item.data.nombre} aria-describedby="basic-addon1" />
				</div>
			</>
		);
	}
}

export function FormLogo({
	hayLogo,
	verLogo,
	alinearLogo
}){

	if (hayLogo) {
		return(
			<>
				<label class="terminarPago" for="logoDoc" style={{background: '#00003c', cursor: 'pointer',}}>
					<span>Agregar Logo</span>
					<input 
						type="file" 
						style={{display: 'none',}} 
						accept="image/*"
						id="logoDoc"
						onChange={verLogo}
					/>
				</label>
				<br/>
				<br/>
				<div class="opcionesFinales">
		              <div onClick={() => alinearLogo('is')} id="is" class="elegirFinal formaDePagoActive">
		                <div class="tipoOpcionYNombre">
		                  <div id="isCirculo" class="circulo circulo30 circuloActive">
		                  </div>
		                  <span class="nombreOpcion">Izquierda Superior</span>
		                </div>
		              </div>
		              <div onClick={() => alinearLogo('cs')}  id="cs" class="elegirFinal">
		                <div class="tipoOpcionYNombre">
		                  <div id="csCirculo" class="circulo circulo30">
		                  </div>
		                  <span class="nombreOpcion">Centrar Superior</span>
		                </div>
		              </div>
		              <div onClick={() => alinearLogo('ds')} id="ds" class="elegirFinal">
		                <div class="tipoOpcionYNombre">
		                  <div id="dsCirculo" class="circulo circulo30">
		                  </div>
		                  <span class="nombreOpcion">Derecha Superior</span>
		                </div>
		              </div>
	            </div>

	            <br/>
				<div class="opcionesFinales">
		              <div onClick={() => alinearLogo('ii')} id="ii" class="elegirFinal">
		                <div class="tipoOpcionYNombre">
		                  <div id="iiCirculo" class="circulo circulo30">
		                  </div>
		                  <span class="nombreOpcion">Izquierda Inferior</span>
		                </div>
		              </div>
		              <div onClick={() => alinearLogo('ci')} id="ci" class="elegirFinal">
		                <div class="tipoOpcionYNombre">
		                  <div id="ciCirculo" class="circulo circulo30">
		                  </div>
		                  <span class="nombreOpcion">Centrar Inferior</span>
		                </div>
		              </div>
		              <div onClick={() => alinearLogo('di')} id="di" class="elegirFinal">
		                <div class="tipoOpcionYNombre">
		                  <div id="diCirculo" class="circulo circulo30">
		                  </div>
		                  <span class="nombreOpcion">Derecha Inferior</span>
		                </div>
		              </div>
	            </div>
			</>
		);
	}else{
		return(
			<></>
		);
	}
}