import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function buscarSubAdmins(){

	let usuarios = [];

	const q = buscar(coleccion(db, "usuarios"), donde("rol", "==", "subAdmin"));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		usuarios.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return usuarios;
}

export async function editarEstadoSubAdmin(id, estado){
	let activo = false;
	
	if(estado == false){
		activo = true;
	}

	const washingtonRef = documento(db, "usuarios", id);

	await actualizarDoc(washingtonRef, {
		activo: activo,
	});

	return true;
}

export async function editarRestricciones(id, restricciones){
	const washingtonRef = documento(db, "usuarios", id);

	await actualizarDoc(washingtonRef, {
		restricciones: restricciones,
	});

	return true;
}