import React, { useState, useEffect } from 'react';
import './planes-styles.css';
import {BotonActivo} from '../../../components/documentos/documentos.js';
import {ModalAuth} from '../../../components/modals/modals.js';
import Swal from 'sweetalert2';
import {guardarPlan, buscarPlanes, editarEstadoPlan} from '../../../backend/planes/planes';

function AdminPlanesScreen() {
  const [rolVisto, CambioRolVisto] = useState(false);
  const [pantalla, CambioPantalla] = useState('');
  const [planes, CambioPlanes] = useState([]);
  const [busquedaPlanes, CambioBusquedaPlanes] = useState(false);
  const [tituloModal, CambioTituloModal] = useState('');
  const [mostrarEditarPlan, CambioMostrarEditarPlan] = useState(false);
  const [editando, CambioEditando] = useState(false);
  const [idPlan, CambioIdPlan] = useState(false);
  const [nombre, CambioNombre] = useState('');
  const [descripcionCortar, CambioDescripcionCortar] = useState('');
  const [precio, CambioPrecio] = useState('');
  const [lugar, CambioLugar] = useState('');
  const [atencionEspecializada, CambioAtencionEspecializada] = useState('');
  const [cantidadDocumentos, CambioCantidadDocumentos] = useState('');
  const [cantidadServicios, CambioCantidadServicios] = useState('');
  const [estadoPlan, CambioEstadoPlan] = useState(false);

  
  useEffect(() => {
    
    if (busquedaPlanes == false) {

      buscarPlanes('admin').then(response =>{
        CambioPlanes(response);
        CambioBusquedaPlanes(true);
      })
    }

    if (rolVisto == false) {
      verRol();
      CambioRolVisto(true);
    }
  });

  const verRol = async function(){
      let usuario = await localStorage.getItem('usuarioLegalizarlo');
      if (usuario != null) {
          let dataUser = JSON.parse(usuario);
          if (dataUser.rol != 'admin' && dataUser.rol != 'subAdmin') {
            window.location.href = '/';
          }else{ 

            if (dataUser.rol == 'subAdmin') { 

              dataUser.restricciones.forEach(element =>{ console.log(element);
                if (element == 'planes') {
                  window.location.href = '/';
                }
              })
            }
          }
      }else{
        window.location.href = '/';
      }
  }

  const cambiarEstado = async function(id, estado){
    if(estado){
      CambioTituloModal('¿Desea desactivar este plan?')
      CambioMostrarEditarPlan(true);
      CambioEstadoPlan(estado);
      CambioIdPlan(id);
    }else{
      CambioTituloModal('¿Desea activar este plan?')
      CambioMostrarEditarPlan(true);
      CambioEstadoPlan(estado);
      CambioIdPlan(id);
    }
  }

  const terminarDeCambiarEstado = async function(){

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
              '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
              '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    editarEstadoPlan(idPlan, estadoPlan).then(response =>{
      window.location.reload();
    })
  }

  const procesarPlan = async function(){

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
              '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
              '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    var id = "";
      for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

    let continuar = true;
    let razon = '';

    let data = {
      activo: true,
      nombre: nombre,
      descripcionCortar: descripcionCortar,
      precio: precio,
      lugar:lugar,
      atencionEspecializada: atencionEspecializada,
      tipo: document.getElementById('tipo').value,
      documentosIlimitados: document.getElementById('documentosIlimitados').checked,
      documentosLogotipo: document.getElementById('documentosLogotipo').checked,
      descuentoDocumentos: document.getElementById('cantidadDocumentos').value,
      serviciosIlimitados: document.getElementById('serviciosIlimitados').checked,
      descuentoServicios: document.getElementById('cantidadServicios').value,
      seguimientoNegocio: document.getElementById('seguimientoNegocio').checked,
      proteccionPersonalAEjecutivos: document.getElementById('proteccionPersonalAEjecutivos').checked,
    };

    if (data.nombre === '' || data.descripcionCortar === '' || data.precio === '' || data.lugar === '' || data.descuentoDocumentos === '' || data.descuentoServicios === '') {
      continuar = false;
      razon = 'Complete los campos requeridos para continuar.';
    }

    if (data.atencionEspecializada != '' && isNaN(data.atencionEspecializada)) {
      continuar = false;
      razon = 'El campo atención especializada debe ser un valor numerico.';
    }

    if (isNaN(data.descuentoDocumentos)) {
      continuar = false;
      razon = 'El campo de descuento de documentos debe ser un valor numerico.';
    }

    if (isNaN(data.descuentoServicios)) {
      continuar = false;
      razon = 'El campo de descuento de servicios debe ser un valor numerico.';
    }

    if (isNaN(data.precio)) {
      continuar = false;
      razon = 'El campo de descuento de precio debe ser un valor numerico.';
    }

    if (isNaN(data.lugar)) {
      continuar = false;
      razon = 'El campo de descuento de lugar debe ser un valor numerico.';
    }

    if (continuar) {
      
      let elIdPlan = '';

      if (idPlan != false) {
        elIdPlan = idPlan;
      }else{
        elIdPlan = id;
      }

      guardarPlan(elIdPlan, data).then(response =>{
        window.location.reload();
      });
    }else{
      Swal.fire({
        title: razon,
        icon: 'info',
      });
    }
  }

  if (pantalla == 'gestionar') {
    return (
      <div class="containerAdminDocs">
        <section class="seccion1AdminDocs">
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <button onClick={() => {
                  CambioPantalla('');
                  CambioEditando(false);
                  CambioIdPlan('');
                }} class="btn btn-warning">Cancelar</button>
                <br/>
                <br/>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <h4 class="tituloPaginaAdmin">Datos generales</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="mb-3">
                  <label for="" class="form-label">Nombre del servicio*</label>
                  <input onChange={() => CambioNombre(document.getElementById('nombre').value)} type="text" class="form-control" id="nombre" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Descripción Cortar*</label>
                  <textarea onChange={() => CambioDescripcionCortar(document.getElementById('descripcionCortar').value)} type="text" class="form-control" id="descripcionCortar"></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="col-sm">
                  <div class="mb-3">
                    <label for="" class="form-label">Precio*</label>
                    <input onChange={() => CambioPrecio(document.getElementById('precio').value)} type="number" class="form-control" id="precio" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="col-sm">
                  <div class="mb-3">
                    <label for="" class="form-label">Lugar en la landing*</label>
                    <input onChange={() => CambioLugar(parseInt(document.getElementById('lugar').value))} type="number" class="form-control" id="lugar" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="col-sm">
                  <div class="mb-3">
                    <label for="" class="form-label">Tipo de plan</label>
                    <select id="tipo" class="form-select" aria-label="Default select example">
                      <option value="mensual">Mensual</option>
                      <option value="anual">Anual</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="col-sm">
                  <div class="mb-3">
                    <label for="" class="form-label">Atención especializada</label>
                    <input onChange={() => CambioAtencionEspecializada(document.getElementById('atencionEspecializada').value)} type="number" class="form-control" id="atencionEspecializada" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <h4 class="tituloPaginaAdmin">Documentos en el plan</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">

                <div class="mb-3">
                  <input class="form-check-input margenServiciosLeft" type="checkbox" id="documentosIlimitados" />
                  <label class="form-check-label margenServiciosLeft" for="miercoles">
                    Documentos ilimitados
                  </label>
                </div>

                <div class="mb-3">
                  <input class="form-check-input margenServiciosLeft" type="checkbox" id="documentosLogotipo" />
                  <label class="form-check-label margenServiciosLeft" for="documentosLogotipo">
                    Documentos con Logotipo
                  </label>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="col-sm">
                  <div class="mb-3">
                    <label for="" class="form-label">Descuento del Documentos*</label>
                    <input onChange={() => CambioCantidadDocumentos(document.getElementById('cantidadDocumentos').value)} type="number" min="0" class="form-control" id="cantidadDocumentos" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <h4 class="tituloPaginaAdmin">Servicios en el plan</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="mb-3">
                  <input class="form-check-input margenServiciosLeft" type="checkbox" id="serviciosIlimitados" />
                  <label class="form-check-label margenServiciosLeft" for="miercoles">
                    Servicios ilimitados
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="col-sm">
                  <div class="mb-3">
                    <label for="" class="form-label">Descuento de los Servicios*</label>
                    <input onChange={() => CambioCantidadServicios(document.getElementById('cantidadServicios').value)} type="number" min="0" class="form-control" id="cantidadServicios" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <h4 class="tituloPaginaAdmin">Seguimiento prioritario de tu negocio</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="mb-3">
                  <input class="form-check-input margenServiciosLeft" type="checkbox" id="seguimientoNegocio" />
                  <label class="form-check-label margenServiciosLeft" for="seguimientoNegocio">
                    Si, hacer seguimiento
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <h4 class="tituloPaginaAdmin">Protección personal a Ejecutivos</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="mb-3">
                  <input class="form-check-input margenServiciosLeft" type="checkbox" id="proteccionPersonalAEjecutivos" />
                  <label class="form-check-label margenServiciosLeft" for="proteccionPersonalAEjecutivos">
                    Si, permitir protección
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <button onClick={() => procesarPlan()} class="btn btn-primary">Guardar</button>
                <br/>
              </div>
            </div>

          </div>
        </section>
      </div>
    );
  }else{
    return(
      <div class="containerAdminDocs">
        <section class="seccion1AdminDocs">
          <div class="container">
            <div class="row">
              <div class="col-sm paddingArribaAbajo">
                <button onClick={() => CambioPantalla('gestionar')} class="btn btn-primary">Crear Plan</button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <br />
                <table class="table table-dark table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Tipo</th>
                      <th scope="col">Precio</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {planes.map((item) =>(
                      <tr>
                        <td>{item.data.nombre}</td>
                        <td>{item.data.tipo}</td>
                        <td>${item.data.precio}</td>
                        <td>
                          <BotonActivo
                            estado={item.data.activo}
                            cambiarEstado={() => cambiarEstado(item.id, item.data.activo)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <ModalAuth mostrar={mostrarEditarPlan}>
          <div class="container">
            <div class="row">
              <div class="col-sm modalCambioEstado">
                <h4>{tituloModal}</h4>
                <button onClick={() => terminarDeCambiarEstado()} class="btn btn-success margenBotonTextCustom">Si</button>
                <button onClick={() => {
                  CambioMostrarEditarPlan(false);
                  CambioIdPlan(false);
                }} class="btn btn-danger margenBotonTextCustom">No</button>
              </div>        
            </div>
          </div>
        </ModalAuth>
      </div>
    );
  }
}

export default AdminPlanesScreen;