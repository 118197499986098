import React, {useState, useEffect} from 'react';
import './carrito-styles.css';
import {imgPagoOxxo, imgPagoSpei} from '../../assets/imgs.js';

export function FormasDePago({
	tipoDeForm,
	nombreTarjeta,
	numeroTarjeta,
	mesTarjeta,
	anoTarjeta,
	codigoTarjeta,
	crearToken, 
	procesarOXXO,
	procesarSPEI,
}){
	if (tipoDeForm == 'tarjeta') {
		return(
			<div class="formPago">
				<div class="colFormPago">
					<div class="input-group mb-3 inputWidthCustom">
					  <input type="text" id="nombreTarjeta" onChange={nombreTarjeta} class="form-control" placeholder="Nombre de la tarjeta" aria-label="Nombre de la tarjeta" aria-describedby="basic-addon1" />
					</div>
					<div class="input-group mb-3 inputWidthCustom">
					  <input type="text" id="numeroTarjeta" onChange={numeroTarjeta} class="form-control" placeholder="Numero de tarjeta" aria-label="Numero de tarjeta" aria-describedby="basic-addon1" />
					</div>
				</div>
				<div class="colFormPago">

					<div class="input-group mb-3 inputWidthCustom3">
					  <input type="text" id="mesTarjeta" onChange={mesTarjeta} class="form-control" placeholder="MM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
					</div>

					<div class="input-group mb-3 inputWidthCustom3">
					  <input type="text" id="anoTarjeta" onChange={anoTarjeta} class="form-control" placeholder="YY" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
					</div>

					<div class="input-group mb-3 inputWidthCustom2">
					  <input type="text" id="codigoTarjeta" onChange={codigoTarjeta} class="form-control" placeholder="Código CVC �" aria-label="Código CVC �" aria-describedby="basic-addon1" />
					</div>
					<buttom onClick={crearToken} class="botonPagar">
						Verifcar Tarjeta
					</buttom>
				</div>
			</div>
		);
	}else if (tipoDeForm == 'oxxo') {
		return(
			<div class="formPago">
				<div class="colFormPago">
					<buttom onClick={procesarOXXO} class="botonPagar">
						Pagar con OXXO
					</buttom>
				</div>
			</div>
		);
	}else if (tipoDeForm == 'spei') {
		return(
			<div class="formPago">
				<div class="colFormPago">
					<buttom onClick={procesarSPEI} class="botonPagar">
						Pagar con SPEI
					</buttom>
				</div>
			</div>
		);
	}else{
		return(
			<span></span>
		);
	}
}

export function SeccionReferencia({pagarCon, referencia, precioProducto}){
	if (pagarCon == 'Pago con OXXO') {
		return(
			<>
				<div class="tarjetaOXXO" id="laFactura">
					<h6 class="ficha">FICHA DIGITAL, NO ES NECESARIO IMPRIMIR.</h6>
					<div class="iconoYPrecio">
						<img class="imgOXXO" src={imgPagoOxxo} />
						<div class="precioPago">
							<span class="montoFicha">MONTO A PAGAR</span><br />
							<span class="precioFicha">${precioProducto} MXN</span><br />
							<span class="textoFicha">OXXO cobrará una comisión adicional al momento de realizar el pago.</span>
						</div>
					</div>
					<div class="divReferencia">
						<span class="textoFicha2">REFERENCIA</span><br /><br />
						<span class="referencia">{referencia}</span>
					</div>
					<div class="opps-instructions">
                        <h3 class="oppsh3">Instrucciones</h3>
                        <ol class="oppsol">
                            <li class="oppsli">Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank" class="oppsa">Encuéntrala aquí</a>.</li>
                            <li class="oppsli">Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                            <li class="oppsli">Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
                            <li class="oppsli">Realiza el pago correspondiente con dinero en efectivo.</li>
                            <li class="oppsli">Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                        </ol>
                        <div class="opps-footnote">Al completar estos pasos recibirás un correo de <strong>LEGALIZARLO</strong> confirmando tu pago.</div>
                    </div>
				</div>
			</>
		);
	}else if (pagarCon == 'Pago con SPEI') {
		return(
			<>
				<div class="tarjetaOXXO" id="laFactura">
					<h6 class="ficha">FICHA DIGITAL, NO ES NECESARIO IMPRIMIR.</h6>
					<div class="iconoYPrecio">
						<img class="imgOXXO" src={imgPagoSpei} />
						<div class="precioPago">
							<span class="montoFicha">MONTO A PAGAR</span><br />
							<span class="precioFicha">${precioProducto} MXN</span><br />
							<span class="textoFicha">Transferir esta cantidad exacta</span>
						</div>
					</div>
					<div class="divReferencia">
						<span class="textoFicha2">CLABE</span><br /><br />
						<span class="referencia">{referencia}</span>
					</div>'+
					<div class="opps-instructions">
                        <h3 class="oppsh3">Instrucciones</h3>
                        <ol class="oppsol">
                            <li class="oppsli">Inicie sesión en el portal en línea de su banco.</li>
                            <li class="oppsli">Registre el CABLE en este stub. <strong>El banco tendrá que ser STP.</strong></li>
                            <li class="oppsli">Realice la transferencia en línea por el monto exacto en este talón, <strong>o la transferencia será rechazada.</strong></li>
                            <li class="oppsli">Para confirmar su pago, el portal en línea de su banco producirá un recibo digital. <strong>Verifique que se haya realizado correctamente.</strong></li>
                        </ol>
                        <div class="opps-footnote">Al completar estos pasos recibirás un correo de <strong>LEGALIZARLO</strong> confirmando tu pago.</div>
                    </div>
				</div>
			</>
		);
	}else{
		return(
			<></>
		);
	}
}

export function DescuentoPlan({
	pantalla,
	descuento,
	nombrePlan,
}){
	if (pantalla == 'titulo') {
		return(
			<p class="infoPrecio">Desc. Por plan: {nombrePlan}</p>
		);
	}else if (pantalla == 'descuento') {
		return(
			<p class="precios">{descuento}%</p>
		);
	}else{
		return(
			<></>
		);
	}
}