import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';
import {buscarProducto} from '../carrito/carrito.js';

export async function misComprar(uid){
	let compras = [];
	let datosCompras = [];
	let q = buscar(coleccion(db, "compras"), donde("uid", "==", uid));
	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		compras.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	for (let compra of compras) {
		let dataProducto = {
			tipoDeCompra: compra.data.tipo,
			idProducto: compra.data.idProducto,
		};
		let producto = await buscarProducto(dataProducto);
		if (producto != false) {
			datosCompras.push({
				compra: compra,
				producto: producto,
			});
		}
	}

	return datosCompras;
}

export async function serviciosPersonalizados(uid){

	let servicios = [];
	let misServicios = [];

	const q = buscar(coleccion(db, "serviciosPersonalizados"), donde("activo", "==", true));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		servicios.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	for (let servicio of servicios) {
		let validarServicio = await validandoMiServicio(servicio, uid);

		if (validarServicio != false) {

			let compra = false;

			let q2 = buscar(coleccion(db, "compras"), donde("uid", "==", uid));
			const querySnapshot2 = await obtenerDocs(q2);
			querySnapshot2.forEach((doc) => {
				if (doc.data().idProducto == servicio.id && doc.data().uid) {
					compra = {
						id: doc.id,
						data: doc.data(),
					};
				}
			});

			misServicios.push({
				producto: servicio,
				compra: compra,
			});
		}
	}

	return misServicios;
}

async function validandoMiServicio(servicio, uid){
	let esta = false;
	let usuarios = servicio.data.usuarios;
	for (let usuario of usuarios) {
		if (usuario.uid == uid) {
			esta = true;
		}
	}
	return esta;
}

export async function desactivarPlan(id){

	const washingtonRef = documento(db, "compras", id);

	await actualizarDoc(washingtonRef, {
		caracteristicas:{
			activo: false,
			fechaExpiracion: 'Sin asignar',
		},
	});

	return true;
}

export async function terminarActualizacion(data, uid){
	const ref = documento(db, "usuarios", uid);

	await actualizarDoc(ref, data);

	const docRef = documento(db, "usuarios", uid);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {

		await localStorage.setItem('usuarioLegalizarlo', JSON.stringify(docSnap.data()));

		return docSnap.data();
	}else{
		return false;
	}
}