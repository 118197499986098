import React, { useState, useEffect } from 'react';
import {ModalAuth} from '../../../components/modals/modals.js';
import Swal from 'sweetalert2';
import {BotonActivo, BotonSolicitado} from '../../../components/documentos/documentos.js';
import {
  guardarServicio, 
  subirImagenServicio, 
  buscarServicios,
  editarEstadoServicio,
  editarSolicitado,
} from '../../../backend/servicios/servicios';
import ReactQuill from 'react-quill';

function AdminServiciosScreen() {
  
  const [pantalla, CambioPantalla] = useState('');
  const [mostrarEditarServicio, CambioMostrarEditarServicio] = useState(false);
  const [estadoServicio, CambioEstadoServicio] = useState(false);
  const [servicios, CambioServicios] = useState([]);
  const [tituloModal, CambioTituloModal] = useState('');
  const [nombreDelServicio,CambioNombreDelServicio] = useState('');
  const [descripcionCortar,CambioDescripcionCortar] = useState('');
  const [descripcion, CambioDescripcion] = useState('');
  const [rolVisto, CambioRolVisto] = useState(false);
  const [uidAdmin, CambioUidAdmin] = useState('');
  const [conImagen, CambioConImagen] = useState(false);
  const [value2, setValue2] = useState('');
  const [precio, CambioPrecio] = useState('');
  const [editando, CambioEditando] = useState(false);
  const [imagenActual, CambioImagenActual] = useState('');
  const [idServicio, CambioIdServicio] = useState('');
  const [solicitado, CambioSolicitado] = useState(false);
  const [mostrarEditarSolicitado, CambioMostrarEditarSolicitado] = useState(false);
  
  useEffect(() => {
    /*let d = 'February';
    var Xmas95 = new Date(d+' 23, 2023 00:00:00');
    var weekday = Xmas95.getDay();

    console.log(weekday); // 1*/
    if (rolVisto == false) {
      verRol();
    }
  });

  const verRol = async function(){
    let usuario = await localStorage.getItem('usuarioLegalizarlo');
    if (usuario != null) {
      let dataUser = JSON.parse(usuario);
      if (dataUser.rol == 'admin' || dataUser.rol == 'subAdmin') {

        if (dataUser.rol == 'subAdmin') { 

            dataUser.restricciones.forEach(element =>{ console.log(element);
              if (element == 'servicios') {
                window.location.href = '/';
              }
            })
        }

        CambioRolVisto(true);
        CambioUidAdmin(dataUser.uid);
        buscarServicios('admin', dataUser.uid).then(response =>{
          CambioServicios(response); console.log(response);
        })
      }else{
        window.location.href = '/';
      }
    }else{
      window.location.href = '/';
    }
  }

  const procesarServicio = async function(){

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
              '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
              '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    let diasDisponibilidad = [];
    let losDias = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
    let camposVacios = false;
    let noEsNumero = false;
    let formatoMal = false;
    let categoria = document.getElementById('categoria').value;

    var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    var id = "";
      for (var i2=0; i2<20; i2++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

    for (var i = 0; i <= 6; i++) {
      let elDia = losDias[i];
      let checked = document.getElementById(elDia).checked;
      if (checked) {
        let desde = document.getElementById('desde'+elDia).value;
        let hasta = document.getElementById('hasta'+elDia).value;

        let horaDesde = desde.slice(0, -3);
        let minutosDesde = desde.slice(3, 5);

        let horaHasta = hasta.slice(0, -3);
        let minutosHasta = hasta.slice(3, 5);

        if (desde === '' || hasta === '') {
          camposVacios = true;
        }else if (isNaN(horaDesde) || isNaN(minutosDesde) || isNaN(horaHasta) || isNaN(minutosHasta)){
          noEsNumero = true;
        }else if (parseInt(horaDesde) > 12 || parseInt(horaDesde) < 1 || parseInt(minutosDesde) > 59 || parseInt(minutosDesde) < 0 ||parseInt(horaHasta) > 12 || parseInt(horaHasta) < 1 || parseInt(minutosHasta) > 59 || parseInt(minutosHasta) < 0 ) {
          formatoMal = true;
        }else{
          diasDisponibilidad.push({
            posicionDia: i,
            dia: elDia,
            desde: desde,
            hasta: hasta,
          });
        }
      }
    }

    if (nombreDelServicio === '' || descripcion === '' || descripcionCortar === '' || diasDisponibilidad.length == 0 || value2 === '' || precio === '') {
      Swal.fire({
        title: 'Por favor complete todos los campos para continuar.',
        icon: 'info',
      });
    }else if (camposVacios) {
      Swal.fire({
        title: 'Indique las horas de disponibilidad de lo dia seleccionado.',
        icon: 'info',
      });
    }else if (noEsNumero) {
      Swal.fire({
        title: 'Las horas y minutos deben tener este formato "00:00".',
        icon: 'info',
      });
    }else if (noEsNumero) {
      Swal.fire({
        title: 'Las ingresadas no son validas.',
        icon: 'info',
      });
    }else if (isNaN(precio)) {
      Swal.fire({
        title: 'El precio debe ser numerico.',
        icon: 'info',
      });
    }else{

      let date = new Date();

      let idData = '';

      if (editando) {
        idData = idServicio;
      }else{
        idData = id;
      }

      var imagen = document.getElementById('elInputImagen').files[0];
      let urlImagen = '';

      if (imagenActual != '') {
        if (conImagen) {
          urlImagen = await subirImagenServicio(idData, imagen);
        }else{
          urlImagen = imagenActual;
        }
      }else{
        if (conImagen) {
          urlImagen = await subirImagenServicio(idData, imagen);
        }else{
          urlImagen = 'https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2FdocumentosAdmin%2Fdoc.png?alt=media&token=00577dfc-7d7c-4883-9aa0-38efdb112fd1';
        }
      }

      let data = {
        activo: true,
        uidAdmin: uidAdmin,
        nombre: nombreDelServicio,
        descripcion: descripcion,
        descripcionCortar: descripcionCortar,
        diasDisponibilidad: diasDisponibilidad,
        foto: urlImagen,
        diaCreacion: date.getDate(),
        mesCreacion: date.getMonth() + 1,
        anoCreacion: date.getFullYear(),
        paraQueSirve: value2,
        precio: precio,
        categoria: categoria,
        solicitado: solicitado,
      }; console.log(data);

      guardarServicio(idData, data).then(response =>{
        window.location.reload();
      })
    }
  }

  const preVisualizarImagen = async function(){
    var pdrs = document.getElementById('elInputImagen').files[0].name;
    document.getElementById('labelElInputImagen').innerHTML = pdrs;
    CambioConImagen(true);
  }

  const cambiarEstado = async function(id, estado){
    if(estado){
      CambioTituloModal('¿Desea desactivar este servicio?')
      CambioMostrarEditarServicio(true);
      CambioEstadoServicio(estado);
      CambioIdServicio(id);
    }else{
      CambioTituloModal('¿Desea activar este servicio?')
      CambioMostrarEditarServicio(true);
      CambioEstadoServicio(estado);
      CambioIdServicio(id);
    }
  }

  const TerminarDeCambiarEstado = async function(){

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
              '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
              '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    editarEstadoServicio(idServicio, estadoServicio).then(response =>{

      CambioMostrarEditarServicio(false);
      CambioRolVisto(false);
      Swal.close();
    })
  }

  const editarServicio = async function(item){
    CambioEditando(true);
    CambioPantalla('gestionar');
    CambioIdServicio(item.id);

    window.setTimeout(ponerDatos, 1000, item);
  }

  const ponerDatos = async function(item){
    document.getElementById('nombreDelDocumento').value = item.data.nombre;
    document.getElementById('descripcionCortar').value = item.data.descripcionCortar;
    document.getElementById('descripcion').value = item.data.descripcion;
    setValue2(item.data.paraQueSirve);
    for (let datoDia of item.data.diasDisponibilidad) {
      document.getElementById(datoDia.dia).checked = true;
      document.getElementById('desde'+datoDia.dia).value = datoDia.desde;
      document.getElementById('hasta'+datoDia.dia).value = datoDia.hasta;
    }
    CambioImagenActual(item.data.foto);
    document.getElementById('precio').value = item.data.precio;
    CambioSolicitado(item.data.solicitado);
    document.getElementById('solicitado').value = item.data.solicitado;
  }

  const ponerEnMasSolicitados = async function(){ console.log(solicitado)

    if (solicitado == false) {

      CambioSolicitado(true);
    }else{

      CambioSolicitado(false);
    }
  }

  const cambiarSolicitado = async function(id, estado){

    if(estado){
      CambioTituloModal('¿Desea quitar de los mas solicitados?')
      CambioMostrarEditarSolicitado(true);
      CambioEstadoServicio(estado);
      CambioIdServicio(id);
    }else{
      CambioTituloModal('¿Desea agregar a los mas solicitados?')
      CambioMostrarEditarSolicitado(true);
      CambioEstadoServicio(estado);
      CambioIdServicio(id);
    }
  }

  const TerminarDeCambiarSolicitado = async function(){

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
              '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
              '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    editarSolicitado(idServicio, estadoServicio).then(response =>{

      CambioMostrarEditarSolicitado(false);
      CambioRolVisto(false);
      Swal.close();
    })
  }

  if (pantalla == 'gestionar') {
    return (
      <div class="containerAdminDocs">
        <section class="seccion1AdminDocs">
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <button onClick={() => {
                  CambioPantalla('');
                  CambioEditando(false);
                  CambioIdServicio('');
                  CambioImagenActual('');
                }} class="btn btn-warning">Cancelar</button>
                <br/>
                <br/>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <h4 class="tituloPaginaAdmin">Datos generales</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="mb-3">
                  <label for="" class="form-label">Nombre del servicio</label>
                  <input onChange={() => CambioNombreDelServicio(document.getElementById('nombreDelDocumento').value)} type="text" class="form-control" id="nombreDelDocumento" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Descripción Cortar</label>
                  <textarea onChange={() => CambioDescripcionCortar(document.getElementById('descripcionCortar').value)} type="text" class="form-control" id="descripcionCortar"></textarea>
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Descripción</label>
                  <textarea onChange={() => CambioDescripcion(document.getElementById('descripcion').value)} type="text" class="form-control" id="descripcion"></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <h4 class="tituloPaginaAdmin">Para que sirve: </h4>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <br/>
                <ReactQuill theme="snow" value={value2} onChange={setValue2} />
                <br/>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <h4 class="tituloPaginaAdmin">Disponibilidad en la Semana</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="diasSemana">

                  <div class="formDia">
                    <div class="mb-3">
                      <input class="form-check-input margenServiciosLeft" type="checkbox" value="0" id="domingo" />
                      <label class="form-check-label margenServiciosLeft" for="domingo">
                        Domingo
                      </label>
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="desdedomingo" class="form-control" placeholder="00:00 AM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="hastadomingo" class="form-control" placeholder="00:00 PM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  <div class="formDia">
                    <div class="mb-3">
                      <input class="form-check-input margenServiciosLeft" type="checkbox" value="1" id="lunes" />
                      <label class="form-check-label margenServiciosLeft" for="lunes">
                        Lunes
                      </label>
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="desdelunes" class="form-control" placeholder="00:00 AM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="hastalunes" class="form-control" placeholder="00:00 PM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  <div class="formDia">
                    <div class="mb-3">
                      <input class="form-check-input margenServiciosLeft" type="checkbox" value="2" id="martes" />
                      <label class="form-check-label margenServiciosLeft" for="martes">
                        Martes
                      </label>
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="desdemartes" class="form-control" placeholder="00:00 AM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="hastamartes" class="form-control" placeholder="00:00 PM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  <div class="formDia">
                    <div class="mb-3">
                      <input class="form-check-input margenServiciosLeft" type="checkbox" value="3" id="miercoles" />
                      <label class="form-check-label margenServiciosLeft" for="miercoles">
                        Miercoles
                      </label>
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="desdemiercoles" class="form-control" placeholder="00:00 AM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="hastamiercoles" class="form-control" placeholder="00:00 PM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  <div class="formDia">
                    <div class="mb-3">
                      <input class="form-check-input margenServiciosLeft" type="checkbox" value="4" id="jueves" />
                      <label class="form-check-label margenServiciosLeft" for="jueves">
                        Jueves
                      </label>
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="desdejueves" class="form-control" placeholder="00:00 AM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="hastajueves" class="form-control" placeholder="00:00 PM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  <div class="formDia">
                    <div class="mb-3">
                      <input class="form-check-input margenServiciosLeft" type="checkbox" value="5" id="viernes" />
                      <label class="form-check-label margenServiciosLeft" for="viernes">
                        Viernes
                      </label>
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="desdeviernes" class="form-control" placeholder="00:00 AM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="hastaviernes" class="form-control" placeholder="00:00 PM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                  </div>

                  <div class="formDia">
                    <div class="mb-3">
                      <input class="form-check-input margenServiciosLeft" type="checkbox" value="6" id="sabado" />
                      <label class="form-check-label margenServiciosLeft" for="sabado">
                        Sabado
                      </label>
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="desdesabado" class="form-control" placeholder="00:00 AM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                    <div class="input-group mb-3 inputWidthCustom4">
                      <input type="text" id="hastasabado" class="form-control" placeholder="00:00 PM" aria-label="Vencimiento (MM/YY)" aria-describedby="basic-addon1" />
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="mb-3">
                    <label for="" class="form-label">Categoría</label>
                    <select class="form-control input-group" id="categoria">
                      <option value="sinCategoria">Sin Categoria</option>
                    </select>
                </div>
              </div>
              <div class="col-sm">
                <div class="inputImagenes" id="inputImagenes">
                  <label id="labelElInputImagen" class="labelElInputImagen" for="elInputImagen">Cambiar</label>
                  <input onChange={() => preVisualizarImagen()} type="file" class="elInputImagen" id="elInputImagen" accept="image/*" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="col-sm">
                  <div class="mb-3">
                    <label for="" class="form-label">Precio</label>
                    <input onChange={() => CambioPrecio(document.getElementById('precio').value)} type="number" class="form-control" id="precio" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <h4 class="tituloPaginaAdmin">Mostrar en mas solicitados</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <div class="form-check">
                  <input onClick={ponerEnMasSolicitados} class="form-check-input" type="checkbox" value="" id="solicitado" />
                  <label class="form-check-label" for="solicitado">
                    Mostrar en mas solicitados
                  </label>
                </div>
              </div>
            </div><br/><br/>

            <div class="row">
              <div class="col-sm">
                <button onClick={() => procesarServicio()} class="btn btn-primary">Guardar</button>
                <br/>
              </div>
            </div>

          </div>
        </section>
      </div>
    );
  }else{
    return(
      <div class="containerAdminDocs">
        <section class="seccion1AdminDocs">
          <div class="container">
            <div class="row">
              <div class="col-sm paddingArribaAbajo">
                <button onClick={() => CambioPantalla('gestionar')} class="btn btn-primary">Crear Servicio</button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <br />
                <table class="table table-dark table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Descripción</th>
                      <th scope="col">Categoría</th>
                      <th scope="col">Fecha de creación</th>
                      <th scope="col">Precio</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {servicios.map((item) =>(
                      <tr>
                        <td>{item.data.nombre}</td>
                        <td>{item.data.descripcionCortar}</td>
                        <td>{item.data.categoria}</td>
                        <td>{item.data.anoCreacion+'-'+item.data.mesCreacion+'-'+item.data.diaCreacion}</td>
                        <td>{item.data.precio}</td>
                        <td>
                          <BotonActivo
                            estado={item.data.activo}
                            cambiarEstado={() => cambiarEstado(item.id, item.data.activo)}
                          />
                          <button onClick={() => editarServicio(item)} class="btn btn-info">Editar</button>
                        
                          <BotonSolicitado
                            estado={item.data.solicitado}
                            cambiarEstado={() => cambiarSolicitado(item.id, item.data.solicitado)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <ModalAuth mostrar={mostrarEditarServicio}>
          <div class="container">
            <div class="row">
              <div class="col-sm modalCambioEstado">
                <h4>{tituloModal}</h4>
                <button onClick={() => TerminarDeCambiarEstado()} class="btn btn-success margenBotonTextCustom">Si</button>
                <button onClick={() => CambioMostrarEditarServicio(false)} class="btn btn-danger margenBotonTextCustom">No</button>
              </div>        
            </div>
          </div>
        </ModalAuth>

        <ModalAuth mostrar={mostrarEditarSolicitado}>
          <div class="container">
            <div class="row">
              <div class="col-sm modalCambioEstado">
                <h4>{tituloModal}</h4>
                <button onClick={() => TerminarDeCambiarSolicitado()} class="btn btn-success margenBotonTextCustom">Si</button>
                <button onClick={() => CambioMostrarEditarSolicitado(false)} class="btn btn-danger margenBotonTextCustom">No</button>
              </div>        
            </div>
          </div>
        </ModalAuth>
      </div>
    );
  }
}

export default AdminServiciosScreen;