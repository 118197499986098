import React, {useState, useEffect} from 'react';
import './cliente-styles.css';

export function EstadoItem({estado}){
	if (estado) {
		return(
			<>
				<span class="badge bg-success">Si</span>
			</>
		);
	}else{
		return(
			<>
				<span class="badge bg-danger">No</span>
			</>
		);
	}
}

export function BotonAccionDocumento({
	estado,
	accion,
}){
	if (estado) {
		return(
			<>
				<button onClick={accion} class="btn btn-success">Descargar</button>
			</>
		);
	}else{
		return(
			<>
				<button onClick={accion} class="btn btn-warning">Editar</button>
			</>
		);

	}
}

export function BotonAccionServicio({
	estado,
	accion,
}){
	if (estado) {
		return(
			<>
				<button onClick={accion} class="btn btn-success">Ver</button>
			</>
		);
	}else{
		return(
			<>
				<button onClick={accion} class="btn btn-warning">Agendar</button>
			</>
		);

	}
}

export function AdminBotonAccionServicio({
	estado,
	accion,
}){
	if (estado) {
		return(
			<>
				<button onClick={accion} class="btn btn-success">Ver</button>
			</>
		);
	}else{
		return(
			<>
				<span class="badge bg-danger">No agendada</span>
			</>
		);

	}
}

export function AccionServicioPersonalizado({
	item, 
	accionPagar, 
	accionGestionar, 
	accionFactura,
	verServicioPersonalizado
}){
	if (item.compra == false) {
		return(
			<>
				<td>
					<button onClick={accionPagar} class="btn btn-warning">Pagar Servicio</button>
					<button onClick={verServicioPersonalizado} class="btn btn-success">Ver Servicio</button>
				</td>
				<td>
					
				</td>
				<td>
					
				</td>
				<td>
					
				</td>
			</>
		);
	}else{
		return(
			<>
				<td>
					<EstadoItem
						estado={item.compra.data.caracteristicas.editado}
					/>
				</td>
				<td>
					<EstadoItem
						estado={item.compra.data.pagado}
					/>
				</td>
				<td>
					{item.compra.data.caracteristicas.fecha}
				</td>
				<td>
					<BotonAccionServicio
						estado={item.compra.data.caracteristicas.editado}
						accion={accionGestionar}
					/>
					{/*<button onClick={accionFactura} class="btn btn-info">Factura</button>*/}
				</td>
			</>
		);
	}
}

export function BotonAccionPlan({
	estado,
	accion,
}){
	if (estado) {
		return(
			<>
				<button onClick={accion} class="btn btn-danger">Quitar Plan</button>
			</>
		);
	}else{
		return(
			<>
				<button onClick={accion} class="btn btn-warning">Renovar</button>
			</>
		);

	}
}