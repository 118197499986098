import React, {useState, useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import Menu from '../components/menu/menu';

// Pantallas app
import HomeScreen from './home/home';
import DocumentosScreen from './documentos/documentos';
import NosotrosScreen from './nosotros/nosotros';
import AbogadosScreen from './abogados/abogados';
import CarritoScreen from './carrito/carrito';
import PlanesScreen from './planes/planes';
import AvisoPrivacidadScreen from './avisoPrivacidad/avisoPrivacidad';
import TerminosCondicionesScreen from './terminosYCondiciones/terminosYCondiciones';

// Pantallas admin
import AdminHomeScreen from './admin/home/home';
import AdminDocumentosScreen from './admin/documentos/documentos';
import AdminServiciosScreen from './admin/servicios/servicios';
import AdminCitasScreen from './admin/citas/citas';
import AdminPlanesScreen from './admin/planes/planes';
import AdminServiciosPersonalizadosScreen from './admin/serviciosPersonalizados/serviciosPersonalizados';
import AdminCuponesScreen from './admin/cupones/cupones';
import AddCupons from './admin/cupones/AddCupons';
import EditCupons from './admin/cupones/EditCupon';
import ListCupons from './admin/cupones/ListCupons';
import AdminUsuariosScreen from './admin/usuarios/usuarios';
import AdminAdministradoresScreen from './admin/administradores/administradores';

// Pantallas cliente
import ClienteHomeScreen from './cliente/home/home';
import EditarDocumentoScreen from './cliente/editarDocumento/editarDocumento';
import AgendarScreen from './cliente/agendar/agendar';

function App() {

  const [rol, EstadoRol] = useState('');

  useEffect(() => {
    verRol();
  });

   const verRol = async function(){
      let usuario = await localStorage.getItem('usuarioLegalizarlo');
      if (usuario != null) {
          let dataUser = JSON.parse(usuario);
          EstadoRol(dataUser.rol);
      }/*else{
        await localStorage.setItem('usuarioLegalizarlo', JSON.stringify({rol: 'admin'}))
      }*/
   }

  return (
    <Router>
      <div>
        <Menu
          rol={rol}
        />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/documentos" element={<DocumentosScreen />} />
          <Route path="/abogados" element={<AbogadosScreen />} />
          <Route path="/nosotros" element={<NosotrosScreen />} />
          <Route path="/planes" element={<PlanesScreen />} />
          <Route path="/carrito" element={<CarritoScreen />} />
          <Route path="/aviso-de-privacidad" element={<AvisoPrivacidadScreen />} />
          <Route path="/terminos-y-condiciones" element={<TerminosCondicionesScreen />} />
          <Route path="/admin/home" element={<AdminHomeScreen />} />
          <Route path="/admin/documentos" element={<AdminDocumentosScreen />} />
          <Route path="/admin/servicios" element={<AdminServiciosScreen />} />
          <Route path="/admin/citas" element={<AdminCitasScreen />} />
          <Route path="/admin/planes" element={<AdminPlanesScreen />} />
          <Route path="/admin/serviciosPersonalizados" element={<AdminServiciosPersonalizadosScreen />} />
          <Route path="/admin/cupones" element={<AdminCuponesScreen />} />
          <Route path="/admin/cupones/listado" element={<ListCupons />} />
          <Route path="/admin/cupones/listado" element={<ListCupons />} />
          <Route path="/admin/cupones/agregar" element={<AddCupons />} />
          <Route path="/admin/cupones/editar/:id" element={<EditCupons />} />
          <Route path="/admin/usuarios" element={<AdminUsuariosScreen />} />
          <Route path="/admin/administradores" element={<AdminAdministradoresScreen />} />
          <Route path="/cliente/home" element={<ClienteHomeScreen />} />
          <Route path="/cliente/editarDocumento" element={<EditarDocumentoScreen />} />
          <Route path="/cliente/agendar" element={<AgendarScreen />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

